define("justmoney-swap/services/just-money-liquidity", ["exports", "@ember/service", "@glimmer/tracking", "justmoney-swap/utils/tools", "justmoney-swap/core/base-service", "justmoney-swap/providers/wallets/tron-link"], function (_exports, _service, _tracking, _tools, _baseService, _tronLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let JustMoneyLiquidityService = (_class = class JustMoneyLiquidityService extends _baseService.default {
    // 5%
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "tokenPair", _descriptor, this);

      _initializerDefineProperty(this, "provider", _descriptor2, this);

      _initializerDefineProperty(this, "logging", _descriptor3, this);

      _initializerDefineProperty(this, "CACHED_BALANCES", _descriptor4, this);

      _defineProperty(this, "lastCacheBalances", {});

      _defineProperty(this, "BALANCES_CACHED", {});

      _defineProperty(this, "resetted", {});

      _defineProperty(this, "_100_TRX", 200000000);

      _defineProperty(this, "SLIPPAGE_FEE", 0.05);

      _defineProperty(this, "cacheBalancesPromises", {});

      _defineProperty(this, "MAX_APPROVAL_VALUE", '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff');

      _defineProperty(this, "key", (from, to) => {
        return `${from.address}-${to.address}`.toUpperCase();
      });

      _defineProperty(this, "keyAddress", (from, to, network) => {
        return `${network}-${from}-${to}`.toUpperCase();
      });

      _defineProperty(this, "keyWithNetwork", (from, to, network) => {
        return `${network}-${from.address}-${to.address}`.toUpperCase();
      });

      _defineProperty(this, "sortedkey", (from, to) => {
        const sorted = this.currentProvider().api.sortAddresses(from, to);
        return `${sorted[0].address}-${sorted[1].address}`.toUpperCase();
      });

      _defineProperty(this, "CONTRACT_METHODS", {
        CREATE_PAIR: 'createPair(address,address)',
        GET_PAIR: 'getPair(address,address)',
        GET_USER_LP: 'getUserLP(address,uint256,uint256)',
        ADD_LIQUIDITY: 'addLiquidity(address,address,uint256,uint256,uint256,uint256,address,uint256)',
        ADD_LIQUIDITY_BASE: 'addLiquidityBase(address,uint256,uint256,uint256,address,uint256)',
        REMOVE_LIQUIDITY: 'removeLiquidity(address,address,uint256,uint256,uint256,address,uint256)',
        REMOVE_LIQUIDITY_BASE: 'removeLiquidityBase(address,uint256,uint256,uint256,address,uint256)',
        REMOVE_LIQUIDITY_BASE_TAXED: 'removeLiquidityBaseSupportingFeeOnTransferTokens(address,uint256,uint256,uint256,address,uint256)',
        GET_ALL_PAIRS_BALANCES: 'getAllPairsBalances(uint256,uint256)',
        GET_ALL_PAIRS_LENGTH: 'getAllPairsLength()'
      });

      if (this.App.widgetOptions && this.App.widgetOptions.hasOwnProperty('slippage')) {
        this.SLIPPAGE_FEE = this.App.widgetOptions.slippage;
      }

      if (this.App.JM.NETWORKS[this.App.NETWORK].LIQUIDITY_PAIRS) {
        this.logging.log('[INFO] USING STUBBED LIQUIDITY PAIRS');
        Object.values(this.App.JM.NETWORKS[this.App.NETWORK].LIQUIDITY_PAIRS).forEach(_ref => {
          let [token1, token2] = _ref;
          {
            const key = this.key(token1, token2);
            this.CACHED_BALANCES[key] = [token1.balance, token2.balance];
          }
          {
            const key = this.key(token2, token1);
            this.CACHED_BALANCES[key] = [token2.balance, token1.balance];
          }
        });
      }

      window.createPairByAddresses = this.createPairByAddresses.bind(this);
    }

    reset() {
      this.resetted.TRON = true;
      this.resetted.BSC = true;
      this.resetted.POLY = true;
      this.resetted.BTTC = true;
      this.resetted.ZENITH = true;
      this.BALANCES_CACHED = {};
      this.CACHED_BALANCES = {};
    }
    /**
     * Get balances of LP pairs
     * @returns {Promise<void>}
     */


    async cacheBalances(network) {
      if (!network) network = this.App.NETWORK;

      if (new Date().getTime() > this.lastCacheBalances[network] + 5000 || !this.lastCacheBalances[network] || this.resetted[network]) {
        this.resetted[network] = false;
        this.lastCacheBalances[network] = new Date().getTime();
        this.cacheBalancesPromises[network] = new Promise(async (resolve, reject) => {
          await this.tokenPair.getTokens(network);

          try {
            let balances = await this.fetchBalancesFromApi(network);

            for (let a = 0; a < balances.length; a++) {
              let token1Address = balances[a].A_A;
              let token2Address = balances[a].B_A;
              const tokenA = this.App.JM.NETWORKS[network].TOKENS_BY_ADDRESS[token1Address.toUpperCase()];
              const tokenB = this.App.JM.NETWORKS[network].TOKENS_BY_ADDRESS[token2Address.toUpperCase()];

              if (!tokenA || !tokenB) {
                continue;
              }

              const key = this.keyAddress(token1Address, token2Address, network);
              this.CACHED_BALANCES[key] = [balances[a].A_B, balances[a].B_B];
            }

            this.BALANCES_CACHED[network] = true;
            resolve();
            return this.cacheBalancesPromises[network];
          } catch (e) {
            console.log('Something went wrong when fetching balances from the api', e);
          }

          const pages = 8;
          const max = network == 'TRON' ? 5 : 15;
          let pairs = [];

          for (let i = 0; i < pages; i++) {
            let res = await this.getProviderByNetwork(network).api.execute(this.App.JM.NETWORKS[network].EXCHANGE.SWAP_API, this.CONTRACT_METHODS.GET_ALL_PAIRS_BALANCES, undefined, [{
              type: _tronLink.default.PARAMETERS.UINT256,
              value: max
            }, {
              type: _tronLink.default.PARAMETERS.UINT256,
              value: i + 1
            }]);
            pairs.push.apply(pairs, res);
          }

          let base = this.App.JM.NETWORKS[network].BASE_TOKEN;
          let mapped = pairs.filter(s => {
            return s.length > 0;
          }).map(s => JSON.parse(s));

          for (let a = 0; a < mapped.length; a++) {
            let pair = mapped[a];
            let token1Address = pair.A.a;
            let token2Address = pair.B.a;

            if (network == 'TRON') {
              token1Address = await this.toBase58(token1Address, network);
              token2Address = await this.toBase58(token2Address, network);
            }

            const tokenA = this.App.JM.NETWORKS[network].TOKENS_BY_ADDRESS[token1Address.toUpperCase()];
            const tokenB = this.App.JM.NETWORKS[network].TOKENS_BY_ADDRESS[token2Address.toUpperCase()];

            if (!tokenA || !tokenB) {
              continue;
            }

            let sorted = this.getProviderByNetwork(network).api.sortAddresses(tokenA, tokenB);
            const key = this.keyWithNetwork(sorted[0], sorted[1], network);

            if (sorted[0].address.toUpperCase() === token1Address.toUpperCase()) {
              this.CACHED_BALANCES[key] = [pair.A.b, pair.B.b];
            } else {
              this.CACHED_BALANCES[key] = [pair.B.b, pair.A.b];
            }
          }

          this.BALANCES_CACHED[network] = true;
          resolve();
        });
        return this.cacheBalancesPromises[network];
      } else {
        return this.cacheBalancesPromises[network];
      }
    }

    async fetchBalancesFromApi(network) {
      return fetch(this.App.BALANCES_APIURL + '/v1/balances/' + network).then(response => response.json()).then(data => {
        return data;
      });
    }

    async getToken(symbol) {
      return (await this.tokenPair.getTokens()).find(token => token.symbol === symbol);
    }

    async getCachedBalance(fromToken, toToken, network) {
      if (!network) network = this.App.NETWORK;
      let sorted = this.getProviderByNetwork(network).api.sortAddresses(fromToken, toToken);
      const key = this.keyWithNetwork(sorted[0], sorted[1], network);

      if (this.CACHED_BALANCES[key]) {
        const [balanceFromToken, balanceToToken] = this.CACHED_BALANCES[key];

        if (sorted[0].address.toUpperCase() === fromToken.address.toUpperCase()) {
          return {
            [fromToken.address.toUpperCase()]: balanceFromToken,
            [toToken.address.toUpperCase()]: balanceToToken
          };
        } else {
          return {
            [fromToken.address.toUpperCase()]: balanceToToken,
            [toToken.address.toUpperCase()]: balanceFromToken
          };
        }
      } // no balances fetched yet, get all of them


      if (!this.BALANCES_CACHED[network]) {
        await this.cacheBalances(network);
      }

      if (this.CACHED_BALANCES[key]) {
        const [balanceFromToken, balanceToToken] = this.CACHED_BALANCES[key];

        if (sorted[0].address.toUpperCase() === fromToken.address.toUpperCase()) {
          return {
            [fromToken.address.toUpperCase()]: balanceFromToken,
            [toToken.address.toUpperCase()]: balanceToToken
          };
        } else {
          return {
            [fromToken.address.toUpperCase()]: balanceToToken,
            [toToken.address.toUpperCase()]: balanceFromToken
          };
        }
      } else {
        // no balances found, so no LP available => insert [0,0] balances
        //this.CACHED_BALANCES[key] = [0, 0];
        const [balanceFromToken, balanceToToken] = [0, 0];
        return {
          [fromToken.address.toUpperCase()]: balanceFromToken,
          [toToken.address.toUpperCase()]: balanceToToken
        };
      }
    }

    async getCachedBalanceBySymbolNames(fromTokenSymbol, toTokenSymbol) {
      return this.getCachedBalance(this.App.JM.NETWORKS[this.App.NETWORK].TOKENS[fromTokenSymbol], this.App.JM.NETWORKS[this.App.NETWORK].TOKENS[toTokenSymbol]);
    }

    async getUserLP() {
      if (!this.provider.connected) {
        return Promise.reject('Wallet not connected');
      }

      const pairsLength = await this.currentProvider().api.execute(this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.SWAP_API, this.CONTRACT_METHODS.GET_ALL_PAIRS_LENGTH, undefined, []);
      let numPairs = Number(pairsLength);
      const max = this.App.NETWORK == 'TRON' ? 4 : 15;
      const pages = Math.ceil(numPairs / max);
      let pairs = [];

      for (let i = 0; i < pages; i++) {
        const res = await this.currentProvider().api.execute(this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.SWAP_API, this.CONTRACT_METHODS.GET_USER_LP, undefined, [{
          type: _tronLink.default.PARAMETERS.ADDRESS,
          value: await this.currentProvider().wallet.getAddress()
        }, {
          type: _tronLink.default.PARAMETERS.UINT256,
          value: max
        }, {
          type: _tronLink.default.PARAMETERS.UINT256,
          value: i + 1
        }]);
        pairs.push.apply(pairs, res);
      }

      const userPools = pairs.filter(s => {
        return s.length > 0;
      }) //.map((s) => JSON.parse(s))
      .map(pool => {
        pool = pool.replace('"tl":', '"tl":"').replace(',"ul":', '","ul":"').replace(',"v1":', '","v1":"').replace(',"v2":', '","v2":"').replace('}', '"}');
        pool = JSON.parse(pool);
        let pair = this.getPairSymbols(pool.a);
        return {
          address: pool.a,
          symbolA: pair ? pair.symbolA : pool.s1,
          symbolB: pair ? pair.symbolB : pool.s2,
          addressA: pool.t1,
          addressB: pool.t2,
          totalLiquidity: pool.tl,
          userLiquidity: pool.ul,
          valueA: pool.v1,
          valueB: pool.v2
        };
      });
      return userPools;
    }

    getPairSymbols(address) {
      if (this.App.NETWORK == 'TRON') {
        address = this.currentProvider().api.tronWeb.address.fromHex(address);
      }

      return this.App.JM.NETWORKS[window.App.NETWORK].POOLS_BY_ADDRESS[address.toUpperCase()];
    }

    async getAllowance(tokenA, tokenB) {
      let useNormalRouter = false;

      if (this.getLiquidityRouter(tokenA, tokenB) == this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.ROUTER_ADDRESS) {
        useNormalRouter = true;
      }

      return this.currentProvider().wallet.getAllowance(tokenA, true, useNormalRouter);
    }

    async createPairByAddresses(addressA, addressB) {
      const options = {
        feeLimit: 1000000000,
        shouldPollResponse: true
      };
      const parameters = [{
        type: _tronLink.default.PARAMETERS.ADDRESS,
        value: addressA
      }, {
        type: _tronLink.default.PARAMETERS.ADDRESS,
        value: addressB
      }];
      return await this.currentProvider().wallet.send(this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.FACTORY_ADDRESS, this.CONTRACT_METHODS.CREATE_PAIR, options, parameters);
    }

    async createPair(tokenA, tokenB) {
      const sorted = this.currentProvider().api.sortAddresses(tokenA, tokenB);
      const options = {
        feeLimit: 1000000000,
        shouldPollResponse: true
      };
      const parameters = [{
        type: _tronLink.default.PARAMETERS.ADDRESS,
        value: sorted[0].address
      }, {
        type: _tronLink.default.PARAMETERS.ADDRESS,
        value: sorted[1].address
      }];
      return await this.currentProvider().wallet.send(this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.FACTORY_ADDRESS, this.CONTRACT_METHODS.CREATE_PAIR, options, parameters);
    }

    async approveAdd(tokenA, tokenB) {
      let useNormalRouter = false;

      if (this.getLiquidityRouter(tokenA, tokenB) == this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.ROUTER_ADDRESS) {
        useNormalRouter = true;
      }

      const allowed = await this.checkAllowanceForLp(tokenA, tokenB);
      const allowed2 = await this.checkAllowanceForLp(tokenB, tokenA);

      if (this.isBaseToken(tokenA)) {
        if (!allowed2) {
          return this.currentProvider().wallet.approveSigned(tokenB.address, useNormalRouter).then(res => {
            this.logging.log(res);
          });
        }
      } else {
        if (!allowed) {
          return this.currentProvider().wallet.approveSigned(tokenA.address, useNormalRouter).then(res => {
            this.logging.log(res);

            if (!allowed2) {
              return this.currentProvider().wallet.approveSigned(tokenB.address, useNormalRouter).then(res => {
                this.logging.log(res);
              });
            }
          });
        } else if (!allowed2) {
          return this.currentProvider().wallet.approveSigned(tokenB.address, useNormalRouter).then(res => {
            this.logging.log(res);
          });
        } else {
          return Promise.resolve();
        }
      }
    }

    async checkAllowanceForLp(tokenA, tokenB) {
      let allowed = true;

      if (this.isBaseToken(tokenA)) {
        return true;
      }

      await this.getAllowance(tokenA, tokenB).then(allowance => {
        if (allowance.hasOwnProperty('remaining') && (!allowance.remaining || allowance.remaining < this.MAX_APPROVAL_VALUE)) {
          allowed = false;
        } else if (!allowance || allowance < this.MAX_APPROVAL_VALUE) {
          allowed = false;
        }
      }).catch(err => {
        allowed = false;
      });
      return allowed;
    }

    async approveRemove(tokenA, tokenB, pairAddress) {
      if (!pairAddress) {
        pairAddress = await this.getPairAddress(tokenA, tokenB);
      }

      let useNormalRouter = false;

      if (this.getLiquidityRouter(tokenA, tokenB) == this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.ROUTER_ADDRESS) {
        useNormalRouter = true;
      }

      let allowed = 0;

      if (this.App.NETWORK != 'TRON') {
        allowed = await this.currentProvider().wallet.getAllowance({
          address: pairAddress
        }, true, useNormalRouter);
      } else {
        allowed = await this.currentProvider().wallet.getAllowanceWithABI({
          address: pairAddress
        }, true, useNormalRouter);
      }

      if (allowed > this.MAX_APPROVAL_VALUE / 2) return Promise.resolve();
      return await this.currentProvider().wallet.approveSigned(pairAddress, useNormalRouter).then(res => {
        this.logging.log(res);
      });
    }

    async getPairAddress(tokenA, tokenB) {
      const parameters = [{
        type: _tronLink.default.PARAMETERS.ADDRESS,
        value: tokenA.address
      }, {
        type: _tronLink.default.PARAMETERS.ADDRESS,
        value: tokenB.address
      }];
      const address = await this.currentProvider().api.execute(this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.FACTORY_ADDRESS, this.CONTRACT_METHODS.GET_PAIR, undefined, parameters).catch(e => console.error(e));
      let ret = this.App.NETWORK == 'TRON' ? await this.toBase58(address) : address;
      return ret;
    }

    async toBase58(address, network) {
      if (!network) network = this.App.NETWORK;
      let provider;

      if (network == this.App.NETWORK) {
        provider = await this.provider.getProvider(network).api.resolve();
      } else {
        provider = await this.provider.getSecondaryProvider(network).api.resolve();
      }

      return provider.address.fromHex(address);
    }

    async removeLiquidity(fromToken, toToken, fromAmount, toAmount, lpTokens, pairAddress) {
      await this.approveRemove(fromToken, toToken, pairAddress);

      if (this.isBaseToken(fromToken)) {
        return this.removeLiquidityBase(fromToken, toToken, fromAmount, toAmount, lpTokens);
      } else if (this.isBaseToken(toToken)) {
        return this.removeLiquidityBase(toToken, fromToken, toAmount, fromAmount, lpTokens);
      } else {
        let sorted = this.currentProvider().api.sortAddresses(fromToken, toToken);

        if (sorted[0].address.toUpperCase() === fromToken.address.toUpperCase()) {
          return this.removeLiquidityTokenToken(fromToken, toToken, fromAmount, toAmount, lpTokens);
        } else {
          return this.removeLiquidityTokenToken(toToken, fromToken, toAmount, fromAmount, lpTokens);
        }
      }
    }

    async removeLiquidityBase(fromToken, toToken, fromAmount, toAmount, lpTokens) {
      this.logging.log('removeLiquidityBase');
      const deadline = (0, _tools.getDeadline5MinutesFromNow)();
      const options = {
        feeLimit: this._100_TRX,
        shouldPollResponse: true
      };
      const parameters = [{
        type: _tronLink.default.PARAMETERS.ADDRESS,
        value: toToken.address
      }, {
        type: _tronLink.default.PARAMETERS.UINT256,
        value: (0, _tools.toWei)(lpTokens.toString(), 18).toString()
      }, {
        type: _tronLink.default.PARAMETERS.UINT256,
        value: (0, _tools.toWei)((Number(toAmount) * (1 - this.SLIPPAGE_FEE)).toString(), toToken.decimals).toString()
      }, {
        type: _tronLink.default.PARAMETERS.UINT256,
        value: (0, _tools.toWei)((Number(fromAmount) * (1 - this.SLIPPAGE_FEE)).toString(), fromToken.decimals).toString()
      }, {
        type: _tronLink.default.PARAMETERS.ADDRESS,
        value: await this.currentProvider().wallet.getAddress()
      }, {
        type: _tronLink.default.PARAMETERS.UINT256,
        value: deadline
      }];
      let method = this.CONTRACT_METHODS.REMOVE_LIQUIDITY_BASE;
      const routerAddress = this.getLiquidityRouter(fromToken, toToken);

      if (routerAddress == this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.ROUTER_ADDRESS) {
        method = this.CONTRACT_METHODS.REMOVE_LIQUIDITY_BASE_TAXED;
      }

      const transaction = await this.currentProvider().wallet.send(routerAddress, method, options, parameters);
      return transaction;
    }

    async removeLiquidityTokenToken(fromToken, toToken, fromAmount, toAmount, lpTokens) {
      this.logging.log('removeLiquidityTokenToken');
      const deadline = (0, _tools.getDeadline5MinutesFromNow)();
      const options = {
        feeLimit: this._100_TRX,
        shouldPollResponse: true
      };
      const parameters = [{
        type: _tronLink.default.PARAMETERS.ADDRESS,
        value: fromToken.address
      }, {
        type: _tronLink.default.PARAMETERS.ADDRESS,
        value: toToken.address
      }, {
        type: _tronLink.default.PARAMETERS.UINT256,
        value: (0, _tools.toWei)(lpTokens.toString(), 18).toString()
      }, {
        type: _tronLink.default.PARAMETERS.UINT256,
        value: (0, _tools.toWei)((Number(fromAmount) * (1 - this.SLIPPAGE_FEE)).toString(), fromToken.decimals).toString()
      }, {
        type: _tronLink.default.PARAMETERS.UINT256,
        value: (0, _tools.toWei)((Number(toAmount) * (1 - this.SLIPPAGE_FEE)).toString(), toToken.decimals).toString()
      }, {
        type: _tronLink.default.PARAMETERS.ADDRESS,
        value: await this.currentProvider().wallet.getAddress()
      }, {
        type: _tronLink.default.PARAMETERS.UINT256,
        value: deadline
      }];
      const transaction = await this.currentProvider().wallet.send(this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.LIQUIDITY_ROUTER, this.CONTRACT_METHODS.REMOVE_LIQUIDITY, options, parameters);
      return transaction;
    }

    async addLiquidity(fromToken, toToken, fromAmount, toAmount) {
      let approved = true;
      await this.approveAdd(fromToken, toToken);
      if (!approved) return Promise.reject();

      if (this.isBaseToken(fromToken)) {
        return this.addLiquidityBase(fromToken, toToken, fromAmount, toAmount);
      } else if (this.isBaseToken(toToken)) {
        return this.addLiquidityBase(toToken, fromToken, toAmount, fromAmount);
      } else {
        let sorted = this.currentProvider().api.sortAddresses(fromToken, toToken);

        if (sorted[0].address.toUpperCase() === fromToken.address.toUpperCase()) {
          return this.addLiquidityTokenToken(fromToken, toToken, fromAmount, toAmount);
        } else {
          return this.addLiquidityTokenToken(toToken, fromToken, toAmount, fromAmount);
        }
      }
    }

    async addLiquidityBase(fromToken, toToken, fromAmount, toAmount) {
      this.logging.log('addLiquidityBase');
      const deadline = (0, _tools.getDeadline5MinutesFromNow)();
      const options = {
        value: (0, _tools.toWei)(fromAmount.toString(), fromToken.decimals).toString()
      };
      const parameters = [{
        type: _tronLink.default.PARAMETERS.ADDRESS,
        value: toToken.address
      }, {
        type: _tronLink.default.PARAMETERS.UINT256,
        value: (0, _tools.toWei)(toAmount.toString(), toToken.decimals).toString()
      }, {
        type: _tronLink.default.PARAMETERS.UINT256,
        value: (0, _tools.toWei)((Number(toAmount) * (1 - this.SLIPPAGE_FEE)).toString(), toToken.decimals).toString()
      }, {
        type: _tronLink.default.PARAMETERS.UINT256,
        value: (0, _tools.toWei)((Number(fromAmount) * (1 - this.SLIPPAGE_FEE)).toString(), fromToken.decimals).toString()
      }, {
        type: _tronLink.default.PARAMETERS.ADDRESS,
        value: await this.currentProvider().wallet.getAddress()
      }, {
        type: _tronLink.default.PARAMETERS.UINT256,
        value: deadline
      }];
      const transaction = await this.currentProvider().wallet.send(this.getLiquidityRouter(fromToken, toToken), this.CONTRACT_METHODS.ADD_LIQUIDITY_BASE, options, parameters);
      return transaction;
    }

    async addLiquidityTokenToken(fromToken, toToken, fromAmount, toAmount) {
      this.logging.log('addLiquidityTokenToken');
      const deadline = (0, _tools.getDeadline5MinutesFromNow)();
      const options = {
        feeLimit: this._100_TRX,
        shouldPollResponse: true
      };
      const parameters = [{
        type: _tronLink.default.PARAMETERS.ADDRESS,
        value: fromToken.address
      }, {
        type: _tronLink.default.PARAMETERS.ADDRESS,
        value: toToken.address
      }, {
        type: _tronLink.default.PARAMETERS.UINT256,
        value: (0, _tools.toWei)(fromAmount.toString(), fromToken.decimals).toString()
      }, {
        type: _tronLink.default.PARAMETERS.UINT256,
        value: (0, _tools.toWei)(toAmount.toString(), toToken.decimals).toString()
      }, {
        type: _tronLink.default.PARAMETERS.UINT256,
        value: (0, _tools.toWei)((Number(fromAmount) * (1 - this.SLIPPAGE_FEE)).toString(), fromToken.decimals).toString()
      }, {
        type: _tronLink.default.PARAMETERS.UINT256,
        value: (0, _tools.toWei)((Number(toAmount) * (1 - this.SLIPPAGE_FEE)).toString(), toToken.decimals).toString()
      }, {
        type: _tronLink.default.PARAMETERS.ADDRESS,
        value: await this.currentProvider().wallet.getAddress()
      }, {
        type: _tronLink.default.PARAMETERS.UINT256,
        value: deadline
      }];
      const transaction = await this.currentProvider().wallet.send(this.getLiquidityRouter(fromToken, toToken), this.CONTRACT_METHODS.ADD_LIQUIDITY, options, parameters);
      return transaction;
    }

    getLiquidityRouter(fromToken, toToken) {
      if (fromToken.useNormalRouterForLiquidity || toToken.useNormalRouterForLiquidity) {
        return this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.ROUTER_ADDRESS;
      }

      return this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.LIQUIDITY_ROUTER;
    }

    async getEstimate(amount, fromToken, toToken) {
      const currentLiquidity = await this.getCachedBalance(fromToken, toToken);
      const fromLiquidity = currentLiquidity[fromToken.address.toUpperCase()];
      const toLiquidity = currentLiquidity[toToken.address.toUpperCase()];
      const ratio = fromLiquidity / toLiquidity;
      const estimate = await this.toBigNumber(amount / ratio, fromToken);
      return this.fromBigNumber(estimate, toToken);
    }

    async getAmount(estimate, fromToken, toToken) {
      const currentLiquidity = await this.getCachedBalance(fromToken, toToken);
      const fromLiquidity = currentLiquidity[fromToken.address.toUpperCase()];
      const toLiquidity = currentLiquidity[toToken.address.toUpperCase()];
      const ratio = toLiquidity / fromLiquidity;
      const amount = await this.toBigNumber(estimate / ratio, toToken);
      return this.fromBigNumber(amount, fromToken);
    }

    fromBigNumber(amount, token) {
      return amount / Math.pow(10, token.decimals);
    }

    async toBigNumber(amount, token) {
      return (0, _tools.toBigNumber)(Math.floor(amount * Math.pow(10, token.decimals)));
    }

    isBaseToken(token) {
      let conf = this.App.JM.NETWORKS[this.App.NETWORK];
      if (!conf.TOKENS[conf.BASE_TOKEN]) return false;
      return token.address === conf.TOKENS[conf.BASE_TOKEN].address;
    }

    currentProvider() {
      return this.provider.getProvider(this.App.NETWORK);
    }

    getProviderByNetwork(network) {
      if (network == this.App.NETWORK) {
        return this.provider.getProvider(network);
      } else {
        return this.provider.getSecondaryProvider(network);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "tokenPair", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "logging", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "CACHED_BALANCES", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  })), _class);
  _exports.default = JustMoneyLiquidityService;
});