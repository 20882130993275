define("justmoney-swap/providers/tron-web", ["exports", "tronweb", "justmoney-swap/providers/base-provider"], function (_exports, _tronweb, _baseProvider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class TronWebProvider extends _baseProvider.default {
    constructor(network, owner) {
      super(network, owner);

      _defineProperty(this, "tronWeb", void 0);

      _defineProperty(this, "addresses", ['TMv6wLhwJ3SQxcSNqo3V4B7d9C6s2UniyN', 'TY2t4NXPvhTLWxxyrfwKtkpEpyKZunt4Dg', 'TXU664Y72Dvm7ydhHHmCYxVSRVhzB2eVUz', 'TQ26quh2DhrCK8c85revViEqsjAczggA1D', 'TGAArPYJJvJeHQ4akXi86tdxnQxTQp1K29', 'TRZGVYAXG1LKZbW1VWChDYVLCf8tuxtLhd', 'TC383AYH5APzPgy1LwYECBh4epWhE86842', 'TTHnhsXCM91d4ctVXmjN6swS9SkuNB8ngs', 'THsDecnYSRtRn4KS8PPnCzDDmTe3uTnz9v', 'TWPzhW1x5oDdh7LG2iD6CCSFs4H71uQ2FF', 'TVrpasibxSqrGeZbQCyeMuMMs9QEpYnnAs', 'TAia44YqBhaZdCya9xypH9vzmB3xMzkQgX', 'TPhGMUd8x9qXvZ5XW5mhqftftoQkocpay6', 'TE6J7ei45v2bbK8o4cnxwng1FuJGjaYaoh', 'TLD4azabJ7baMttdSm5HrUwvQen83WDyvY', 'TUkf7yagVXTvBatwi8StBv9FCM99oeBTj6', 'TCvnS5uxJKHM15SszuRtRZhAQJzm4F4ZiT', 'TKWaDKJrcbwXPjGSKHZvzuuzTrW9FDArPn', 'THS563xQ9gJfemMgmScQiBFgbZdWbQUmBx', 'TAc1KDqTB4mKjvT4MjE6wt7epAMVKNVzXe', 'TEc6sSntJuWdscwz5ApC78cXjZUKMYGkHm', 'TBP9Eo664JpcwqUn64JrnB2iUAWkp7GVGz', 'TUs61fRx6PTyQk9uZG9D7Fa5z3ioWKrP8E', 'TSamuGraF8SNRQw6vzrJbgXFdEqv32EtEF', 'TNExK2QSJGLCvqv4eEAP7P1V3NEV2MaMQm', 'TAWWfLudSTX22KR2AaHFdbAZVAqrLeEVMj', 'TKbTzd6uorV5PFrY6soEZmk6hH2XEWDjna', 'TW2Sk1uiEY99prvYEtPjDe9huUaswhEjAU', 'TSNumpHCKBLTQtky1nEGeRhM3mJUp4TAWq', 'TBVmgb3EsVDeLpNuwhK8MKLrxDKpYwGHWy', 'THRywp2wghcrLtHkokuV8mreRAopq5URxb', 'THAF7juAoCC6kmm5GGBcU3mrNdkB4Rzznd', 'TCKWJ4GLxL7WC1vsVRBg78ddvsw6hP4gMN', 'TJYQ8EDJRcGTJuB4tZgt2DYJQSBMr4AKBR', 'TTXkwUEXknbU75mVretvY9Fqw4u26M67vq', 'TVG2V1fdSsTCmJFrBzyeNKAiwP8YjnK1Us', 'TRodgPM4PdfXWcESw5T5E8BUQJ8TBzQh5N', 'TQM631RcS9T7myGwn5ZpCjUdBJY5MP4c5A', 'TY8tB5vNkDQTmmzP3XhNBgxtBZikCUUyLu', 'TCWzN2FdZe7TrhJreE1hiUNXvfGw3JbX8t']);

      _defineProperty(this, "apiKeys", ['5cd40795-a262-4d82-8b80-a3284fc7f56b', '9865a6aa-6276-4e93-af27-00a0fb7eafda', '3d3546d3-704b-4eb6-b44d-3b0eeebed5de', '590fdac7-8a8b-4e1c-ba75-6e3ad0be34eb', 'aad36c24-eff1-4ccb-a0bc-890631d5367b', '681e7e52-9598-4437-9373-b6202042ecab']);

      _defineProperty(this, "cachedContracts", []);

      this.tronWeb = new _tronweb.default({
        fullHost: this.App.JM.NETWORKS[this.network].API.url,
        headers: {
          'TRON-PRO-API-KEY': this.getRandomApiKey()
        }
      });
      this.tronWeb.setAddress(this.getRandomAddress());
    }

    getRandomAddress() {
      return this.addresses[Math.floor(Math.random() * this.addresses.length)];
    }

    getRandomApiKey() {
      return this.apiKeys[Math.floor(Math.random() * this.apiKeys.length)];
    }

    resolve() {
      return Promise.resolve(this.tronWeb);
    }

    sortAddresses(fromToken, toToken) {
      const fromHex = fromToken.hex || this.tronWeb.address.toHex(fromToken.address);
      const toHex = toToken.hex || this.tronWeb.address.toHex(toToken.address);
      return fromHex < toHex ? [fromToken, toToken] : [toToken, fromToken];
    }

    async execute(address, method, options, parameters) {
      let paramsArr = parameters.map(val => {
        return val.value;
      });
      const tronWeb = this.tronWeb;
      this.cachedContracts[address] = this.cachedContracts[address] || (await tronWeb.contract().at(address));
      const contract = this.cachedContracts[address];
      let ret = await contract.methods[method].apply(this, paramsArr).call();
      return ret;
    }

    validateResult(transaction) {
      if (!transaction || !transaction.result || !transaction.result.result) {
        throw new Error(`Transaction failed`);
      }
    }

  }

  _exports.default = TronWebProvider;
});