define("justmoney-swap/providers/wallets/metamask-widget", ["exports", "justmoney-swap/providers/base-provider"], function (_exports, _baseProvider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class MetamaskWidgetProvider extends _baseProvider.default {
    constructor(network, owner) {
      super(network, owner);

      _defineProperty(this, "tempbalance", null);

      _defineProperty(this, "lastBalanceCheck", 0);

      _defineProperty(this, "lastBalanceCheckForToken", {});

      _defineProperty(this, "cachedTokenBalance", {});

      _defineProperty(this, "balanceCheckPromiseToken", {});

      _defineProperty(this, "accounts", void 0);

      setInterval(() => {
        if (window.App.isVisible && this.connected) {
          this.update();
        }
      }, 20000);
      window.addEventListener('message', e => {
        /*if (e.data.message && e.data.message.action == 'accountChanged') {
            if (e.data.message.data.address != this.address) {
                window.location.reload();
            }
        }
        if (e.data.message && e.data.message.action == 'setNode') {
            window.location.reload();
        }*/
      });
    }

    addMessageListener(name, passback) {
      let func = event => {
        if (event.data && event.data.name == 'JMSwapResponse' + this.network && event.data.functionName == name) {
          passback(event.data);
        }

        if (event.data && event.data.name == 'JMSwapResponseWeb3' && event.data.functionName == name) {
          passback(event.data);
        }
      };

      window.addEventListener('message', func, false);
      return func;
    }

    removeListener(listener) {
      window.removeEventListener('message', listener);
    }

    postMessage(functionName, data) {
      let finalData = {
        name: 'JMSwapFunction' + this.network,
        functionName: functionName,
        functionParams: data,
        iframeID: window.App.widgetOptions.iframeID
      };
      window.parent.postMessage(finalData, '*');
      let finalData2 = {
        name: 'JMSwapFunctionWeb3',
        functionName: functionName,
        functionParams: data,
        iframeID: window.App.widgetOptions.iframeID
      };
      window.parent.postMessage(finalData, '*');
      window.parent.postMessage(finalData2, '*');
    }

    async resolve() {
      return new Promise(resolve => {});
    }

    async connect() {
      return new Promise(resolve => {
        let listener = this.addMessageListener('connected', () => {
          this.removeListener(listener);
          this.connected = true;
          this.update();

          for (let func in this.onConnectedPassbacks) {
            this.onConnectedPassbacks[func]();
          }

          resolve();
          window.amplitude.getInstance().logEvent('WALLET_CONNECT', {
            host: this.App.widgetOptions ? this.App.widgetOptions.host : window.location.host,
            widget: true,
            wallet: 'MetaMask',
            network: window.App.NETWORK
          });
        });
        this.postMessage('connect', ['MetaMask', this.network]);
      });
    }

    async update() {
      if (this.connected) {
        return new Promise(resolve => {
          let listener = this.addMessageListener('updated', data => {
            this.removeListener(listener);
            this.address = data.data.address;
            this.name = data.data.name;
            this.balance = data.data.balance;
            resolve();
          });
          this.postMessage('update');
        });
      }
    }

    async getAddress() {
      return this.address;
    }

    async getName() {
      return this.name;
    }

    async getBalance(token) {
      if (this.isBaseToken(token)) {
        return this.getBalanceForBase();
      } else {
        return this.getBalanceForToken(token);
      }
    }

    async getBalanceForBase() {
      if (new Date().getTime() > this.lastBalanceCheck + 5000 || this.tempbalance === null) {
        this.lastBalanceCheck = new Date().getTime();
        this.tempbalance = 0;
        this.balanceCheckPromise = new Promise(resolve => {
          let listener = this.addMessageListener('balanceForBaseResponse', data => {
            this.removeListener(listener);
            this.tempbalance = data.data;
            resolve(data.data);
          });
          this.postMessage('getBalanceForBase');
        });
        return await this.balanceCheckPromise;
      } else {
        return await this.balanceCheckPromise;
      }
    }

    async getBalanceForToken(token) {
      if (new Date().getTime() > this.lastBalanceCheckForToken[token.address] + 5000 || this.cachedTokenBalance[token.address] === undefined) {
        this.lastBalanceCheckForToken[token.address] = new Date().getTime();
        this.cachedTokenBalance[token.address] = 0;
        this.balanceCheckPromiseToken[token.address] = new Promise(resolve => {
          let listener = this.addMessageListener('balanceForTokenResponse' + token.symbol, data => {
            this.removeListener(listener);
            this.lastBalanceCheckForToken[token.address] = data.data;
            resolve(data.data, token);
          });
          this.postMessage('getBalanceForToken', [token]);
        });
        return await this.balanceCheckPromiseToken[token.address];
      } else {
        return this.balanceCheckPromiseToken[token.address];
      }
    }

    fromBigNumber(amount, token) {
      return amount / Math.pow(10, token.decimals);
    }

    async getAllowance(token, lp, useNormalRouter) {
      let router = this.App.JM.NETWORKS[this.network].EXCHANGE.ROUTER_ADDRESS;

      if (lp) {
        router = useNormalRouter ? this.App.JM.NETWORKS[this.network].EXCHANGE.ROUTER_ADDRESS : this.App.JM.NETWORKS[this.network].EXCHANGE.LIQUIDITY_ROUTER;
      }

      return new Promise(resolve => {
        let listener = this.addMessageListener('allowanceResponse', data => {
          this.removeListener(listener);
          resolve(Number(data.data));
        });
        this.postMessage('getAllowance', [token.address, router]);
      });
    }

    async approve(token) {
      return new Promise((resolve, reject) => {
        let listener = this.addMessageListener('approvalResponse', data => {
          this.removeListener(listener);

          if (data.data === false) {
            reject();
          } else {
            resolve(data.data);
          }
        });
        this.postMessage('approve', [token.address, this.App.JM.NETWORKS[this.network].EXCHANGE.ROUTER_ADDRESS]);
      });
    }

    async approveSigned(address, useNormalRouter) {
      return new Promise(resolve => {
        let router = useNormalRouter ? this.App.JM.NETWORKS[this.network].EXCHANGE.ROUTER_ADDRESS : this.App.JM.NETWORKS[this.network].EXCHANGE.LIQUIDITY_ROUTER;
        let listener = this.addMessageListener('approvalSignedResponse', data => {
          this.removeListener(listener);
          resolve(data.data);
        });
        this.postMessage('approveSigned', [address, router]);
      });
    }

    async execute(address, method, options, parameters) {
      return new Promise((resolve, reject) => {
        let listener = this.addMessageListener('executeResponse', data => {
          this.removeListener(listener);

          if (data.data) {
            resolve(data.data);
          } else {
            reject(data.data);
          }
        });
        let abi = this.App.JM.NETWORKS[this.network].ABI[address] || this.minABI;
        this.postMessage('execute', [address, method, options, parameters, abi]);
      });
    }

    async send(address, method, options, parameters) {
      return new Promise((resolve, reject) => {
        let listener = this.addMessageListener('sendResponse', data => {
          this.removeListener(listener);
          let ret = data.data;

          if (data.data.transactionHash) {
            ret.txid = data.data.transactionHash;
          }

          if (ret) {
            resolve(ret);
          } else {
            reject(ret);
          }
        });
        let abi = this.App.JM.NETWORKS[this.network].ABI[address] || this.minABI;
        this.postMessage('send', [address, method, options, parameters, abi]);
      });
    }

  }

  _exports.default = MetamaskWidgetProvider;
});