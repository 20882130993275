define("justmoney-swap/providers/web3", ["exports", "justmoney-swap/providers/base-provider", "@ember/service"], function (_exports, _baseProvider, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Web3Provider = (_class = class Web3Provider extends _baseProvider.default {
    constructor(network, owner) {
      super(network, owner);

      _initializerDefineProperty(this, "provider", _descriptor, this);

      _defineProperty(this, "cachedContracts", []);

      this.web3 = new Web3(this.App.JM.NETWORKS[network].API.url);
    }

    async resolve() {
      return new Promise(resolve => {
        if (this.web3) return resolve(this.web3);
        this.web3 = new Web3(this.App.JM.NETWORKS[this.network].API.url);
        resolve(this.web3);
      });
    }

    async getAddress() {
      return null;
    }

    async getName() {
      return null;
    }

    fromBigNumber(amount, token) {
      return amount / Math.pow(10, token.decimals);
    }

    isBaseToken(token) {
      let conf = this.App.JM.NETWORKS[this.network];
      return token.address === conf.TOKENS[conf.BASE_TOKEN].address;
    }

    sortAddresses(from, to) {
      const fromHex = from.hex || this.web3.utils.toHex(from.address);
      const toHex = to.hex || this.web3.utils.toHex(to.address);
      return fromHex < toHex ? [from, to] : [to, from];
    }

    async execute(address, method, options, parameters) {
      const web3 = await this.resolve();
      const wallet = this.network == this.App.NETWORK ? await this.provider.getProvider(this.network).wallet : null;
      let abi = this.App.JM.NETWORKS[this.network].ABI[address] || this.minABI;
      const swapApi = new web3.eth.Contract(abi, address);
      if (!options) options = {};
      options.from = wallet && this.connected ? await wallet.getAddress() : null;

      if (parameters) {
        let paramsArr = parameters.map(val => {
          return val.value;
        });
        let ret = await swapApi.methods[method].apply(this, paramsArr).call(options).catch(e => this.logging.log(e));

        if (ret.transactionHash) {
          ret.txid = ret.transactionHash;
        }

        return ret;
      } else {
        let ret = await swapApi.methods[method].apply(this).call(options).catch(e => this.logging.log(e));

        if (ret.transactionHash) {
          ret.txid = ret.transactionHash;
        }

        return ret;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = Web3Provider;
});