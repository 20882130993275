define("justmoney-swap/helpers/indexOf", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.indexOf = indexOf;

  function indexOf(params) {
    if (params[0] && params[0].indexOf(params[1]) > -1) {
      return true;
    }

    return false;
  }

  var _default = (0, _helper.helper)(indexOf);

  _exports.default = _default;
});