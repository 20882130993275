define("justmoney-swap/services/provider", ["exports", "@ember/service", "@ember/application", "@glimmer/tracking", "justmoney-swap/core/base-service", "justmoney-swap/utils/metamask", "justmoney-swap/providers/tron-web", "justmoney-swap/providers/web3", "justmoney-swap/providers/wallets/tron-link-widget", "justmoney-swap/providers/wallets/tron-link", "justmoney-swap/providers/wallets/binancewallet", "justmoney-swap/providers/wallets/binancewallet-widget", "justmoney-swap/providers/wallets/metamask-widget", "justmoney-swap/providers/wallets/metamask", "justmoney-swap/providers/wallets/wconnect", "justmoney-swap/providers/wallets/metamaskzenith", "justmoney-swap/providers/wallets/wconnectzenith", "justmoney-swap/providers/wallets/metamasketh", "justmoney-swap/providers/wallets/metamaskbttc", "justmoney-swap/providers/wallets/wconnectbttc", "justmoney-swap/providers/wallets/metamaskpoly", "justmoney-swap/providers/wallets/wconnectpoly"], function (_exports, _service, _application, _tracking, _baseService, _metamask, _tronWeb, _web, _tronLinkWidget, _tronLink, _binancewallet, _binancewalletWidget, _metamaskWidget, _metamask2, _wconnect, _metamaskzenith, _wconnectzenith, _metamasketh, _metamaskbttc, _wconnectbttc, _metamaskpoly, _wconnectpoly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProviderService = (_class = class ProviderService extends _baseService.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "justMoneyLiquidity", _descriptor, this);

      _initializerDefineProperty(this, "tokenPair", _descriptor2, this);

      _initializerDefineProperty(this, "wallet", _descriptor3, this);

      _initializerDefineProperty(this, "api", _descriptor4, this);

      _initializerDefineProperty(this, "connectedTo", _descriptor5, this);

      _initializerDefineProperty(this, "chain", _descriptor6, this);

      _initializerDefineProperty(this, "secondaryChain", _descriptor7, this);

      _initializerDefineProperty(this, "connectedToSecondary", _descriptor8, this);

      _initializerDefineProperty(this, "secondaryApi", _descriptor9, this);

      _defineProperty(this, "providers", {});

      _defineProperty(this, "onConnectedPassbacks", []);

      _defineProperty(this, "onDisconnectedPassbacks", []);

      _defineProperty(this, "onNetworkChangePassbacks", []);

      _initializerDefineProperty(this, "connected", _descriptor10, this);
    }
    /**
     * Get correct wallet service by network name
     * @param chain
     * @returns {BaseWalletService}
     */


    getProvider(chain) {
      let networkChanged = false;
      this.chain = chain;

      if (this.connectedTo == chain) {
        return {
          wallet: this.wallet,
          api: this.api
        };
      }

      if (this.connectedTo != chain) {
        if (this.connectedTo && this.wallet) {
          this.wallet.disconnect();
          this.wallet = null;
          this.connected = false;
        }

        this.tokenPair.reset();
        this.justMoneyLiquidity.reset();
        this.App.set('NETWORK', chain);
        this.App.set('NETWORKNAME', this.App.JM.NETWORKS[this.App.NETWORK].NAME);

        try {
          if (window.App.staging) {
            this.setCookie('SELECTED_NETWORK', chain, true);
          } else {
            this.setCookie('SELECTED_NETWORK', chain);
          }
        } catch (e) {}

        networkChanged = true;
      }

      switch (chain) {
        case 'TRON':
          this.api = new _tronWeb.default(chain, (0, _application.getOwner)(this));
          this.connectedTo = 'TRON';
          break;

        case 'BSC':
          this.api = new _web.default(chain, (0, _application.getOwner)(this));
          this.connectedTo = 'BSC';
          break;

        case 'POLY':
          this.api = new _web.default(chain, (0, _application.getOwner)(this));
          this.connectedTo = 'POLY';
          break;

        case 'ZENITH':
          this.api = new _web.default(chain, (0, _application.getOwner)(this));
          this.connectedTo = 'ZENITH';
          break;

        case 'BTTC':
          this.api = new _web.default(chain, (0, _application.getOwner)(this));
          this.connectedTo = 'BTTC';
          break;

        case 'ETH':
          this.api = new _web.default(chain, (0, _application.getOwner)(this));
          this.connectedTo = 'ETH';
          break;

        default:
          this.api = new _tronWeb.default(chain, (0, _application.getOwner)(this));
          break;
      }

      this.providers[chain] = {
        api: this.api
      };

      if (networkChanged) {
        for (let func in this.onNetworkChangePassbacks) {
          this.onNetworkChangePassbacks[func]();
        }

        this.dispatch('NETWORK_CHANGED', chain);
      }

      return {
        wallet: this.wallet,
        api: this.api
      };
    }
    /**
     * Get correct wallet service by network name
     * @param chain
     * @returns {BaseWalletService}
     */


    getSecondaryProvider(chain) {
      this.secondaryChain = chain;

      if (this.providers[chain]) {
        return {
          api: this.providers[chain].api
        };
      }

      switch (chain) {
        case 'TRON':
          this.secondaryApi = new _tronWeb.default('TRON', (0, _application.getOwner)(this));
          this.connectedToSecondary = 'TRON';
          break;

        case 'BSC':
          this.secondaryApi = new _web.default(chain, (0, _application.getOwner)(this));
          this.connectedToSecondary = 'BSC';
          break;

        case 'POLY':
          this.secondaryApi = new _web.default(chain, (0, _application.getOwner)(this));
          this.connectedToSecondary = 'POLY';
          break;

        case 'ZENITH':
          this.secondaryApi = new _web.default(chain, (0, _application.getOwner)(this));
          this.connectedToSecondary = 'ZENITH';
          break;

        case 'BTTC':
          this.secondaryApi = new _web.default(chain, (0, _application.getOwner)(this));
          this.connectedToSecondary = 'BTTC';
          break;

        case 'ETH':
          this.secondaryApi = new _web.default(chain, (0, _application.getOwner)(this));
          this.connectedToSecondary = 'ETH';
          break;

        default:
          this.secondaryApi = new _tronWeb.default('TRON', (0, _application.getOwner)(this));
          break;
      }

      this.providers[chain] = {
        api: this.secondaryApi
      };
      return {
        api: this.secondaryApi
      };
    }

    setCookie(name, network) {
      var myDate = new Date();
      myDate.setMonth(myDate.getMonth() + 12);
      var hostNameArr = window.location.hostname.split('.');
      document.cookie = name + "=" + network + ";expires=" + myDate + ";domain=." + hostNameArr[hostNameArr.length - 2] + '.' + hostNameArr[hostNameArr.length - 1] + ";path=/";
    }

    getCurrentProvider() {
      return {
        wallet: this.wallet,
        api: this.api
      };
    }

    async connect(walletName, passback) {
      if (!this.chain) {
        this.getProvider(this.App.NETWORK);
      }

      if (passback) {
        this.onConnectedPassbacks.push(passback);
      }

      switch (this.chain) {
        case 'TRON':
          this.wallet = this.App.widgetOptions ? new _tronLinkWidget.default(this.chain, (0, _application.getOwner)(this)) : new _tronLink.default(this.chain, (0, _application.getOwner)(this));
          break;

        case 'BSC':
          if (walletName == 'BINANCEWALLET') {
            this.wallet = this.App.widgetOptions ? new _binancewalletWidget.default(this.chain, (0, _application.getOwner)(this)) : new _binancewallet.default(this.chain, (0, _application.getOwner)(this));
          } else if (walletName == 'TRUSTWALLET') {
            this.wallet = this.App.widgetOptions ? new _metamaskWidget.default(this.chain, (0, _application.getOwner)(this)) : new _metamask2.default(this.chain, (0, _application.getOwner)(this));
          } else if (walletName == 'WALLETCONNECT') {
            this.wallet = this.App.widgetOptions ? new _wconnect.default(this.chain, (0, _application.getOwner)(this)) : new _wconnect.default(this.chain, (0, _application.getOwner)(this));
          } else {
            if (window.ethereum && !this.App.widgetOptions) {
              await (0, _metamask.addNetwork)(this.chain);
            }

            this.wallet = this.App.widgetOptions ? new _metamaskWidget.default(this.chain, (0, _application.getOwner)(this)) : new _metamask2.default(this.chain, (0, _application.getOwner)(this));
          }

          break;

        case 'POLY':
          if (walletName == 'TRUSTWALLET') {
            this.wallet = this.App.widgetOptions ? new _metamaskWidget.default(this.chain, (0, _application.getOwner)(this)) : new _metamaskpoly.default(this.chain, (0, _application.getOwner)(this));
          } else if (walletName == 'WALLETCONNECT') {
            this.wallet = this.App.widgetOptions ? new _wconnectpoly.default(this.chain, (0, _application.getOwner)(this)) : new _wconnectpoly.default(this.chain, (0, _application.getOwner)(this));
          } else {
            if (window.ethereum && !this.App.widgetOptions && this.App.JM.ENV != 'DEVELOPMENT') {
              await (0, _metamask.addNetwork)(this.chain);
            }

            this.wallet = this.App.widgetOptions ? new _metamaskWidget.default(this.chain, (0, _application.getOwner)(this)) : new _metamaskpoly.default(this.chain, (0, _application.getOwner)(this));
          }

          break;

        case 'BTTC':
          if (walletName == 'TRUSTWALLET') {
            this.wallet = this.App.widgetOptions ? new _metamaskWidget.default(this.chain, (0, _application.getOwner)(this)) : new _metamaskbttc.default(this.chain, (0, _application.getOwner)(this));
          } else if (walletName == 'WALLETCONNECT') {
            this.wallet = this.App.widgetOptions ? new _wconnectbttc.default(this.chain, (0, _application.getOwner)(this)) : new _wconnectbttc.default(this.chain, (0, _application.getOwner)(this));
          } else {
            if (window.ethereum && !this.App.widgetOptions) {
              await (0, _metamask.addNetwork)(this.chain);
            }

            this.wallet = this.App.widgetOptions ? new _metamaskWidget.default(this.chain, (0, _application.getOwner)(this)) : new _metamaskbttc.default(this.chain, (0, _application.getOwner)(this));
          }

          break;

        case 'ETH':
          if (walletName == 'TRUSTWALLET') {
            this.wallet = this.App.widgetOptions ? new _metamaskWidget.default(this.chain, (0, _application.getOwner)(this)) : new _metamasketh.default(this.chain, (0, _application.getOwner)(this));
          } else if (walletName == 'WALLETCONNECT') {
            this.wallet = this.App.widgetOptions ? new _wconnect.default(this.chain, (0, _application.getOwner)(this)) : new _wconnect.default(this.chain, (0, _application.getOwner)(this));
          } else {
            if (window.ethereum && !this.App.widgetOptions) {
              await (0, _metamask.addNetwork)(this.chain);
            }

            this.wallet = this.App.widgetOptions ? new _metamaskWidget.default(this.chain, (0, _application.getOwner)(this)) : new _metamasketh.default(this.chain, (0, _application.getOwner)(this));
          }

          break;

        case 'ZENITH':
          if (walletName == 'TRUSTWALLET') {
            this.wallet = this.App.widgetOptions ? new _metamaskWidget.default(this.chain, (0, _application.getOwner)(this)) : new _metamaskzenith.default(this.chain, (0, _application.getOwner)(this));
          } else if (walletName == 'WALLETCONNECT') {
            this.wallet = this.App.widgetOptions ? new _wconnectzenith.default(this.chain, (0, _application.getOwner)(this)) : new _wconnectzenith.default(this.chain, (0, _application.getOwner)(this));
          } else {
            if (window.ethereum && !this.App.widgetOptions) {
              await (0, _metamask.addNetwork)(this.chain);
            }

            this.wallet = this.App.widgetOptions ? new _metamaskWidget.default(this.chain, (0, _application.getOwner)(this)) : new _metamaskzenith.default(this.chain, (0, _application.getOwner)(this));
          }

          break;

        default:
          this.wallet = new _tronLink.default(this.chain, (0, _application.getOwner)(this));
          break;
      }

      this.providers[this.chain] = {
        api: this.providers[this.chain].api,
        wallet: this.wallet
      };
      this.wallet.onConnected(this._onConnect.bind(this));
      this.wallet.onDisconnected(this._onDisconnect.bind(this));
      return this.wallet.connect();
    }

    onConnected(passback) {
      if (this.connected) {
        passback();
      } else {
        this.onConnectedPassbacks.push(passback);
      }
    }

    onNetworkChange(passback) {
      this.onNetworkChangePassbacks.push(passback);
    }

    onDisconnected(passback) {
      this.onDisconnectedPassbacks.push(passback);
    }

    _onConnect() {
      for (let func in this.onConnectedPassbacks) {
        this.onConnectedPassbacks[func]();
      }

      document.dispatchEvent(new CustomEvent('WALLET_CONNECTED', {
        detail: this
      }));
      this.connected = true;
    }

    _onDisconnect() {
      this.connected = false;

      for (let func in this.onDisconnectedPassbacks) {
        this.onDisconnectedPassbacks[func]();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "justMoneyLiquidity", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "tokenPair", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "wallet", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "api", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "connectedTo", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "chain", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "secondaryChain", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "connectedToSecondary", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "secondaryApi", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "connected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class);
  _exports.default = ProviderService;
});