define("justmoney-swap/components/chart-component/component", ["exports", "@ember/object", "justmoney-swap/core/base-component", "lightweight-charts", "@glimmer/tracking", "@ember/service"], function (_exports, _object, _baseComponent, _lightweightCharts, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChartComponent = (_class = class ChartComponent extends _baseComponent.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "loading", _descriptor, this);

      _initializerDefineProperty(this, "lscache", _descriptor2, this);

      _initializerDefineProperty(this, "nodata", _descriptor3, this);

      this.addListener('visibilitychange', async event => {
        if (this.chartElement && !document.hidden && new Date().getTime() > this.lastUpdate + 60000) {
          if (this.args.type == 'line') {
            this.updateLineChart(this.chartElement);
          } else {
            this.updateCandlesChart(this.chartElement);
          }
        }
      });
    }

    async createCandlesChart(element) {
      const tokenA = this.args.tokenA;
      const tokenB = this.args.tokenB;
      const timeInterval = this.args.time;
      this.precision = this.args.precision || 8;
      this.lastUpdate = new Date().getTime();
      this.chart = _lightweightCharts.default.createChart(element, {
        width: this.width,
        height: this.height,
        layout: {
          backgroundColor: 'transparent',
          textColor: 'rgba(255, 255, 255, 0.9)',
          fontSize: 8
        },
        grid: {
          vertLines: {
            color: 'rgba(197, 203, 206, 0.05)'
          },
          horzLines: {
            color: 'rgba(197, 203, 206, 0.05)'
          }
        },
        crosshair: {
          mode: _lightweightCharts.default.CrosshairMode.Normal,
          color: 'white',
          labelVisible: true,
          vertLine: {
            labelBackgroundColor: '#e5001e'
          },
          horzLine: {
            labelBackgroundColor: '#e5001e'
          }
        },
        rightPriceScale: {
          visible: true,
          borderColor: 'rgba(197, 203, 206, 0.15)',
          scaleMargins: {
            bottom: 0.25,
            top: 0.05
          }
        },
        timeScale: {
          rightOffset: 0,
          timeVisible: true,
          borderColor: 'rgba(197, 203, 206, 0.15)',
          barSpacing: 8
        }
      });
      this.candleSeries = this.chart.addCandlestickSeries({
        priceFormat: {
          type: 'price',
          precision: this.precision,
          minMove: 1 / Math.pow(10, this.precision)
        }
      });

      if (tokenA && tokenB) {
        let data = await this.getBarsData(tokenA, tokenB, timeInterval);

        if (data[0]) {
          this.candleSeries.setData(data);
        }

        if (data.length == 0) {
          this.nodata = true;
        } else if (data.length) {
          this.candleSeries.applyOptions({
            priceFormat: {
              precision: this.precision,
              minMove: 1 / Math.pow(10, this.precision)
            }
          });
          this.chart.timeScale().resetTimeScale();
          this.chart.priceScale().applyOptions({
            autoScale: true
          });
          setTimeout(() => {
            this.chart.resize(this.lastWidth - 1, this.height);
            this.chart.resize(this.lastWidth, this.height);
          }, 100);
          this.nodata = false;
        }
      }
    }

    async updateCandlesChart() {
      const tokenA = this.args.tokenA;
      const tokenB = this.args.tokenB;
      const timeInterval = this.args.time;
      this.lastUpdate = new Date().getTime();
      this.precision = this.args.precision || 8;
      let data = await this.getBarsData(tokenA, tokenB, timeInterval);

      if (data[0]) {
        this.candleSeries.applyOptions({
          priceFormat: {
            precision: this.precision,
            minMove: 1 / Math.pow(10, this.precision)
          }
        });
      }

      this.candleSeries.setData(data);

      if (data.length == 0) {
        this.nodata = true;
      } else {
        this.chart.timeScale().resetTimeScale();
        this.chart.priceScale().applyOptions({
          autoScale: true
        });
        setTimeout(() => {
          this.chart.resize(this.lastWidth - 1, this.height);
          this.chart.resize(this.lastWidth, this.height);
        }, 100);
        this.nodata = false;
      }
    }

    async createLineChart(element) {
      const tokenA = this.args.tokenA;
      const tokenB = this.args.tokenB;
      const timeInterval = this.args.time;
      this.precision = this.args.precision || 8;
      this.lastUpdate = new Date().getTime();
      let bottomScale = this.args.tokenA == "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t" && this.args.tokenB == "TNUC9Qb1rRpS5CbWLmNMxXBjyFoydXjWFR" ? 0.25 : 0.05;
      this.chart = _lightweightCharts.default.createChart(element, {
        width: this.width,
        height: this.height,
        layout: {
          backgroundColor: 'transparent',
          textColor: 'rgba(255, 255, 255, 0.9)',
          fontSize: 8
        },
        grid: {
          vertLines: {
            color: 'rgba(197, 203, 206, 0.05)'
          },
          horzLines: {
            color: 'rgba(197, 203, 206, 0.05)'
          }
        },
        crosshair: {
          mode: _lightweightCharts.default.CrosshairMode.Normal,
          color: 'white',
          labelVisible: true,
          vertLine: {
            labelBackgroundColor: '#e5001e'
          },
          horzLine: {
            labelBackgroundColor: '#e5001e'
          }
        },
        rightPriceScale: {
          visible: true,
          borderColor: 'rgba(197, 203, 206, 0.15)',
          scaleMargins: {
            bottom: bottomScale,
            top: 0.05
          }
        },
        timeScale: {
          rightOffset: 0,
          timeVisible: true,
          borderColor: 'rgba(197, 203, 206, 0.15)',
          barSpacing: 4
        }
      });
      this.lineSeries = this.chart.addLineSeries({
        lineWidth: 1,
        color: 'white',
        priceFormat: {
          type: 'price',
          precision: this.precision,
          minMove: 1 / Math.pow(10, this.precision)
        }
      });

      if (tokenA && tokenB) {
        let data = await this.getLineData(tokenA, tokenB, timeInterval);

        if (data[0]) {
          this.lineSeries.setData(data);
        }

        if (data.length == 0) {
          this.nodata = true;
        } else if (data.length) {
          this.lineSeries.applyOptions({
            priceFormat: {
              precision: this.precision,
              minMove: 1 / Math.pow(10, this.precision)
            }
          });
          this.chart.timeScale().resetTimeScale();
          this.chart.priceScale().applyOptions({
            autoScale: true
          });
          setTimeout(() => {
            this.chart.resize(this.lastWidth - 1, this.height);
            this.chart.resize(this.lastWidth, this.height);
          }, 100);
          this.nodata = false;
        }
      }
    }

    async updateLineChart() {
      const tokenA = this.args.tokenA;
      const tokenB = this.args.tokenB;
      const timeInterval = this.args.time;
      this.precision = this.args.precision || 8;
      this.lastUpdate = new Date().getTime();
      let data = await this.getLineData(tokenA, tokenB, timeInterval);

      if (data[0]) {
        this.lineSeries.applyOptions({
          priceFormat: {
            precision: this.precision,
            minMove: 1 / Math.pow(10, this.precision)
          }
        });
      }

      this.lineSeries.setData(data);

      if (data.length == 0) {
        this.nodata = true;
      } else {
        this.chart.timeScale().resetTimeScale();
        this.chart.priceScale().applyOptions({
          autoScale: true
        });
        setTimeout(() => {
          this.chart.resize(this.lastWidth - 1, this.height);
          this.chart.resize(this.lastWidth, this.height);
        }, 100);
        this.nodata = false;
      }
    }

    getBarsData(tokenA, tokenB, timeInterval) {
      this.loading = true;
      let key = 'chart_' + timeInterval + '_candles_' + tokenA + '_' + tokenB;
      let data = this.lscache.get(key);

      if (data) {
        if (data.length > 0 && data[data.length - 1]) {
          let priceNow = Number(data[data.length - 1].c).toLocaleString('en-US', {
            maximumSignificantDigits: 1,
            useGrouping: false
          });
          let split = priceNow.split('.');
          let numbers = split.length == 1 ? 1 : split[1].length;
          numbers = numbers + 3;

          if (numbers > 9) {
            numbers = 9;
          }

          this.precision = numbers;
        }

        let res = data.map(item => {
          return {
            open: Number(item.o).toFixed(this.precision),
            high: Number(item.h).toFixed(this.precision),
            low: Number(item.l).toFixed(this.precision),
            close: Number(item.c).toFixed(this.precision),
            time: Number(item.dt) / 1000
          };
        });
        this.data = res;

        if (this.currentPrice && this.data && this.data.length > 0) {
          if (new Date().getTime() >= this.data[this.data.length - 1].time * 1000 + 3600000 * 4) {
            let candle = {
              close: this.currentPrice.toFixed(this.precision),
              time: this.data[this.data.length - 1].time + 3600 * 4,
              open: this.currentPrice.toFixed(this.precision),
              high: this.currentPrice.toFixed(this.precision),
              low: this.currentPrice.toFixed(this.precision)
            };
            this.data.push(candle);
          } else {
            this.data[this.data.length - 1].close = this.currentPrice.toFixed(this.precision);
          }
        }

        this.loading = false;
        return res;
      }

      let tA, tB;

      if (this.App.NETWORK != 'TRON') {
        tA = tokenA.toLowerCase();
        tB = tokenB.toLowerCase();
      } else {
        tA = tokenA;
        tB = tokenB;
      }

      return fetch(this.App.APIURL + '/v1/history/' + this.App.NETWORK.toLowerCase() + '/candles/' + timeInterval + '/' + tA + '/' + tB, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }).then(r => r.json()).then(data => {
        if (tokenA != this.args.tokenA || tokenB != this.args.tokenB) {
          this.loading = false;
          return false;
        }

        if (data.length > 0 && data[data.length - 1]) {
          let priceNow = Number(data[data.length - 1].c).toLocaleString('en-US', {
            maximumSignificantDigits: 1,
            useGrouping: false
          });
          let split = priceNow.split('.');
          let numbers = split.length == 1 ? 1 : split[1].length;
          numbers = numbers + 3;

          if (numbers > 9) {
            numbers = 9;
          }

          this.precision = numbers;
        }

        let res = data.map(item => {
          return {
            open: Number(item.o).toFixed(this.precision),
            high: Number(item.h).toFixed(this.precision),
            low: Number(item.l).toFixed(this.precision),
            close: Number(item.c).toFixed(this.precision),
            time: Number(item.dt) / 1000
          };
        });
        this.data = res;

        if (this.currentPrice && this.data && this.data.length > 0) {
          if (new Date().getTime() >= this.data[this.data.length - 1].time * 1000 + 3600000 * 4) {
            let candle = {
              close: this.currentPrice.toFixed(this.precision),
              time: this.data[this.data.length - 1].time + 3600 * 4,
              open: this.currentPrice.toFixed(this.precision),
              high: this.currentPrice.toFixed(this.precision),
              low: this.currentPrice.toFixed(this.precision)
            };
            this.data.push(candle);
          } else {
            this.data[this.data.length - 1].close = this.currentPrice.toFixed(this.precision);
          }
        }

        this.loading = false;

        if (data[0]) {
          this.lscache.set(key, data, 10);
        }

        return res;
      }).catch(e => {
        console.log(e);
        this.loading = false;
      });
    }

    getLineData(tokenA, tokenB, timeInterval) {
      this.loading = true;
      let key = 'chart_' + timeInterval + '_line_' + tokenA + '_' + tokenB;
      let data = this.lscache.get(key);

      if (data) {
        if (data.length > 0 && data[data.length - 1]) {
          let priceNow = Number(data[data.length - 1].value).toLocaleString('en-US', {
            maximumSignificantDigits: 1,
            useGrouping: false
          });
          let split = priceNow.split('.');
          let numbers = split.length == 1 ? 1 : split[1].length;
          numbers = numbers + 3;

          if (numbers > 9) {
            numbers = 9;
          }

          this.precision = numbers;
        }

        let res = data.map(item => {
          return {
            value: Number(item.value).toFixed(this.precision),
            time: Number(item.dt) / 1000
          };
        });
        this.data = res;

        if (this.currentPrice && this.data && this.data.length > 0) {
          if (new Date().getTime() >= this.data[this.data.length - 1].time * 1000 + 3600000 * 4) {
            let line = {
              value: this.currentPrice.toFixed(this.precision),
              time: this.data[this.data.length - 1].time + 3600 * 4
            };
            this.data.push(line);
          } else {
            this.data[this.data.length - 1].value = this.currentPrice.toFixed(this.precision);
          }
        }

        this.loading = false;
        return res;
      }

      let tA, tB;

      if (this.App.NETWORK != 'TRON') {
        tA = tokenA.toLowerCase();
        tB = tokenB.toLowerCase();
      } else {
        tA = tokenA;
        tB = tokenB;
      }

      return fetch(this.App.APIURL + '/v1/history/' + this.App.NETWORK.toLowerCase() + '/line/' + timeInterval + '/' + tA + '/' + tB, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }).then(r => r.json()).then(data => {
        if (tokenA != this.args.tokenA || tokenB != this.args.tokenB) {
          this.loading = false;
          return false;
        }

        if (data.length > 0 && data[data.length - 1]) {
          let priceNow = Number(data[data.length - 1].value).toLocaleString('en-US', {
            maximumSignificantDigits: 1,
            useGrouping: false
          });
          let split = priceNow.split('.');
          let numbers = split.length == 1 ? 1 : split[1].length;
          numbers = numbers + 3;

          if (numbers > 9) {
            numbers = 9;
          }

          this.precision = numbers;
        }

        let res = data.map(item => {
          return {
            value: Number(item.value).toFixed(this.precision),
            time: Number(item.dt) / 1000
          };
        });
        this.data = res;

        if (this.currentPrice && this.data && this.data.length > 0) {
          if (new Date().getTime() >= this.data[this.data.length - 1].time * 1000 + 3600000 * 4) {
            let line = {
              value: this.currentPrice.toFixed(this.precision),
              time: this.data[this.data.length - 1].time + 3600 * 4
            };
            this.data.push(line);
          } else {
            this.data[this.data.length - 1].value = this.currentPrice.toFixed(this.precision);
          }
        }

        this.loading = false;

        if (data[0]) {
          this.lscache.set(key, data, 10);
        }

        return res;
      }).catch(e => {
        console.log(e);
        this.loading = false;
      });
    }

    didInsert(element) {
      const type = this.args.type;
      this.chartElement = element;
      this.width = this.args.width || 470;
      this.height = this.args.height || 320;
      this.lastWidth = this.width;

      if (this.args.updatePriceListener) {
        this.args.updatePriceListener(this.updatePrice);
      }

      if (type == 'line') {
        this.createLineChart(element);
        this.setSafeInterval(() => {
          if (!document.hidden) {
            this.updateLineChart(element);
          }
        }, 610000);
      } else {
        this.createCandlesChart(element);
        this.setSafeInterval(() => {
          if (!document.hidden) {
            this.updateCandlesChart(element);
            console.log('updateCandlesChart');
          }
        }, 610000);
      }

      this.setSafeInterval(() => {
        let width = element.offsetWidth;

        if (width != this.lastWidth) {
          this.chart.resize(width, this.height);
          this.lastWidth = width;
        }
      }, 200);
    }

    updatePrice(price) {
      if (!isNaN(price)) {
        if (this.data && this.data.length > 0) {
          let time;
          let newCandle = false;

          if (new Date().getTime() >= this.data[this.data.length - 1].time * 1000 + 3600000 * 4) {
            time = this.data[this.data.length - 1].time + 3600 * 4;
            newCandle = true;
          } else {
            time = this.data[this.data.length - 1].time;
          }

          if (this.lineSeries) {
            let line = {
              value: price.toFixed(this.precision),
              time: time
            };
            this.lineSeries.update(line);

            if (newCandle) {
              this.data.push(line);
            }
          } else if (this.candleSeries) {
            if (newCandle) {
              let candle = {
                close: price.toFixed(this.precision),
                time: time,
                open: price.toFixed(this.precision),
                high: price.toFixed(this.precision),
                low: price.toFixed(this.precision)
              };
              this.data.push(candle);
              this.candleSeries.update(candle);
            } else {
              this.candleSeries.update({
                close: price.toFixed(this.precision),
                time: time,
                open: this.data[this.data.length - 1].open,
                high: Math.max(this.data[this.data.length - 1].high, price.toFixed(this.precision)),
                low: Math.min(this.data[this.data.length - 1].low, price.toFixed(this.precision))
              });
            }
          }
        }

        this.currentPrice = price;
      }
    }

    didUpdateAttributes(element) {
      this.afterRender(() => {
        this.data = [];
        const type = this.args.type;
        this.chartElement = element;
        this.width = this.args.width || 470;
        this.height = this.args.height || 320;
        this.currentPrice = null;
        let bottomScale = this.args.tokenA == "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t" && this.args.tokenB == "TNUC9Qb1rRpS5CbWLmNMxXBjyFoydXjWFR" ? 0.25 : 0.05;

        if (this.lineSeries) {
          this.lineSeries.setData([]);
          this.lineSeries.applyOptions({
            priceFormat: {
              precision: 1,
              minMove: 1 / Math.pow(10, 1)
            }
          });
        } else if (this.candleSeries) {
          this.candleSeries.setData([]);
          this.candleSeries.applyOptions({
            priceFormat: {
              precision: 1,
              minMove: 1 / Math.pow(10, 1)
            }
          });
        }

        this.chart.applyOptions({
          rightPriceScale: {
            scaleMargins: {
              bottom: bottomScale,
              top: 0.05
            }
          }
        });

        if (type == 'line') {
          this.updateLineChart(element);
        } else {
          this.updateCandlesChart(element);
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "lscache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "nodata", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatePrice", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updatePrice"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didUpdateAttributes", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didUpdateAttributes"), _class.prototype)), _class);
  _exports.default = ChartComponent;
});