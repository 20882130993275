define("justmoney-swap/providers/wallets/tron-link", ["exports", "justmoney-swap/providers/base-provider", "justmoney-swap/utils/tools"], function (_exports, _baseProvider, _tools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class TronLinkProvider extends _baseProvider.default {
    constructor(network, owner) {
      super(network, owner);

      _defineProperty(this, "_100_TRX", 100000000);

      _defineProperty(this, "cachedContracts", []);

      _defineProperty(this, "tempbalance", null);

      _defineProperty(this, "lastBalanceCheck", 0);

      _defineProperty(this, "lastBalanceCheckForToken", {});

      _defineProperty(this, "cachedTokenBalance", {});

      _defineProperty(this, "balanceCheckPromiseToken", {});

      _defineProperty(this, "apiKeys", ['5cd40795-a262-4d82-8b80-a3284fc7f56b', '9865a6aa-6276-4e93-af27-00a0fb7eafda', '3d3546d3-704b-4eb6-b44d-3b0eeebed5de', '590fdac7-8a8b-4e1c-ba75-6e3ad0be34eb', 'aad36c24-eff1-4ccb-a0bc-890631d5367b', '681e7e52-9598-4437-9373-b6202042ecab']);

      setInterval(() => {
        if (window.App.isVisible && this.connected) {
          this.update();
        }
      }, 20000);
      setInterval(() => {
        if (window.App.isVisible && this.connected) {
          this.updateAddress();
        }
      }, 5000);
      window.addEventListener('message', e => {
        if (e.data.message && e.data.message.action == 'setAccount') {
          if (e.data.message.data.address != this.address) {
            window.location.reload();
          }
        }

        if (e.data.message && e.data.message.action == 'setNode') {
          window.location.reload();
        }
      });
    }

    async connect() {
      if (!window.tronWeb) return Promise.reject('No wallet found');
      const prom = super.connect();
      window.amplitude.getInstance().logEvent('WALLET_CONNECT', {
        host: this.App.widgetOptions ? this.App.widgetOptions.host : window.location.host,
        widget: false,
        wallet: 'TronLink',
        network: this.App.NETWORK
      });
      return prom;
    }

    getRandomApiKey() {
      return this.apiKeys[Math.floor(Math.random() * this.apiKeys.length)];
    }

    async resolve() {
      return new Promise(resolve => {
        if (this.tronWeb) return resolve(this.tronWeb);

        async function run() {
          if (window.tronWeb) {
            if (window.tronLink) {
              try {
                window.tronLink.request({
                  method: 'tron_requestAccounts'
                }).then(a => {
                  if (a && a.code == 4001) {
                    console.log('declined connection');
                  } else if (a.code == 200) {
                    this.tronWeb = window.tronWeb;

                    if (window.tronWeb.setHeader && window.tronWeb.fullNode.host.indexOf('nileex.io') == 0) {
                      window.tronWeb.setHeader({
                        'TRON-PRO-API-KEY': this.getRandomApiKey()
                      });
                    }

                    return resolve(this.tronWeb);
                  }
                }).catch(err => {
                  console.log('err', err);

                  if (window.tronWeb) {
                    this.tronWeb = window.tronWeb;

                    if (window.tronWeb.setHeader && window.tronWeb.fullNode.host.indexOf('nileex.io') == 0) {
                      window.tronWeb.setHeader({
                        'TRON-PRO-API-KEY': this.getRandomApiKey()
                      });
                    }

                    return resolve(this.tronWeb);
                  }
                });
              } catch (err) {
                if (window.tronWeb) {
                  this.tronWeb = window.tronWeb;

                  if (window.tronWeb.setHeader && window.tronWeb.fullNode.host.indexOf('nileex.io') == 0) {
                    window.tronWeb.setHeader({
                      'TRON-PRO-API-KEY': this.getRandomApiKey()
                    });
                  }

                  return resolve(this.tronWeb);
                }
              }
            } else if (window.tronWeb.defaultAddress && window.tronWeb.defaultAddress.base58) {
              return resolve(window.tronWeb);
            }
          } else {
            setTimeout(run, this.POLLING_INTERVAL);
          }
        }

        run.call(this);
      });
    }

    async updateAddress() {
      let newAddress = await this.getAddress();

      if (this.addressOnLastUpdate && newAddress != this.addressOnLastUpdate) {
        window.location.reload();
      }

      this.addressOnLastUpdate = newAddress;
      this.name = await this.getName();
    }

    async update() {
      if (this.connected) {
        super.update();
        this.balance = await this.getBalanceForBase();
      }
    }

    async getAddress() {
      const tronWeb = await this.resolve();
      this.address = tronWeb.defaultAddress.base58;
      return this.address;
    }

    async getName() {
      const tronWeb = await this.resolve();
      return tronWeb.defaultAddress.name;
    }

    async getBalance(token) {
      if (this.isBaseToken(token)) {
        return this.getBalanceForBase();
      } else {
        return this.getBalanceForToken(token);
      }
    }

    async getBalanceForBase() {
      if (new Date().getTime() > this.lastBalanceCheck + 5000 || this.tempbalance === null) {
        this.lastBalanceCheck = new Date().getTime();
        this.tempbalance = 0;
        this.balanceCheckPromise = new Promise(async resolve => {
          const tronWeb = await this.resolve();
          this.tempbalance = await tronWeb.trx.getUnconfirmedBalance();
          resolve(this.tempbalance / Math.pow(10, this.App.JM.NETWORKS[this.App.NETWORK].TOKENS.TRX.decimals));
        });
        return await this.balanceCheckPromise;
      } else {
        return await this.balanceCheckPromise;
      }
    }

    async getBalanceForToken(token) {
      if (new Date().getTime() > this.lastBalanceCheckForToken[token.address] + 5000 || this.cachedTokenBalance[token.address] === undefined) {
        this.lastBalanceCheckForToken[token.address] = new Date().getTime();
        this.cachedTokenBalance[token.address] = 0;
        this.balanceCheckPromiseToken[token.address] = new Promise(async resolve => {
          const tronWeb = await this.resolve();
          this.cachedContracts[token.address] = this.cachedContracts[token.address] || (await tronWeb.contract().at(token.address));
          const contract = this.cachedContracts[token.address];
          const balance = await contract.balanceOf(await this.getAddress()).call();
          resolve(this.fromBigNumber(balance, token));
        });
        return await this.balanceCheckPromiseToken[token.address];
      } else {
        return this.balanceCheckPromiseToken[token.address];
      }
    }

    async getResources() {
      let energy = 0;
      let bandwidth = 0;
      const tronWeb = await this.resolve();
      bandwidth = await tronWeb.trx.getBandwidth(await this.getAddress()).then(meta => {
        return Number(meta);
      });
      energy = await tronWeb.trx.getAccountResources(await this.getAddress()).then(res => {
        if (typeof res.EnergyUsed === 'undefined' && res.EnergyLimit) {
          // if energy is full this is undefined...
          return res.EnergyLimit;
        } else if (res.EnergyLimit) {
          return res.EnergyLimit - res.EnergyUsed;
        } else {
          return 0;
        }
      });
      return {
        energy,
        bandwidth
      };
    }

    fromBigNumber(amount, token) {
      return (0, _tools.fromWei)(amount, token.decimals);
    }

    async getAllowance(token, lp, useNormalRouter) {
      let router = this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.ROUTER_ADDRESS;

      if (lp) {
        router = useNormalRouter ? this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.ROUTER_ADDRESS : this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.LIQUIDITY_ROUTER;
      }

      const tronWeb = await this.resolve();
      this.cachedContracts[token.address] = this.cachedContracts[token.address] || (await tronWeb.contract().at(token.address));
      const contract = this.cachedContracts[token.address];
      const allowance = await contract.allowance(await this.getAddress(), router).call();
      return allowance;
    }

    async getAllowanceForAddress(token, address) {
      const tronWeb = await this.resolve();
      this.cachedContracts[token.address] = this.cachedContracts[token.address] || (await tronWeb.contract().at(token.address));
      const contract = this.cachedContracts[token.address];
      const allowance = await contract.allowance(await this.getAddress(), address).call();
      return allowance;
    }

    async getAllowanceWithABI(token, lp, useNormalRouter) {
      let router = this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.ROUTER_ADDRESS;

      if (lp) {
        router = useNormalRouter ? this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.ROUTER_ADDRESS : this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.LIQUIDITY_ROUTER;
      }

      const contract = await tronWeb.contract([{
        constant: true,
        inputs: [{
          name: '_owner',
          type: 'address'
        }, {
          name: '_spender',
          type: 'address'
        }],
        name: 'allowance',
        outputs: [{
          name: 'remaining',
          type: 'uint256'
        }],
        type: 'function',
        payable: false,
        stateMutability: 'view'
      }], token.address);
      const allowance = await contract.allowance(await this.getAddress(), router).call({
        _isConstant: true
      });
      return allowance;
    }

    async approve(token, address) {
      const tronWeb = await this.resolve();
      this.cachedContracts[token.address] = this.cachedContracts[token.address] || (await tronWeb.contract().at(token.address));
      const contract = this.cachedContracts[token.address];
      return await contract.approve(address || this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.ROUTER_ADDRESS, this.MAX_APPROVAL_VALUE).send();
    }

    async approveSigned(address, useNormalRouter) {
      const parameters = [{
        type: TronLinkProvider.PARAMETERS.ADDRESS,
        value: useNormalRouter ? this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.ROUTER_ADDRESS : this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.LIQUIDITY_ROUTER
      }, {
        type: TronLinkProvider.PARAMETERS.UINT256,
        value: this.MAX_APPROVAL_VALUE
      }];
      let transaction = this.send(address, 'approve(address,uint256)', {}, parameters, [TronLinkProvider.PARAMETERS.ADDRESS, TronLinkProvider.PARAMETERS.UINT256]);
      this.logging.log(transaction);
      return transaction;
    }

    async execute(address, method, options, parameters) {
      let paramsArr = parameters.map(val => {
        return val.value;
      });
      const tronWeb = await this.resolve();
      this.cachedContracts[address] = this.cachedContracts[address] || (await tronWeb.contract().at(address));
      const contract = this.cachedContracts[address];
      let ret = await contract.methods[method].apply(this, paramsArr).call();
      return ret;
    }

    async send(address, method, options, parameters) {
      const tronLink = await this.resolve();
      let params = {};

      if (options && options.value) {
        params.callValue = options.value;
      }

      params.feeLimit = options.feeLimit || this._100_TRX;
      params.shouldPollResponse = true;
      const transaction = await tronLink.transactionBuilder.triggerSmartContract(address, method, params, parameters);
      const signedTransaction = await tronLink.trx.sign(transaction.transaction).catch(e => this.logging.log(e));
      const result = await tronLink.trx.sendRawTransaction(signedTransaction);
      return result;
    }

  }

  _exports.default = TronLinkProvider;

  _defineProperty(TronLinkProvider, "PARAMETERS", {
    ADDRESS: 'address',
    ADDRESS_ARRAY: 'address[]',
    UINT8: 'uint8',
    UINT256: 'uint256',
    BYTES: 'bytes',
    BYTES32: 'bytes32',
    UINT256_ARRAY: 'uint256[]',
    BYTES32_ARRAY: 'bytes32[]',
    BOOL: 'bool',
    STRING: 'string',
    STRING_ARRAY: 'string[]'
  });
});