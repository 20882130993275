define("justmoney-swap/helpers/destTokenAddress", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.destTokenAddress = destTokenAddress;

  function destTokenAddress(params) {
    if (!params[0] || !params[1] || !params[2] || !params[0][params[1].symbol]) return '';
    let address = params[0][params[1].symbol].address[params[2].code];

    if (!address) {
      return '';
    }

    return address.substr(0, 11) + '...' + address.substr(address.length - 9, 97);
  }

  var _default = (0, _helper.helper)(destTokenAddress);

  _exports.default = _default;
});