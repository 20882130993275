define("justmoney-swap/helpers/equals", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.equals = equals;

  function equals(params) {
    if (params[0] == params[1]) {
      return true;
    }

    return false;
  }

  var _default = (0, _helper.helper)(equals);

  _exports.default = _default;
});