define("justmoney-swap/helpers/toWei", ["exports", "@ember/component/helper", "justmoney-swap/utils/tools"], function (_exports, _helper, _tools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.wei = wei;

  function wei(params) {
    if (!params[0] && !params !== 0) return '';
    return (0, _tools.toWei)(params[0], params[1]);
  }

  var _default = (0, _helper.helper)(wei);

  _exports.default = _default;
});