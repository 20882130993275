define("justmoney-swap/routes/charts", ["exports", "justmoney-swap/core/base-route", "@ember/service", "justmoney-swap/utils/tools", "@ember/object"], function (_exports, _baseRoute, _service, _tools, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChartsRoute = (_class = class ChartsRoute extends _baseRoute.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "justMoneyApiUrl", this.App.APIURL + '/v1/info');

      _initializerDefineProperty(this, "tokenPair", _descriptor, this);

      _defineProperty(this, "queryParams", {
        n: {
          refreshModel: true
        },
        symbolA: {
          refreshModel: true
        },
        symbolB: {
          refreshModel: true
        }
      });
    }

    refreshModel() {
      this.refresh();
    }

    fetchApiData() {
      return fetch(this.justMoneyApiUrl).then(response => response.json()).then(data => {
        return data;
      });
    }

    async beforeModel() {}

    async model(params) {
      this.App.setLoading(true);

      if (!params.n) {
        params.n = this.App.NETWORK || 'TRON';
      }

      if (params.n != this.App.NETWORK) {
        this.App.NETWORK = params.n;
      }

      this.provider.onNetworkChangePassbacks = [];
      this.provider.getProvider(this.App.NETWORK);
      this.tokens = await this.tokenPair.getTokens();
      this.pairs = (await this.tokenPair.getPairs()).filter(pool => {
        return !pool.hideFromCharts && (this.App.staging || !pool.hidden);
      });
      return {
        apiData: this.fetchApiData(),
        pairs: this.pairs,
        tokens: this.tokens,
        symbolA: params.symbolA || 'TRX',
        symbolB: params.symbolB || 'JM'
      };
    }

    setupController(controller, model, transition) {
      super.setupController(controller, model, transition);
      controller.pairs = model.pairs;
      controller.tokens = model.tokens;
      let pair = model.pairs.find(item => {
        return item.symbolA == model.symbolA && item.symbolB == model.symbolB;
      });

      if (!pair) {
        pair = model.pairs.find(item => {
          return item.symbolB == model.symbolA && item.symbolA == model.symbolB;
        });
      }

      if (!pair) {
        pair = (0, _tools.findPairBySymbols)(this.App.DEFAULT_TOKENS[this.App.NETWORK][0], this.App.DEFAULT_TOKENS[this.App.NETWORK][1]);
      }

      controller.symbolA = pair.symbolA;
      controller.symbolB = pair.symbolB;
      controller.selectedPair = pair;
      controller.fromToken = this.getTokenBySymbol(pair.invert ? pair.symbolA : pair.symbolB || 'TRX');
      controller.toToken = this.getTokenBySymbol(pair.invert ? pair.symbolB : pair.symbolA || 'JM');
      Promise.all([model.apiData]).then(data => {
        this.App.setLoading(false);
        controller.apiData = data[0];
      });
    }

    getTokenBySymbol(symbol) {
      return this.tokens.find(token => token.symbol === symbol);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "tokenPair", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "refreshModel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "refreshModel"), _class.prototype)), _class);
  _exports.default = ChartsRoute;
});