define("justmoney-swap/services/token-pair", ["exports", "@ember/service", "@glimmer/tracking", "justmoney-swap/core/base-service", "justmoney-swap/utils/tools"], function (_exports, _service, _tracking, _baseService, _tools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TokenPairService = (_class = class TokenPairService extends _baseService.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "provider", _descriptor, this);

      _initializerDefineProperty(this, "lscache", _descriptor2, this);

      _initializerDefineProperty(this, "CACHED_TOKENS", _descriptor3, this);

      _initializerDefineProperty(this, "CACHED_TOKEN_PATHS", _descriptor4, this);

      _initializerDefineProperty(this, "App", _descriptor5, this);

      _defineProperty(this, "CONTRACT_METHODS", {
        GET_TOKENS: 'getAllRecords()',
        GET_PAIRS: 'getAllRecords()'
      });

      _defineProperty(this, "key", (from, to) => `${from.address}-${to.address}`);

      _defineProperty(this, "keyWithNetwork", (from, to, network) => `${network}-${from.address}-${to.address}`);

      _defineProperty(this, "DIRECT_HOP_LENGTH", 2);

      _defineProperty(this, "MAX_HOP_LENGTH", this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.MAX_HOP_LENGTH);
    }

    reset() {
      this.CACHED_TOKENS = {};
      this.CACHED_TOKEN_PATHS = {};
    }

    async getTokens(n) {
      let network = n || this.App.NETWORK;

      if (this.CACHED_TOKENS[network] && !this.isEmpty(this.CACHED_TOKENS[network])) {
        return this.CACHED_TOKENS[network];
      }

      if (!this.App.JM.NETWORKS[network].TOKENS) {
        let records = this.lscache.get('TOKENLIST_' + network);

        if (records) {
          this.App.JM.NETWORKS[network].TOKENS = records;
          let obj = {};

          for (let item in records) {
            if (records.hasOwnProperty(item)) {
              try {
                obj[records[item].address.toUpperCase()] = records[item];
              } catch (e) {
                console.log(e);
              }
            }
          }

          this.App.JM.NETWORKS[network].TOKENS_BY_ADDRESS = obj;
        } else {
          await this.getTokensFromContractStorage(n != this.App.NETWORK ? n : null).then(result => {
            let obj = {},
                obj2 = {};
            let ok = false;
            result.forEach(item => {
              try {
                let parsed = JSON.parse(item);
                obj[parsed.s] = (0, _tools.mapTokenData)(parsed, network);
                obj2[parsed.a.toUpperCase()] = obj[parsed.s];
                ok = true;
              } catch (e) {
                console.log(e);
              }
            });

            if (ok) {
              this.lscache.set('TOKENLIST_' + network, obj, 5);
            }

            this.App.JM.NETWORKS[network].TOKENS = obj;
            this.App.JM.NETWORKS[network].TOKENS_BY_ADDRESS = obj2;
          }).catch(err => {
            console.error(err);
          });
        }
      } else {
        let obj = {};
        let tokens = this.App.JM.NETWORKS[network].TOKENS;

        for (let item in tokens) {
          if (tokens.hasOwnProperty(item)) {
            try {
              obj[tokens[item].address.toUpperCase()] = tokens[item];
            } catch (e) {
              console.log(e);
            }
          }
        }

        this.App.JM.NETWORKS[network].TOKENS_BY_ADDRESS = obj;
      }

      return this.CACHED_TOKENS[network] = Object.keys(Object.values(this.App.JM.NETWORKS[network].TOKENS)).map(token => this.sortAlphabetically(Object.values(this.App.JM.NETWORKS[network].TOKENS))[token]);
    }

    getTokenTotalSupply(token) {
      let key = 'totalSupply_' + this.App.NETWORK + '_' + token.address;
      let supply = this.lscache.get(key);

      if (supply) {
        return Promise.resolve(supply);
      }

      return this.provider.getProvider(this.App.NETWORK).api.execute(token.address, 'totalSupply()', undefined, []).then(supply => {
        let s = (0, _tools.fromWei)(supply, token.decimals);
        this.lscache.set(key, s.toString(), 1440);
        return s.toString();
      });
    }

    async getPairs(n) {
      let network = n || this.App.NETWORK;

      if (this.App.JM.NETWORKS[network].POOLS) {
        let obj = {};
        let pools = this.App.JM.NETWORKS[network].POOLS;

        for (let item in pools) {
          if (pools.hasOwnProperty(item)) {
            obj[pools[item].address.toUpperCase()] = pools[item];
          }
        }

        this.App.JM.NETWORKS[network].POOLS_BY_ADDRESS = obj;
        return this.App.JM.NETWORKS[network].POOLS;
      }

      let records = this.lscache.get('PAIRLIST_' + network);

      if (records) {
        this.App.JM.NETWORKS[network].POOLS = records;
        let obj = {};

        for (let i = 0; i < records.length; i++) {
          obj[records[i].address.toUpperCase()] = records[i];
        }

        this.App.JM.NETWORKS[network].POOLS_BY_ADDRESS = obj;
        return records;
      }

      let pairs = await this.getPairsFromContractStorage(n != this.App.NETWORK ? n : null);
      let newArray = [],
          obj = {};
      pairs.forEach(item => {
        try {
          let parsed = JSON.parse(item);
          obj[parsed.ad.toUpperCase()] = (0, _tools.mapPairData)(parsed);
          newArray.push(obj[parsed.ad.toUpperCase()]);
        } catch (e) {
          console.log(e);
        }
      });

      if (newArray.length > 0) {
        this.lscache.set('PAIRLIST_' + network, newArray, 5);
      }

      this.App.JM.NETWORKS[network].POOLS = newArray;
      this.App.JM.NETWORKS[network].POOLS_BY_ADDRESS = obj;
      return this.App.JM.NETWORKS[network].POOLS;
    }

    getTokensFromContractStorage(network) {
      if (network) {
        return this.provider.getSecondaryProvider(network).api.execute(this.App.JM.NETWORKS[network].EXCHANGE.TOKENS_STORAGE, this.CONTRACT_METHODS.GET_TOKENS, undefined, []);
      } else {
        return this.currentProvider().api.execute(this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.TOKENS_STORAGE, this.CONTRACT_METHODS.GET_TOKENS, undefined, []);
      }
    }

    getPairsFromContractStorage(network) {
      if (network) {
        return this.provider.getSecondaryProvider(network).api.execute(this.App.JM.NETWORKS[network].EXCHANGE.PAIRS_STORAGE, this.CONTRACT_METHODS.GET_PAIRS, undefined, []);
      } else {
        return this.currentProvider().api.execute(this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.PAIRS_STORAGE, this.CONTRACT_METHODS.GET_PAIRS, undefined, []);
      }
    }

    async getPairwiseTokenPathsFromTo(fromToken, toToken, network) {
      if (!network) network = this.App.NETWORK;
      const key = this.keyWithNetwork(fromToken, toToken, network);

      if (this.CACHED_TOKEN_PATHS[key]) {
        return this.CACHED_TOKEN_PATHS[key];
      }

      return this.CACHED_TOKEN_PATHS[key] = this.getTokenPairsPathsFromTo(fromToken, toToken, await this.getTokens(network)).map(tokenPairPath => [tokenPairPath, this.pairwise(tokenPairPath)]);
    }

    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    }

    pairwise(tokenPairPath) {
      let path = [];

      for (let i = 0; i < tokenPairPath.length - 1; i++) {
        for (let j = 1; j < tokenPairPath.length; j++) {
          if (j - i === 1) {
            path.push([tokenPairPath[i], tokenPairPath[j]]);
          }
        }
      }

      return path;
    }

    sortAlphabetically(tokens) {
      return tokens.sort((token1, token2) => token1.symbol.localeCompare(token2.symbol));
    }

    getTokenPairsPathsFromTo(fromToken, toToken, tokens) {
      const tokenPairPaths = [...this.getAllTokenPairPathPermutations(tokens, this.DIRECT_HOP_LENGTH), ...this.getAllTokenPairPathPermutations(tokens, this.MAX_HOP_LENGTH)];
      return tokenPairPaths.filter(item => item[0].address.toUpperCase() == fromToken.address.toUpperCase() && item[item.length - 1].address.toUpperCase() == toToken.address.toUpperCase());
    }

    getAllTokenPairPathPermutations(value, maxHopLength) {
      let depth = Math.min(maxHopLength, value.length);
      let results = [];

      const permute = function () {
        let queue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

        if (queue.length === depth) {
          results.push(queue);
        } else {
          for (let ele of value) {
            permute(queue.concat(ele));
          }
        }
      };

      permute();
      return results.filter(item => new Set(item).size === item.length);
    }

    currentProvider() {
      return this.provider.getProvider(this.App.NETWORK);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "lscache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "CACHED_TOKENS", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "CACHED_TOKEN_PATHS", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "App", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return window.App;
    }
  })), _class);
  _exports.default = TokenPairService;
});