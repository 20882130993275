define("justmoney-swap/components/add-token-metamask/component", ["exports", "justmoney-swap/core/base-component", "@ember/object"], function (_exports, _baseComponent, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let AddTokenMetamaskComponent = (_class = class AddTokenMetamaskComponent extends _baseComponent.default {
    constructor() {
      super(...arguments);
    }

    didInsert() {
      if (this.args.token) {
        this.tokenAddress = this.args.token.address;
        this.tokenSymbol = this.args.token.symbol;
        this.tokenDecimals = this.args.token.decimals;
        this.tokenImage = this.args.token.image;
      }
    }

    didUpdateAttributes(element) {
      if (this.args.token) {
        this.tokenAddress = this.args.token.address;
        this.tokenSymbol = this.args.token.symbol;
        this.tokenDecimals = this.args.token.decimals;
        this.tokenImage = this.args.token.image;
      }
    }

    addTokenClicked() {
      this.addToken();
    }

    async addToken() {
      try {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        const wasAdded = await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            // Initially only supports ERC20, but eventually more!
            options: {
              address: this.tokenAddress,
              // The address that the token is at.
              symbol: this.tokenSymbol,
              // A ticker symbol or shorthand, up to 5 chars.
              decimals: this.tokenDecimals,
              // The number of decimals in the token
              image: this.tokenImage // A string url of the token logo

            }
          }
        });

        if (wasAdded) {} else {}
      } catch (error) {
        console.log(error);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didUpdateAttributes", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didUpdateAttributes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addTokenClicked", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addTokenClicked"), _class.prototype)), _class);
  _exports.default = AddTokenMetamaskComponent;
});