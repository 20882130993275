define("justmoney-swap/helpers/isArray", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isArray = isArray;

  function isArray(params) {
    if (Array.isArray(params[0])) {
      return true;
    }

    return false;
  }

  var _default = (0, _helper.helper)(isArray);

  _exports.default = _default;
});