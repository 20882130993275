define("justmoney-swap/routes/burn", ["exports", "justmoney-swap/core/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BurnRoute extends _baseRoute.default {
    model(params) {
      //this.App.setLoading(true);
      return {//burns: this.store.findAll('burn'),
      };
    }

    setupController(controller, model, transition) {
      super.setupController(controller, model, transition);
      /*model.burns
          .then((res) => {
              controller.set('burns', res);
              let totalBurns = 0, addedToPools = 0
              res.forEach((item) => {
                  if (item.type.toUpperCase() == 'BURN') {
                      totalBurns += item.amount;
                  } else if (item.type.toUpperCase() == 'ADD TO POOL') {
                      addedToPools += item.amount;
                  }
              });
              controller.set('totalBurns', totalBurns);
              controller.set('addedToPools', addedToPools);
              this.App.setLoading(false);
          })
          .catch((err) => {
              console.log(err);
              controller.burns = [];
              this.App.setLoading(false);
          });*/
    }

  }

  _exports.default = BurnRoute;
});