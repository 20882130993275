define("justmoney-swap/helpers/division", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.division = division;

  function division(params) {
    if (params[0] && params[1]) {
      return params[0] / params[1];
    }

    return false;
  }

  var _default = (0, _helper.helper)(division);

  _exports.default = _default;
});