define("justmoney-swap/components/history-component/component", ["exports", "@ember/object", "justmoney-swap/core/base-component", "@glimmer/tracking", "@ember/service", "justmoney-swap/utils/tools"], function (_exports, _object, _baseComponent, _tracking, _service, _tools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let HistoryComponent = (_class = class HistoryComponent extends _baseComponent.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "loading", _descriptor, this);

      _initializerDefineProperty(this, "lscache", _descriptor2, this);

      _initializerDefineProperty(this, "nodata", _descriptor3, this);

      _initializerDefineProperty(this, "data", _descriptor4, this);

      _initializerDefineProperty(this, "pair", _descriptor5, this);

      this.addListener('toggleHistory', () => {
        if (!this.isOpen) {
          this.updateHistory();
          this.containerElement.classList.add('show');
          this.containerElement.classList.remove('hide');
        } else {
          this.containerElement.classList.remove('show');
          this.containerElement.classList.add('hide');
        }

        this.isOpen = !this.isOpen;
      });
      this.setSafeInterval(() => {
        if (this.isOpen && this.App.isVisible && new Date().getTime() > this.lastUpdate + 60000) {
          this.updateHistory();
        }
      }, 300000);
      this.addListener('visibilitychange', async event => {
        if (this.isOpen && !document.hidden && new Date().getTime() > this.lastUpdate + 60000) {
          this.updateHistory();
        }
      });
    }

    didInsert(element) {
      this.containerElement = element;

      if (this.args.updateDataListener) {
        this.args.updateDataListener(this.updateHistory);
      }
    }

    async updateHistory() {
      let pair = (0, _tools.findPairBySymbols)(this.args.tokenA.symbol, this.args.tokenB.symbol);
      this.pair = pair;
      let sortedA, sortedB;

      if (this.App.NETWORK == 'TRON') {
        sortedA = (0, _tools.getTokenBySymbol)(pair.invert ? pair.symbolA : pair.symbolB);
        sortedB = (0, _tools.getTokenBySymbol)(pair.invert ? pair.symbolB : pair.symbolA);
      } else {
        sortedA = (0, _tools.getTokenBySymbol)(pair.symbolA);
        sortedB = (0, _tools.getTokenBySymbol)(pair.symbolB);
      }

      this.data = [];

      if (sortedB && sortedA.address && sortedB && sortedB.address) {
        this.loading = true;
        this.nodata = false;
        this.lastUpdate = new Date().getTime();
      } else {
        this.nodata = true;
        this.loading = false;
        return;
      }

      let key = 'history_trades_' + sortedA.address + '_' + sortedB.address;
      let data = this.lscache.get(key);

      if (data) {
        this.data = data;
        this.loading = false;
        return;
      }

      this.data = await fetch(`${this.App.APIURL}/v1/history/${this.App.NETWORK}/trades/${sortedA.address}/${sortedB.address}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(r => r.json()).then(data => {
        this.loading = false;
        return data;
      });

      if (this.data && this.data.length > 0) {
        this.lscache.set(key, this.data, 1);
      }
    }

    didUpdateAttributes() {
      this.lastUpdate = 0;

      if (this.isOpen) {
        this.updateHistory();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "lscache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "nodata", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "data", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "pair", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateHistory", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateHistory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didUpdateAttributes", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didUpdateAttributes"), _class.prototype)), _class);
  _exports.default = HistoryComponent;
});