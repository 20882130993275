define("justmoney-swap/providers/wallets/tron-link-widget", ["exports", "@ember/service", "justmoney-swap/providers/base-provider", "justmoney-swap/utils/tools"], function (_exports, _service, _baseProvider, _tools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TronLinkWidgetProvider = (_class = class TronLinkWidgetProvider extends _baseProvider.default {
    constructor(network, owner) {
      super(network, owner);

      _initializerDefineProperty(this, "notification", _descriptor, this);

      _defineProperty(this, "tempbalance", null);

      _defineProperty(this, "lastBalanceCheck", 0);

      _defineProperty(this, "lastBalanceCheckForToken", {});

      _defineProperty(this, "cachedTokenBalance", {});

      _defineProperty(this, "balanceCheckPromiseToken", {});

      const tronLinkInterval = setInterval(async () => {
        if (window.tronLink) {
          clearInterval(tronLinkInterval);
          window.tronLink.request({
            method: 'tron_requestAccounts'
          }).then(a => {
            this.logging.log(a);
          });
        }
      }, this.POLLING_INTERVAL);
      setInterval(() => {
        if (window.App.isVisible) {
          this.update();
        }
      }, 20000);
      window.addEventListener('message', e => {
        if (e.data.message && e.data.message.action == 'setAccount') {
          if (e.data.message.data.address != this.address) {
            window.location.reload();
          }
        }

        if (e.data.message && e.data.message.action == 'setNode') {
          window.location.reload();
        }
      });
    }

    addMessageListener(name, passback) {
      let func = event => {
        if (event.data && event.data.name == 'JMSwapResponseTRON' && event.data.functionName == name) {
          passback(event.data);
        }
      };

      window.addEventListener('message', func, false);
      return func;
    }

    removeListener(listener) {
      window.removeEventListener('message', listener);
    }

    postMessage(functionName, data) {
      let finalData = {
        name: 'JMSwapFunctionTRON',
        functionName: functionName,
        functionParams: data,
        iframeID: window.App.widgetOptions.iframeID
      };
      window.parent.postMessage(finalData, '*');
    }

    async resolve() {
      return new Promise(resolve => {});
    }

    async connect() {
      return new Promise(resolve => {
        let listener = this.addMessageListener('connected', () => {
          this.removeListener(listener);
          this.connected = true;
          this.update();

          for (let func in this.onConnectedPassbacks) {
            this.onConnectedPassbacks[func]();
          }

          resolve();
          window.amplitude.getInstance().logEvent('WALLET_CONNECT', {
            host: this.App.widgetOptions ? this.App.widgetOptions.host : window.location.host,
            widget: true,
            wallet: 'TronLink',
            network: window.App.NETWORK
          });
        });
        this.postMessage('connect');
      });
    }

    async update() {
      if (this.connected) {
        return new Promise(resolve => {
          let listener = this.addMessageListener('updated', data => {
            this.removeListener(listener);
            this.address = data.data.address;
            this.name = data.data.name;
            this.balance = data.data.balance;
            resolve();
          });
          this.postMessage('update');
        });
      }
    }

    async getAddress() {
      return this.address;
    }

    async getName() {
      return this.name;
    }

    async getBalance(token) {
      if (this.isBaseToken(token)) {
        return this.getBalanceForBase();
      } else {
        return this.getBalanceForToken(token);
      }
    }

    async getBalanceForBase() {
      if (new Date().getTime() > this.lastBalanceCheck + 5000 || this.tempbalance === null) {
        this.lastBalanceCheck = new Date().getTime();
        this.tempbalance = 0;
        this.balanceCheckPromise = new Promise(resolve => {
          let listener = this.addMessageListener('balanceForBaseResponse', data => {
            this.removeListener(listener);
            this.tempbalance = data.data;
            resolve(data.data);
          });
          this.postMessage('getBalanceForBase');
        });
        return await this.balanceCheckPromise;
      } else {
        return await this.balanceCheckPromise;
      }
    }

    async getBalanceForToken(token) {
      if (new Date().getTime() > this.lastBalanceCheckForToken[token.address] + 5000 || this.cachedTokenBalance[token.address] === undefined) {
        this.lastBalanceCheckForToken[token.address] = new Date().getTime();
        this.cachedTokenBalance[token.address] = 0;
        this.balanceCheckPromiseToken[token.address] = new Promise(resolve => {
          let listener = this.addMessageListener('balanceForTokenResponse' + token.symbol, data => {
            this.removeListener(listener);
            this.lastBalanceCheckForToken[token.address] = (0, _tools.fromWei)(data.data, token.decimals);
            resolve((0, _tools.fromWei)(data.data, token.decimals), token);
          });
          this.postMessage('getBalanceForToken', [token]);
        });
        return await this.balanceCheckPromiseToken[token.address];
      } else {
        return this.balanceCheckPromiseToken[token.address];
      }
    }

    async getResources() {
      return new Promise(resolve => {
        let listener = this.addMessageListener('energyResponse', data => {
          this.removeListener(listener);
          resolve({
            energy: data.data.energy,
            bandwidth: data.data.bandwidth
          });
        });
        this.postMessage('getResources');
      });
    }

    fromBigNumber(amount, token) {
      return (0, _tools.fromWei)(amount, token.decimals);
    }

    async getAllowance(token, lp, useNormalRouter) {
      let router = this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.ROUTER_ADDRESS;

      if (lp) {
        router = useNormalRouter ? this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.ROUTER_ADDRESS : this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.LIQUIDITY_ROUTER;
      }

      return new Promise(resolve => {
        let listener = this.addMessageListener('allowanceResponse', data => {
          this.removeListener(listener);
          resolve(Number(data.data));
        });
        this.postMessage('getAllowance', [token.address, router]);
      });
    }

    async approve(token) {
      return new Promise((resolve, reject) => {
        let listener = this.addMessageListener('approvalResponse', data => {
          this.removeListener(listener);

          if (data.data === false) {
            reject();
          } else {
            resolve(data.data);
          }
        });
        this.postMessage('approve', [token.address, this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.ROUTER_ADDRESS]);
      });
    }

    async approveSigned(address, useNormalRouter) {
      return new Promise(resolve => {
        let router = useNormalRouter ? this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.ROUTER_ADDRESS : this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.LIQUIDITY_ROUTER;
        let listener = this.addMessageListener('approvalSignedResponse', data => {
          this.removeListener(listener);
          resolve(data.data);
        });
        this.postMessage('approveSigned', [address, router]);
      });
    }

    async execute(address, method, options, parameters, outputParamTypes) {
      return new Promise((resolve, reject) => {
        let listener = this.addMessageListener('executeResponse', data => {
          this.removeListener(listener);

          if (data.data) {
            resolve(data.data);
          } else {
            reject(data.data);
          }
        });
        this.postMessage('execute', [address, method, options, parameters, outputParamTypes]);
      });
    }

    async send(address, method, options, parameters) {
      return new Promise((resolve, reject) => {
        let listener = this.addMessageListener('sendResponse', data => {
          this.removeListener(listener);

          if (data.data) {
            resolve(data.data);
          } else {
            reject(data.data);
          }
        });
        this.postMessage('send', [address, method, options, parameters]);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notification", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = TronLinkWidgetProvider;
});