define("justmoney-swap/services/lscache", ["exports", "@ember/service", "lscache"], function (_exports, _service, _lscache) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LsCache extends _service.default {
    constructor() {
      super(...arguments);
      console.log('Init cache service');
      setInterval(() => {
        _lscache.default.flushExpired();
      }, 10000);
    }

    set(key, data, expiration) {
      return _lscache.default.set(key, data, expiration);
    }

    get(key) {
      return _lscache.default.get(key);
    }

    remove(key) {
      return _lscache.default.get(key);
    }

    flush() {
      return _lscache.default.flush();
    }

  }

  _exports.default = LsCache;
});