define("justmoney-swap/helpers/scanurl", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.scanurl = scanurl;

  function scanurl(params) {
    if (window.App.NETWORK == 'TRON') {
      return window.App.JM.NETWORKS[window.App.NETWORK].SCAN.url + params[0].substr(2, params[0].length);
    } else {
      return window.App.JM.NETWORKS[window.App.NETWORK].SCAN.url + params[0];
    }
  }

  var _default = (0, _helper.helper)(scanurl);

  _exports.default = _default;
});