define("justmoney-swap/core/base-controller", ["exports", "@ember/runloop", "@ember/controller", "@ember/service", "@glimmer/tracking"], function (_exports, _runloop, _controller, _service, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BaseController = (_class = class BaseController extends _controller.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "logging", _descriptor, this);

      _initializerDefineProperty(this, "provider", _descriptor2, this);

      _initializerDefineProperty(this, "App", _descriptor3, this);

      this.listeners = [];
      this.timeouts = [];
      this.intervals = [];
    }

    initialize() {
      this.logging.log('Initialize ' + this.App.get('currentRouteName') + ' controller');

      if (this.addListeners) {
        this.addListeners();
      }
    }

    destroying() {
      this.logging.log('destroying');

      if (this.removeListeners) {
        this.removeListeners();
      }

      if (this.resetAll) {
        this.resetAll();
      }

      if (window.globalIntervals && window.globalIntervals.length > 0) {
        for (let i = 0; i < window.globalIntervals.length; i++) {
          clearInterval(window.globalIntervals[i]);
        }
      }

      if (this.intervals) {
        for (let i = 0; i < this.intervals.length; i++) {
          clearInterval(this.intervals[i]);
        }
      }

      if (this.timeouts) {
        for (let i = 0; i < this.timeouts.length; i++) {
          clearTimeout(this.timeouts[i]);
        }
      }
    }

    removeListeners() {
      for (let item in this.listeners) {
        if (this.listeners.hasOwnProperty(item)) {
          if (item.indexOf('window.') > -1) {
            window.removeEventListener(item.replace('window.', ''), this.listeners[item]);
          } else {
            document.removeEventListener(item, this.listeners[item]);
          }

          delete this.listeners[item];
        }
      }
    }

    menuToggle() {
      this.dispatch('toggleMenu');
    }

    dispatch(name, data) {
      document.dispatchEvent(new CustomEvent(name, {
        detail: data
      }));
    }

    addListener(name, callback) {
      this.listeners[name] = callback;
      document.addEventListener(name, this.listeners[name]);
    }

    addWindowListener(name, callback) {
      this.listeners['window.' + name] = callback;
      window.addEventListener(name, this.listeners['window.' + name]);
    }

    setSafeTimeout(action, delay, args) {
      this.timeouts.push(setTimeout(action, delay, args));
    }

    setSafeInterval(action, delay, args) {
      let val = this.intervals.push(setInterval(action, delay, args)); // #push returns the new array's length, while we want the index

      return val - 1;
    }

    clearSafeInterval(index) {
      clearInterval(this.intervals[index]);
      this.intervals.splice(index, 1);
    }

    afterRender(callback) {
      (0, _runloop.schedule)('afterRender', this, callback);
    }

    currentProvider() {
      return this.provider.getProvider(this.App.NETWORK);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "logging", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "App", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return window.App;
    }
  })), _class);
  _exports.default = BaseController;
});