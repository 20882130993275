define("justmoney-swap/controllers/listing", ["exports", "justmoney-swap/core/base-controller"], function (_exports, _baseController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Pools extends _baseController.default {
    initialize() {
      super.initialize(...arguments);
      this.afterRender(() => {
        var form = document.getElementById('listingForm');

        async function handleSubmit(event) {
          event.preventDefault();
          var status = document.getElementById('listingFormStatus');
          var data = new FormData(event.target);
          fetch(event.target.action, {
            method: form.method,
            body: data,
            headers: {
              Accept: 'application/json'
            }
          }).then(response => response.json()).then(data => {
            if (data.error) {
              status.style.display = 'block';
              status.innerHTML = data.errors[0] ? 'Validation error: ' + data.errors[0].field + ' ' + data.errors[0].message : 'Thanks for your submission!';
            } else {
              status.innerHTML = 'Thanks for your submission!';
              form.reset();
              form.style.display = 'none';
              status.style.display = 'block';
            }
          }).catch(e => {
            form.style.display = 'none';
            status.style.display = 'block';
            status.innerHTML = 'Oops! There was a problem submitting your form';
          });
        }

        form.addEventListener('submit', handleSubmit);
      });
      window.amplitude.getInstance().logEvent('ROUTE_LISTING', {
        host: this.App.widgetOptions ? this.App.widgetOptions.host : window.location.host,
        widget: false,
        network: this.App.NETWORK
      });
    }

  }

  _exports.default = Pools;
});