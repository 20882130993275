define("justmoney-swap/providers/wallets/metamask", ["exports", "@ember/service", "justmoney-swap/providers/base-provider", "justmoney-swap/utils/tools"], function (_exports, _service, _baseProvider, _tools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _class2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MetamaskProvider = (_class = (_class2 = class MetamaskProvider extends _baseProvider.default {
    constructor(network, owner) {
      super(network, owner);

      _initializerDefineProperty(this, "provider", _descriptor, this);

      _defineProperty(this, "tempbalance", null);

      _defineProperty(this, "lastBalanceCheck", 0);

      _defineProperty(this, "lastBalanceCheckForToken", {});

      _defineProperty(this, "cachedTokenBalance", {});

      _defineProperty(this, "balanceCheckPromiseToken", {});

      _defineProperty(this, "walletName", 'MetaMask');

      _defineProperty(this, "accounts", void 0);

      setInterval(() => {
        if (window.App.isVisible && this.connected) {
          this.update();
        }
      }, 20000);
    }

    async resolve() {
      return new Promise(resolve => {
        if (this.web3) return resolve(this.web3);

        async function run() {
          if (window.ethereum) {
            this.accounts = await window.ethereum.request({
              method: 'eth_requestAccounts'
            });
            this.web3 = new Web3(window.ethereum);
            return resolve(this.web3);
          } else {
            setTimeout(run, this.POLLING_INTERVAL);
          }
        }

        run.call(this);
      });
    }

    async connect() {
      super.connect();
      window.amplitude.getInstance().logEvent('WALLET_CONNECT', {
        host: this.App.widgetOptions ? this.App.widgetOptions.host : window.location.host,
        widget: false,
        wallet: this.walletName,
        network: this.App.NETWORK
      });
    }

    async update() {
      if (this.connected) {
        super.update();
        this.balance = await this.getBalanceForBase();
      }
    }

    async getAddress() {
      return this.accounts[0];
    }

    async getName() {
      const web3 = await this.resolve();
      return web3.name;
    }

    async getBalance(token) {
      if (this.isBaseToken(token)) {
        return this.getBalanceForBase();
      } else {
        return this.getBalanceForToken(token);
      }
    }

    async getBalanceForBase() {
      if (new Date().getTime() > this.lastBalanceCheck + 5000 || this.tempbalance === null) {
        this.lastBalanceCheck = new Date().getTime();
        this.tempbalance = 0;
        this.balanceCheckPromise = new Promise(resolve => {
          this.resolve().then(web3 => {
            this.getAddress().then(address => {
              web3.eth.getBalance(address).then(balance => {
                this.tempbalance = balance;
                resolve(this.web3.utils.fromWei(balance));
              });
            });
          });
        });
        return await this.balanceCheckPromise;
      } else {
        return await this.balanceCheckPromise;
      }
    }

    async getBalanceForToken(token) {
      if (new Date().getTime() > this.lastBalanceCheckForToken[token.address] + 5000 || this.cachedTokenBalance[token.address] === undefined) {
        this.lastBalanceCheckForToken[token.address] = new Date().getTime();
        this.cachedTokenBalance[token.address] = 0;
        this.balanceCheckPromiseToken[token.address] = new Promise(async resolve => {
          const web3 = await this.resolve();
          const contract = new web3.eth.Contract(this.minABI, token.address);
          const balance = await contract.methods.balanceOf(await this.getAddress()).call();
          this.cachedTokenBalance[token.address] = balance;
          resolve(this.fromBigNumber(balance, token));
        });
        return await this.balanceCheckPromiseToken[token.address];
      } else {
        return this.balanceCheckPromiseToken[token.address];
      }
    }

    fromBigNumber(amount, token) {
      return (0, _tools.fromWei)(amount, token.decimals);
    }

    async getAllowance(token, lp, useNormalRouter) {
      let router = this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.ROUTER_ADDRESS;

      if (lp) {
        router = useNormalRouter ? this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.ROUTER_ADDRESS : this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.LIQUIDITY_ROUTER;
      }

      const web3 = await this.resolve();
      const contract = new web3.eth.Contract(this.minABI, token.address);
      const allowance = await contract.methods.allowance(await this.getAddress(), router).call();
      return allowance;
    }

    async getAllowanceForAddress(token, address) {
      const web3 = await this.resolve();
      const contract = new web3.eth.Contract(this.minABI, token.address);
      const allowance = await contract.methods.allowance(await this.getAddress(), address).call();
      return allowance;
    }

    async approve(token, address) {
      const web3 = await this.resolve();
      const contract = new web3.eth.Contract(this.minABI, token.address);
      return await contract.methods.approve(address || this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.ROUTER_ADDRESS, this.MAX_APPROVAL_VALUE).send({
        from: await this.getAddress()
      });
    }

    async approveSigned(address, useNormalRouter) {
      const parameters = [{
        type: MetamaskProvider.PARAMETERS.ADDRESS,
        value: useNormalRouter ? this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.ROUTER_ADDRESS : this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.LIQUIDITY_ROUTER
      }, {
        type: MetamaskProvider.PARAMETERS.UINT256,
        value: this.MAX_APPROVAL_VALUE
      }];
      let transaction = this.send(address, 'approve(address,uint256)', {}, parameters);
      return transaction;
    }

    sortAddresses(from, to) {
      const fromHex = from.hex || this.web3.utils.toHex(from.address);
      const toHex = to.hex || this.web3.utils.toHex(to.address);
      return fromHex < toHex ? [from, to] : [to, from];
    }

    async execute(address, method, options, parameters) {
      const web3 = await this.resolve();
      const wallet = this;
      let abi = this.App.JM.NETWORKS[this.App.NETWORK].ABI[address] || this.minABI;
      const swapApi = new web3.eth.Contract(abi, address);
      if (!options) options = {};
      options.from = wallet && this.connected ? await wallet.getAddress() : null;

      if (parameters) {
        let paramsArr = parameters.map(val => {
          return val.value;
        });
        let ret = await swapApi.methods[method].apply(this, paramsArr).call(options).catch(e => this.logging.log(e));

        if (ret.transactionHash) {
          ret.txid = ret.transactionHash;
        }

        return ret;
      } else {
        let ret = await swapApi.methods[method].apply(this).call(options).catch(e => this.logging.log(e));

        if (ret.transactionHash) {
          ret.txid = ret.transactionHash;
        }

        return ret;
      }
    }

    async send(address, method, options, parameters) {
      const web3 = await this.resolve();
      let abi = this.App.JM.NETWORKS[this.App.NETWORK].ABI[address] || this.minABI;
      const swapApi = new web3.eth.Contract(abi, address); //options.gas = ;
      //options.gasPrice = ;

      let paramsArr = parameters.map(val => {
        return val.value;
      });
      let ret = await swapApi.methods[method].apply(this, paramsArr).send({
        value: options.value,
        from: await this.getAddress()
      }).catch(e => console.error(e));

      if (ret.transactionHash) {
        ret.txid = ret.transactionHash;
      }

      return ret;
    }

  }, _defineProperty(_class2, "PARAMETERS", {
    ADDRESS: 'address',
    ADDRESS_ARRAY: 'address[]',
    UINT256: 'uint256',
    UINT256_ARRAY: 'uint256[]',
    BYTES32_ARRAY: 'bytes32[]',
    BOOL: 'bool',
    STRING_ARRAY: 'string[]'
  }), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = MetamaskProvider;
});