define("justmoney-swap/helpers/returnIfEq", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.returnIfEq = returnIfEq;

  function returnIfEq(params) {
    if (params[1] == params[2]) {
      return params[0];
    }

    return '';
  }

  var _default = (0, _helper.helper)(returnIfEq);

  _exports.default = _default;
});