define("justmoney-swap/providers/wallets/metamaskzenith", ["exports", "justmoney-swap/providers/wallets/metamask"], function (_exports, _metamask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MetamaskzenithProvider extends _metamask.default {
    constructor(network, owner) {
      super(network, owner);
    }

    async approve(token, address) {
      const web3 = await this.resolve();
      const contract = new web3.eth.Contract(this.minABI, token.address);
      return await contract.methods.approve(address || this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.ROUTER_ADDRESS, this.MAX_APPROVAL_VALUE).send({
        from: await this.getAddress(),
        gasPrice: 60000000000
      });
    }

    async send(address, method, options, parameters) {
      const web3 = await this.resolve();
      let abi = this.App.JM.NETWORKS[this.App.NETWORK].ABI[address] || this.minABI;
      const swapApi = new web3.eth.Contract(abi, address); //options.gas = ;
      //options.gasPrice = ;

      let paramsArr = parameters.map(val => {
        return val.value;
      });
      let ret = await swapApi.methods[method].apply(this, paramsArr).send({
        value: options.value,
        from: await this.getAddress(),
        gasPrice: 60000000000
      }).catch(e => this.logging.error(e));

      if (ret.transactionHash) {
        ret.txid = ret.transactionHash;
      }

      return ret;
    }

  }

  _exports.default = MetamaskzenithProvider;
});