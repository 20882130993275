define("justmoney-swap/services/notification", ["exports", "@ember/service", "notyf", "justmoney-swap/core/base-service"], function (_exports, _service, _notyf, _baseService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class NotificationService extends _baseService.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "notyf", new _notyf.Notyf({
        duration: 2000,
        dismissible: true,
        position: {
          x: 'right',
          y: 'top'
        }
      }));
    }

    success(message) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      options.message = message;
      this.notyf.success(options);
    }

    error(error) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      options.message = error;
      this.notyf.error(options);
    }

  }

  _exports.default = NotificationService;
});