define("justmoney-swap/routes/tokens", ["exports", "justmoney-swap/routes/token"], function (_exports, _token) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TokensRoute extends _token.default {}

  _exports.default = TokensRoute;
});