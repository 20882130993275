define("justmoney-swap/helpers/equalsor", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.equalsor = equalsor;

  function equalsor(params) {
    if (params[0] == params[1]) {
      return true;
    }

    if (params[2] && params[0] == params[2]) {
      return true;
    }

    if (params[3] && params[0] == params[3]) {
      return true;
    }

    if (params[4] && params[0] == params[4]) {
      return true;
    }

    if (params[5] && params[0] == params[5]) {
      return true;
    }

    return false;
  }

  var _default = (0, _helper.helper)(equalsor);

  _exports.default = _default;
});