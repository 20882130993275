define("justmoney-swap/helpers/toPathString", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toPathString = toPathString;

  function toPathString(params) {
    if (params[0] && params[0].length) {
      if (params[0].length == 2 && params[0][0] == 'JM' && params[0][1] == 'JM') {
        return params[0][0];
      }

      return params[0].join(' \u2794 ');
    }

    return '';
  }

  var _default = (0, _helper.helper)(toPathString);

  _exports.default = _default;
});