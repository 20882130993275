define("justmoney-swap/controllers/widget", ["exports", "justmoney-swap/core/base-controller", "@glimmer/tracking", "@ember/object", "@ember/service", "justmoney-swap/utils/tools"], function (_exports, _baseController, _tracking, _object, _service, _tools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Widget = (_class = class Widget extends _baseController.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "provider", _descriptor, this);

      _defineProperty(this, "queryParams", ['n', 't', 'from', 'to']);

      _initializerDefineProperty(this, "n", _descriptor2, this);

      _initializerDefineProperty(this, "t", _descriptor3, this);

      _initializerDefineProperty(this, "from", _descriptor4, this);

      _initializerDefineProperty(this, "to", _descriptor5, this);

      _initializerDefineProperty(this, "liquidityTab", _descriptor6, this);

      _initializerDefineProperty(this, "networks", _descriptor7, this);
    }

    initialize() {
      super.initialize(...arguments);

      if (!this.t) {
        this.t = 'swap';
      }

      if (!this.n) {
        this.n = this.App.NETWORK || 'TRON';
      }

      if (!this.from) {
        this.from = this.App.DEFAULT_TOKENS[this.App.NETWORK][0];
      }

      if (!this.to) {
        this.to = this.App.DEFAULT_TOKENS[this.App.NETWORK][1];
      }

      this.afterRender(() => {
        this.initializeSettings();
      });
      window.amplitude.getInstance().logEvent('ROUTE_WIDGET', {
        host: this.App.widgetOptions ? this.App.widgetOptions.host : window.location.host,
        widget: true,
        network: this.App.NETWORK
      });
    }

    initializeSettings() {
      if (this.App.widgetOptions) {
        this.setSafeInterval(() => {
          let height = document.getElementById('mainWrapper').offsetHeight;

          if (this.lastHeight != height) {
            this.resizeIframe(height);
          }

          this.lastHeight = height;
        }, 500);
      }

      if (this.App.widgetOptions.hasOwnProperty("liquidityTab") && this.App.widgetOptions.liquidityTab) {
        this.liquidityTab = true;
      }

      if (Array.isArray(this.App.widgetOptions.network)) {
        this.networks = this.App.widgetOptions.network;
      }

      if (this.App.widgetOptions.hasOwnProperty("shadow") && this.App.widgetOptions.shadow == false) {
        this.addStyle('.swapbox, .add-liquidity-box {box-shadow:none !important;}');
      }

      if (this.App.widgetOptions.hasOwnProperty('backgroundColor')) {
        this.addStyle('.swapbox, .add-liquidity-box, .widgetTab .networkTabs a .tab.active {background-color:' + this.App.widgetOptions.backgroundColor + ' !important;}');
      }

      if (this.App.widgetOptions.hasOwnProperty('backgroundImage')) {
        this.addStyle('.swapbox, .add-liquidity-box {background-image:' + this.App.widgetOptions.backgroundImage + '}');
      }

      if (this.App.widgetOptions.hasOwnProperty('textColor')) {
        this.addStyle('.swapbox, .add-liquidity-box, .swapbox a, .add-liquidity-box a {color:' + this.App.widgetOptions.textColor + ' !important;}');
        this.addStyle('.swapbox .infoContainer {border-color:' + this.App.widgetOptions.textColor + ' !important;}');
      }

      if (this.App.widgetOptions.hasOwnProperty('buttonStyle')) {
        this.addStyle('.swapbox .connectButton, .swapbox .swapButton, ' + '.add-liquidity-box .connectButton, .add-liquidity-box .addLiquidityButton, .add-liquidity-box .supplyButton {' + this.App.widgetOptions.buttonStyle + '}');
      }

      if (this.App.widgetOptions.hasOwnProperty('maxButtonStyle')) {
        this.addStyle('.swapbox .inputContainer .maxButton, ' + '.add-liquidity-box .inputContainer .maxButton {' + this.App.widgetOptions.maxButtonStyle + '}');
      }

      if (this.App.widgetOptions.hasOwnProperty('headingStyle')) {
        this.addStyle('.swapbox h3, .add-liquidity-box h3 {' + this.App.widgetOptions.headingStyle + '}');
      }

      if (this.App.widgetOptions.hasOwnProperty('lightBranding') && this.App.widgetOptions.lightBranding) {
        this.addStyle('.swapbox .brandingLogo, .add-liquidity-box .brandingLogo  {filter:none;opacity:1}');
      }

      if (this.App.widgetOptions.hasOwnProperty('tabNavStyle') && this.App.widgetOptions.tabNavStyle) {
        this.addStyle('.tabSwitcher .nav-item {' + this.App.widgetOptions.tabNavStyle + '}');
      }

      if (this.App.widgetOptions.hasOwnProperty('tabNavStyle') && this.App.widgetOptions.tabNavActiveStyle) {
        this.addStyle('.tabSwitcher .nav-item.active {' + this.App.widgetOptions.tabNavActiveStyle + '}');
      }

      if (this.App.widgetOptions.hasOwnProperty('chartBackgroundColor') && this.App.widgetOptions.chartBackgroundColor) {
        this.addStyle('.widgetTab .swapbox .chart-component { background:' + this.App.widgetOptions.chartBackgroundColor + '}');
      }
    }

    resizeIframe(height) {
      let finalData = {
        name: 'JMSwapFunction' + this.App.NETWORK,
        functionName: 'resizeIframe',
        functionParams: [height],
        iframeID: window.App.widgetOptions.iframeID
      };
      window.parent.postMessage(finalData, '*');
      let finalData2 = {
        name: 'JMSwapFunctionWeb3',
        functionName: 'resizeIframe',
        functionParams: [height],
        iframeID: window.App.widgetOptions.iframeID
      };
      window.parent.postMessage(finalData, '*');
      window.parent.postMessage(finalData2, '*');
    }
    /**
     * Utility function to add replaceable CSS.
     * @param {string} styleString
     */


    addStyle(styleString) {
      const style = document.createElement("style");
      document.head.append(style);
      style.textContent = styleString;
    }

    onTabClick(network) {
      if (window.App.staging) {
        (0, _tools.setCookie)('SELECTED_NETWORK', network, true);
      } else {
        (0, _tools.setCookie)('SELECTED_NETWORK', network, false);
      }

      this.changeNetwork(network);
    }

    async changeNetwork(network) {
      this.provider.getProvider(network);
      this.App.setLoading(true);
      let from = this.App.DEFAULT_TOKENS[this.App.NETWORK][0];
      let to = this.App.DEFAULT_TOKENS[this.App.NETWORK][1];
      this.updateQuery({
        n: network,
        from: from,
        to: to
      });
    }

    updateQuery(query) {
      if (query.n) {
        this.n = query.n;
      }

      if (query.from) {
        this.from = query.from;
      }

      if (query.to) {
        this.to = query.to;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "n", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "t", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "from", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "to", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "liquidityTab", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "networks", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onTabClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onTabClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeNetwork", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeNetwork"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateQuery", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateQuery"), _class.prototype)), _class);
  _exports.default = Widget;
});