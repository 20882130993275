define("justmoney-swap/adapters/application", ["exports", "@ember/string", "ember-inflector", "@ember-data/adapter/rest"], function (_exports, _string, _emberInflector, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ApplicationAdapter extends _rest.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "host", window.App.APIURL + '/v1');
    }

    pathForType(type) {
      var camelized = (0, _string.camelize)(type);
      return (0, _emberInflector.singularize)(camelized);
    }

    handleResponse(status) {
      return super.handleResponse(...arguments);
    }

  }

  _exports.default = ApplicationAdapter;
});