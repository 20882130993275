define("justmoney-swap/helpers/baseTokenSymbol", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.baseTokenSymbol = baseTokenSymbol;
  _exports.default = void 0;

  function baseTokenSymbol() {
    if (window.App.NETWORK && window.App.JM.NETWORKS) {
      return window.App.JM.NETWORKS[window.App.NETWORK].BASE_TOKEN;
    }

    return '';
  }

  var _default = (0, _helper.helper)(baseTokenSymbol);

  _exports.default = _default;
});