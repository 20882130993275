define("justmoney-swap/services/logging", ["exports", "justmoney-swap/core/base-service"], function (_exports, _baseService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LoggingService extends _baseService.default {
    log(message) {
      if (this.App.JM.DEBUG) {
        for (var _len = arguments.length, params = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          params[_key - 1] = arguments[_key];
        }

        if (params.length) {
          console.log(message, params);
        } else {
          console.log(message);
        }
      }
    }

    info(message) {
      if (this.App.JM.DEBUG) {
        for (var _len2 = arguments.length, params = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
          params[_key2 - 1] = arguments[_key2];
        }

        if (params.length) {
          console.info(message, params);
        } else {
          console.info(message);
        }
      }
    }

    warn(message) {
      if (this.App.JM.DEBUG) {
        for (var _len3 = arguments.length, params = new Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
          params[_key3 - 1] = arguments[_key3];
        }

        if (params.length) {
          console.warn(message, params);
        } else {
          console.warn(message);
        }
      }
    }

    error(message) {
      if (this.App.JM.DEBUG) {
        for (var _len4 = arguments.length, params = new Array(_len4 > 1 ? _len4 - 1 : 0), _key4 = 1; _key4 < _len4; _key4++) {
          params[_key4 - 1] = arguments[_key4];
        }

        if (params.length) {
          console.error(message, params);
        } else {
          console.error(message);
        }
      }
    }

  }

  _exports.default = LoggingService;
});