define("justmoney-swap/providers/wallets/metamasketh", ["exports", "justmoney-swap/providers/wallets/metamask"], function (_exports, _metamask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MetamaskethProvider extends _metamask.default {}

  _exports.default = MetamaskethProvider;
});