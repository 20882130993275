define("justmoney-swap/components/popup/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "bg7Sb2bz",
    "block": "[[[10,0],[14,0,\"popupContainer\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"popup\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"icon\"],[12],[10,\"img\"],[15,\"src\",[30,1]],[12],[13],[13],[1,\"\\n        \"],[10,\"h3\"],[12],[1,[30,2]],[13],[1,\"\\n\\n        \"],[18,5,null],[1,\"\\n\\n        \"],[11,\"button\"],[24,0,\"closeBtn\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,3]],null],[12],[1,\"CLOSE\"],[13],[1,\"\\n        \"],[1,[30,4]],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[\"@icon\",\"@title\",\"@onClose\",\"@footer\",\"&default\"],false,[\"yield\",\"on\"]]",
    "moduleName": "justmoney-swap/components/popup/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});