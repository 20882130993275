define("justmoney-swap/helpers/toFixedWithMax", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toFixedWithMax = toFixedWithMax;

  function toFixedWithMax(params) {
    if (!params[0] && !params !== 0) return '';
    let decimals = Math.min(params[1], params[2]);
    return parseFloat(params[0]).toFixed(decimals);
  }

  var _default = (0, _helper.helper)(toFixedWithMax);

  _exports.default = _default;
});