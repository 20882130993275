define("justmoney-swap/helpers/isPair", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isPair = isPair;

  function isPair(params) {
    let base = window.App.JM.NETWORKS[window.App.NETWORK].BASE_TOKEN;
    let symbolA = params[0] == 'W' + base ? base : params[0];
    let symbolB = params[1] == 'W' + base ? base : params[1];
    let symbolC = params[2] == 'W' + base ? base : params[2];
    let symbolD = params[3] == 'W' + base ? base : params[3];
    if (symbolA == symbolC && symbolB == symbolD || symbolA == symbolD && symbolB == symbolC) return true;
    return false;
  }

  var _default = (0, _helper.helper)(isPair);

  _exports.default = _default;
});