define("justmoney-swap/routes/jmtoken", ["exports", "justmoney-swap/core/base-route", "@ember/service", "@ember/object"], function (_exports, _baseRoute, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let JmtokenRoute = (_class = class JmtokenRoute extends _baseRoute.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "tokenPair", _descriptor, this);

      _initializerDefineProperty(this, "lscache", _descriptor2, this);

      _defineProperty(this, "tokensToMigrate", ['OLDJM']);

      _defineProperty(this, "queryParams", {
        t: {
          refreshModel: false
        }
      });
    }

    refreshModel() {
      this.refresh();
    }

    async beforeModel() {
      this.tokens = await this.tokenPair.getTokens();
      this.tokens = this.tokens.filter(token => this.tokensToMigrate.indexOf(token.symbol) !== -1);
    }

    async model(params) {
      this.provider.getProvider(this.App.NETWORK);
      return {
        token: params.t,
        tokens: this.tokens
      };
    }

    async setupController(controller, model, transition) {
      super.setupController(controller, model, transition);
      controller.tokens = model.tokens;

      if (model.token) {
        controller.token = this.getTokenBySymbol(model.token);
      }
    }

    getTokenBySymbol(symbol) {
      return this.tokens.find(token => token.symbol === symbol);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "tokenPair", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "lscache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "refreshModel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "refreshModel"), _class.prototype)), _class);
  _exports.default = JmtokenRoute;
});