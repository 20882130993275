define("justmoney-swap/helpers/formatNumberWithDecimals", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatNumberWithDecimals = formatNumberWithDecimals;

  function formatNumberWithDecimals(params) {
    if (!params[0] && !params !== 0) return 0;
    let res;

    if (params[1] > 0) {
      res = Number(params[0]) / Math.pow(10, params[1]);
    } else {
      res = Number(params[0]);
    }

    if (params[2] === 0) {
      return new Intl.NumberFormat('en-US', {
        maximumFractionDigits: params[2] || 18
      }).format(res);
    }

    return new Intl.NumberFormat('en-US', {
      maximumSignificantDigits: params[2] || 4
    }).format(res);
  }

  var _default = (0, _helper.helper)(formatNumberWithDecimals);

  _exports.default = _default;
});