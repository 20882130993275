define("justmoney-swap/components/chart-component/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "EZLBtsLG",
    "block": "[[[11,0],[24,0,\"chart-component\"],[4,[38,0],[[30,0,[\"didInsert\"]]],null],[4,[38,1],[[30,0,[\"didUpdateAttributes\"]],[30,1],[30,2]],null],[12],[1,\"\\n\"],[41,[30,0,[\"nodata\"]],[[[1,\"        \"],[10,0],[14,0,\"nodata\"],[12],[1,\"No chart data available\"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[14,0,\"pair\"],[12],[8,[39,3],null,[[\"@route\",\"@query\"],[\"charts\",[28,[37,4],null,[[\"symbolA\",\"symbolB\"],[[30,3],[30,4]]]]]],[[\"default\"],[[[[1,[30,3]],[1,\"/\"],[1,[30,4]]],[]]]]],[13],[1,\"\\n\"]],[]]],[41,[30,0,[\"loading\"]],[[[1,\"        \"],[8,[39,5],null,[[\"@loadingText\"],[\"Loading Chart...\"]],null],[1,\"\\n\"]],[]],null],[13]],[\"@tokenA\",\"@tokenB\",\"@tokenASymbol\",\"@tokenBSymbol\"],false,[\"did-insert\",\"did-update\",\"if\",\"link-to\",\"hash\",\"in-component-loading-spinner\"]]",
    "moduleName": "justmoney-swap/components/chart-component/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});