define("justmoney-swap/components/new-version-reloader/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "mAXDGwTy",
    "block": "[[[10,0],[14,1,\"newVersionMessage\"],[12],[1,\"A new version of the UI available.\"],[10,\"br\"],[12],[13],[1,\"\\n    Page will auto-refresh in \"],[1,[30,0,[\"secondsToAutoRefresh\"]]],[1,\" seconds to prevent any broken functionality.\\n\"],[13]],[],false,[]]",
    "moduleName": "justmoney-swap/components/new-version-reloader/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});