define("justmoney-swap/controllers/token", ["exports", "@glimmer/tracking", "justmoney-swap/core/base-controller", "@ember/service", "@ember/object", "justmoney-swap/utils/tools"], function (_exports, _tracking, _baseController, _service, _object, _tools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Token = (_class = class Token extends _baseController.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "justMoneyExchange", _descriptor, this);

      _initializerDefineProperty(this, "justMoneyLiquidity", _descriptor2, this);

      _initializerDefineProperty(this, "tokenPair", _descriptor3, this);

      _initializerDefineProperty(this, "tokens", _descriptor4, this);

      _initializerDefineProperty(this, "token", _descriptor5, this);

      _initializerDefineProperty(this, "selectedToken", _descriptor6, this);

      _initializerDefineProperty(this, "selectedTokenSupply", _descriptor7, this);
    }

    async initialize() {
      super.initialize(...arguments);

      if (!this.token) {
        if (this.App.NETWORK == 'TRON') {
          this.selectedToken = this.tokens.find(item => {
            if (item.symbol == 'OLDJM') return item;
          });
          this.token = this.selectedToken.symbol;
        } else {
          this.token = this.tokens[0].symbol;
          this.selectedToken = this.tokens[0];
        }
      } else {
        this.selectedToken = this.tokens.find(item => {
          if (item.symbol == this.token) return item;
        });
      }

      this.selectedTokenSupply = null;
      let supply = await this.tokenPair.getTokenTotalSupply(this.selectedToken);
      this.selectedTokenSupply = supply.toString();
      window.amplitude.getInstance().logEvent('ROUTE_TOKEN', {
        host: this.App.widgetOptions ? this.App.widgetOptions.host : window.location.host,
        widget: false,
        network: this.App.NETWORK,
        token: this.token
      });
    }

    onTabClick(network) {
      if (window.App.staging) {
        (0, _tools.setCookie)('SELECTED_NETWORK', network, true);
      } else {
        (0, _tools.setCookie)('SELECTED_NETWORK', network, false);
      }

      this.changeNetwork(network);
      this.send('refreshModel');
    }

    async changeNetwork(network) {
      this.provider.getProvider(network);
    }

    async onTokenChanged(token) {
      this.transitionToRoute('token', token.symbol);
    }

    getTokenBySymbol(symbol) {
      return this.tokens.find(token => token.symbol === symbol);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "justMoneyExchange", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "justMoneyLiquidity", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tokenPair", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "tokens", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "token", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedToken", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "selectedTokenSupply", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onTabClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onTabClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeNetwork", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeNetwork"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTokenChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onTokenChanged"), _class.prototype)), _class);
  _exports.default = Token;
});