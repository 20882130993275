define("justmoney-swap/components/lp-pool-item/component", ["exports", "@ember/object", "@ember/service", "justmoney-swap/core/base-component", "@glimmer/tracking"], function (_exports, _object, _service, _baseComponent, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LpPoolItemComponent = (_class = class LpPoolItemComponent extends _baseComponent.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "justMoneyLiquidity", _descriptor, this);

      _initializerDefineProperty(this, "tokenA", _descriptor2, this);

      _initializerDefineProperty(this, "tokenB", _descriptor3, this);

      _initializerDefineProperty(this, "balanceA", _descriptor4, this);

      _initializerDefineProperty(this, "balanceB", _descriptor5, this);

      _initializerDefineProperty(this, "apy", _descriptor6, this);

      _defineProperty(this, "extraTrxPushedIn2Weeks", 2000);
    }

    didInsert() {
      let fromToken = this.App.JM.NETWORKS[this.App.NETWORK].TOKENS[this.args.fromToken];
      let toToken = this.App.JM.NETWORKS[this.App.NETWORK].TOKENS[this.args.toToken];
      let sorted;

      try {
        sorted = this.provider.getProvider(this.App.NETWORK).api.sortAddresses(fromToken, toToken);
      } catch (e) {
        console.error(e);
        return false;
      }

      this.tokenA = sorted[0];
      this.tokenB = sorted[1];
      this.justMoneyLiquidity.getCachedBalance(fromToken, toToken).then(res => {
        this.balanceA = res[sorted[0].address.toUpperCase()] / Math.pow(10, sorted[0].decimals);
        this.balanceB = res[sorted[1].address.toUpperCase()] / Math.pow(10, sorted[1].decimals);
        let symbolA = sorted[0].symbol;
        let symbolB = sorted[1].symbol;

        if (this.App.NETWORK == 'TRON') {
          if (symbolA == 'TRX') {
            symbolA = 'WTRX';
          }

          if (symbolB == 'TRX') {
            symbolB = 'WTRX';
          }
        } else if (this.App.NETWORK == 'BSC') {
          if (symbolA == 'BNB') {
            symbolA = 'WBNB';
          }

          if (symbolB == 'BNB') {
            symbolB = 'WBNB';
          }
        } else if (this.App.NETWORK == 'POLY') {
          if (symbolA == 'MATIC') {
            symbolA = 'WMATIC';
          }

          if (symbolB == 'MATIC') {
            symbolB = 'WMATIC';
          }
        }

        let volumeInfo;
        let apy, apy2, apy3, apy4;
        let extra = 0;

        if (symbolB == 'JM' && symbolA == 'WTRX') {
          extra = this.extraTrxPushedIn2Weeks * (365 / 14);
        }

        this.args.volumes.then(volumes => {
          if (!volumes) {
            return;
          }

          if (volumes[symbolA + '_' + symbolB]) {
            volumeInfo = volumes[symbolA + '_' + symbolB];
            let yearFees = 365 / 14 * (volumeInfo.amountA * 0.0025);
            apy = yearFees / this.balanceA * 100;
            let yearFees2 = 365 / 14 * (volumeInfo.amountB * 0.0025);
            apy2 = yearFees2 / this.balanceB * 100;

            if (symbolB == 'JM' && symbolA == 'WTRX') {
              apy4 = extra / (this.balanceA * 2) * 100;
            }
          }

          if (apy && apy2) {
            if (symbolB == 'JM' && symbolA == 'WTRX') {
              this.apy = ((apy + apy2) / 2 + apy4).toFixed(2);
            } else {
              this.apy = ((apy + apy2) / 2).toFixed(2);
            }
          }
        });
      }).catch(err => {});
    }

    addLiquidityClicked() {}

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "justMoneyLiquidity", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "tokenA", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tokenB", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "balanceA", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "balanceB", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "apy", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addLiquidityClicked", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addLiquidityClicked"), _class.prototype)), _class);
  _exports.default = LpPoolItemComponent;
});