define("justmoney-swap/helpers/logoPath", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.logoPath = logoPath;

  function logoPath(params) {
    if (!params[0] && !params !== 0) return '';
    if (!window.App.JM.NETWORKS[window.App.NETWORK].TOKENS[params[0]]) return '';
    return window.App.ASSETURL + window.App.JM.NETWORKS[window.App.NETWORK].TOKENS[params[0]].logoURI;
  }

  var _default = (0, _helper.helper)(logoPath);

  _exports.default = _default;
});