define("justmoney-swap/components/user-lp-pool-item/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Tgvy7OfT",
    "block": "[[[11,0],[24,0,\"userPoolItem\"],[4,[38,0],[[30,0,[\"didInsert\"]]],null],[12],[1,\"\\n    \"],[10,0],[14,0,\"top\"],[12],[10,\"img\"],[14,0,\"icon iconFrom\"],[15,\"src\",[28,[37,1],[[30,0,[\"tokenA\",\"symbol\"]]],null]],[12],[13],[10,\"img\"],[14,0,\"icon iconTo\"],[15,\"src\",[28,[37,1],[[30,0,[\"tokenB\",\"symbol\"]]],null]],[12],[13],[1,[30,0,[\"tokenA\",\"symbol\"]]],[1,\"/\"],[1,[30,0,[\"tokenB\",\"symbol\"]]],[1,\"\\n    \"],[11,\"button\"],[24,4,\"button\"],[4,[38,2],[\"click\",[28,[37,3],[[30,1],[30,0,[\"pairData\"]]],null]],null],[12],[1,\"REMOVE LIQUIDITY\"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"bottom\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"left\"],[12],[1,\"\\n            Amount \"],[1,[30,0,[\"tokenA\",\"symbol\"]]],[1,\" / \"],[1,[30,0,[\"tokenB\",\"symbol\"]]],[1,\"\\n            \"],[10,1],[14,0,\"balance\"],[12],[1,[28,[35,4],[[30,2],[30,0,[\"tokenA\",\"decimals\"]],8],null]],[1,\" / \"],[1,[28,[35,4],[[30,3],[30,0,[\"tokenB\",\"decimals\"]],8],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"right\"],[12],[1,\"\\n            Pool tokens / Pool share\\n            \"],[10,1],[14,0,\"balance\"],[12],[1,[28,[35,4],[[30,4],18],null]],[1,\" / \"],[1,[28,[35,5],[[30,4],[30,5]],null]],[1,\" %\"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[\"@removeClicked\",\"@amountA\",\"@amountB\",\"@userTokens\",\"@totalLiquidity\"],false,[\"did-insert\",\"logoPath\",\"on\",\"fn\",\"formatNumberWithDecimals\",\"userPoolPercentage\"]]",
    "moduleName": "justmoney-swap/components/user-lp-pool-item/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});