define("justmoney-swap/providers/base-provider", ["exports", "@ember/service", "@glimmer/tracking", "@ember/application"], function (_exports, _service, _tracking, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BaseProvider = (_class = class BaseProvider {
    constructor(network, owner) {
      _initializerDefineProperty(this, "logging", _descriptor, this);

      _initializerDefineProperty(this, "App", _descriptor2, this);

      _initializerDefineProperty(this, "connected", _descriptor3, this);

      _initializerDefineProperty(this, "balance", _descriptor4, this);

      _initializerDefineProperty(this, "name", _descriptor5, this);

      _initializerDefineProperty(this, "address", _descriptor6, this);

      _defineProperty(this, "MAX_APPROVAL_VALUE", '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff');

      _defineProperty(this, "onConnectedPassbacks", []);

      _defineProperty(this, "onDisconnectedPassbacks", []);

      _defineProperty(this, "minABI", [// totalSupply
      {
        "constant": true,
        "inputs": [],
        "name": "totalSupply",
        "outputs": [{
          "name": "totalSupply",
          "type": "uint256"
        }],
        "type": "function"
      }, // balanceOf
      {
        "constant": true,
        "inputs": [{
          "name": "_owner",
          "type": "address"
        }],
        "name": "balanceOf",
        "outputs": [{
          "name": "balance",
          "type": "uint256"
        }],
        "type": "function"
      }, // decimals
      {
        "constant": true,
        "inputs": [],
        "name": "decimals",
        "outputs": [{
          "name": "",
          "type": "uint8"
        }],
        "type": "function"
      }, {
        "constant": true,
        "inputs": [{
          "name": "_owner",
          "type": "address"
        }, {
          "name": "_spender",
          "type": "address"
        }],
        "name": "allowance",
        "outputs": [{
          "name": "remaining",
          "type": "uint256"
        }],
        "payable": false,
        "type": "function"
      }, {
        "constant": false,
        "inputs": [{
          "name": "spender",
          "type": "address"
        }, {
          "name": "tokens",
          "type": "uint256"
        }],
        "name": "approve",
        "outputs": [{
          "name": "success",
          "type": "bool"
        }],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
      }, {
        "inputs": [{
          "internalType": "uint8",
          "name": "destinationDomainID",
          "type": "uint8"
        }, {
          "internalType": "bytes32",
          "name": "resourceID",
          "type": "bytes32"
        }, {
          "internalType": "bytes",
          "name": "data",
          "type": "bytes"
        }],
        "name": "deposit",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
      }, {
        "inputs": [{
          "internalType": "uint8",
          "name": "destinationDomainID",
          "type": "uint8"
        }, {
          "internalType": "bytes",
          "name": "data",
          "type": "bytes"
        }, {
          "internalType": "bytes",
          "name": "dataSrc",
          "type": "bytes"
        }, {
          "internalType": "bytes",
          "name": "dataDest",
          "type": "bytes"
        }],
        "name": "depositCross",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
      }]);

      (0, _application.setOwner)(this, owner);
      this.network = network;
    }

    async resolve() {
      return new Promise(resolve => {
        return Promise.reject('Not implemented');
      });
    }

    onConnected(passback) {
      if (this.connected) {
        passback();
      } else {
        this.onConnectedPassbacks.push(passback);
      }
    }

    onDisconnected(passback) {
      this.onDisconnectedPassbacks.push(passback);
    }

    async connect() {
      await this.resolve().then(async () => {
        this.connected = true;
        await this.update();

        for (let func in this.onConnectedPassbacks) {
          this.onConnectedPassbacks[func]();
        }
      }).catch(err => {
        console.log(err);
      });
    }

    disconnect() {
      this.connected = false;
      this.address = null;
      this.name = null;
      this.balance = null;

      for (let func in this.onDisconnectedPassbacks) {
        this.onDisconnectedPassbacks[func]();
      }
    }

    async update() {
      this.address = await this.getAddress();
      this.name = await this.getName();
    }

    isBaseToken(token) {
      let conf = this.App.JM.NETWORKS[this.network];
      if (!conf.TOKENS[conf.BASE_TOKEN]) return false;
      return token.address === conf.TOKENS[conf.BASE_TOKEN].address;
    }

    async getAddress() {
      throw Error('Method needs to be implemented in the subclass');
    }

    async getName() {
      throw Error('Method needs to be implemented in the subclass');
    }

    async getBalance(token) {
      throw Error('Method needs to be implemented in the subclass');
    }

    async getBalanceForBase() {
      throw Error('Method needs to be implemented in the subclass');
    }

    async getBalanceForToken(token) {
      throw Error('Method needs to be implemented in the subclass');
    }

    fromBigNumber(amount, token) {
      return amount / Math.pow(10, token.decimals);
    }

    async getAllowance(token) {
      throw Error('Method needs to be implemented in the subclass');
    }

    async approve(token) {
      throw Error('Method needs to be implemented in the subclass');
    }

    async approveSigned(address) {
      throw Error('Method needs to be implemented in the subclass');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "logging", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "App", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return window.App;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "connected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "balance", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "name", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "address", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = BaseProvider;
});