define("justmoney-swap/helpers/toFloat", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toFloat = toFloat;

  function toFloat(_ref) {
    let [value, decimals] = _ref;

    const isInt = n => n % 1 === 0;

    const getDecimals = function (value) {
      if (Math.floor(value) !== value) return value.toString().split('.')[1].length || 0;
      return 0;
    };

    if (!value || !decimals) {
      return '';
    } else if (isInt(value)) {
      return value;
    } else if (value.toString().indexOf('e-') > -1 || value.toString().indexOf('e+') > -1) {
      return Number(value).toFixed(Math.min(decimals, 12));
    } else if (value.toString().endsWith('.')) {
      return value;
    } else if (getDecimals(value) > decimals) {
      // should be decimal by now
      return Math.floor(Number(value) * Math.pow(10, decimals)) / Math.pow(10, decimals);
    } else {
      return value;
    }
  }

  var _default = (0, _helper.helper)(toFloat);

  _exports.default = _default;
});