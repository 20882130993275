define("justmoney-swap/controllers/charts", ["exports", "@glimmer/tracking", "justmoney-swap/core/base-controller", "@ember/service", "@ember/object", "justmoney-swap/utils/tools"], function (_exports, _tracking, _baseController, _service, _object, _tools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Charts = (_class = class Charts extends _baseController.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "currentPrice", _descriptor, this);

      _initializerDefineProperty(this, "growth1d", _descriptor2, this);

      _initializerDefineProperty(this, "justMoneyExchange", _descriptor3, this);

      _initializerDefineProperty(this, "justMoneyLiquidity", _descriptor4, this);

      _defineProperty(this, "lastUpdate", 0);

      _initializerDefineProperty(this, "pairs", _descriptor5, this);

      _initializerDefineProperty(this, "n", _descriptor6, this);

      _initializerDefineProperty(this, "fromToken", _descriptor7, this);

      _initializerDefineProperty(this, "toToken", _descriptor8, this);

      _initializerDefineProperty(this, "selectedPair", _descriptor9, this);

      _initializerDefineProperty(this, "symbolA", _descriptor10, this);

      _initializerDefineProperty(this, "symbolB", _descriptor11, this);

      _initializerDefineProperty(this, "tokenSupply", _descriptor12, this);

      _initializerDefineProperty(this, "tokenMcap", _descriptor13, this);
    }

    async initialize() {
      super.initialize(...arguments);

      if (!this.n) {
        this.n = this.App.NETWORK || 'TRON';
      }

      window.amplitude.getInstance().logEvent('ROUTE_CHART', {
        host: this.App.widgetOptions ? this.App.widgetOptions.host : window.location.host,
        widget: false,
        network: this.App.NETWORK
      });
      await this.justMoneyLiquidity.cacheBalances();
      this.setSafeInterval(async () => {
        if (this.App.isVisible && new Date().getTime() > this.lastUpdate + 10000) {
          this.lastUpdate = new Date().getTime();
          await this.justMoneyLiquidity.cacheBalances();
          this.updatePrice();
        }
      }, 15000);
      this.addListener('visibilitychange', async event => {
        if (!document.hidden && new Date().getTime() > this.lastUpdate + 10000) {
          this.lastUpdate = new Date().getTime();
          await this.justMoneyLiquidity.cacheBalances();
          this.updatePrice();
        }
      });
      this.lastUpdate = new Date().getTime();
      await this.justMoneyLiquidity.cacheBalances();
      this.updatePrice();
    }

    onTabClick(network) {
      if (window.App.staging) {
        (0, _tools.setCookie)('SELECTED_NETWORK', network, true);
      } else {
        (0, _tools.setCookie)('SELECTED_NETWORK', network, false);
      }

      this.changeNetwork(network);
      this.send('refreshModel');
    }

    async changeNetwork(network) {
      this.provider.getProvider(network);
      this.App.setLoading(true);
      this.n = network;
    }

    updatePrice() {
      this.justMoneyExchange.getPrice(this.toToken, this.fromToken).then(estimate => {
        if (this.priceCallback) {
          this.currentPrice = estimate;
          this.priceCallback(estimate);
        }
      }).catch(e => {
        console.log(e);
        this.toPrice = null;
        this.logging.error(e);
      });
    }

    onPairChanged(e) {
      const pair = e;
      this.selectedPair = pair;
      this.fromToken = this.getTokenBySymbol(pair.invert ? pair.symbolA : pair.symbolB);
      this.toToken = this.getTokenBySymbol(pair.invert ? pair.symbolB : pair.symbolA);
      this.symbolA = pair.symbolA;
      this.symbolB = pair.symbolB;
      setTimeout(() => {
        this.updatePrice();
      }, 500);
    }

    setUpdatePriceListener(callback) {
      this.priceCallback = callback;
    }

    getTokenBySymbol(symbol) {
      return this.tokens.find(token => token.symbol === symbol);
    }

    set justSwapData(data) {
      if (data.data.hasOwnProperty('volume24H')) {
        let vol = Math.round(data.data.volume24H);
        document.getElementById('token_volume').innerHTML = '$' + new Intl.NumberFormat('en-US', {
          maximumSignificantDigits: 4
        }).format(vol) + ' USD';
      }
    }

    set apiData(data) {
      if (data.hasOwnProperty('supply')) {
        this.tokenSupply = data.supply.total;
      }

      if (data.hasOwnProperty('marketcap')) {
        let mcap = data.marketcap / 1000000;
        this.tokenMcap = mcap;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentPrice", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "growth1d", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "justMoneyExchange", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "justMoneyLiquidity", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "pairs", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "n", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "fromToken", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.getTokenBySymbol('TRX');
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "toToken", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.getTokenBySymbol('JM');
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "selectedPair", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.pairs[0];
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "symbolA", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "symbolB", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "tokenSupply", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "tokenMcap", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onTabClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onTabClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeNetwork", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeNetwork"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onPairChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onPairChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setUpdatePriceListener", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setUpdatePriceListener"), _class.prototype)), _class);
  _exports.default = Charts;
});