define("justmoney-swap/controllers/ccwidget", ["exports", "justmoney-swap/core/base-controller", "@glimmer/tracking", "@ember/object", "@ember/service"], function (_exports, _baseController, _tracking, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Ccwidget = (_class = class Ccwidget extends _baseController.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "provider", _descriptor, this);

      _initializerDefineProperty(this, "networks", _descriptor2, this);

      _defineProperty(this, "queryParams", ['from', 'to', 'n1', 'n2']);

      _initializerDefineProperty(this, "n1", _descriptor3, this);

      _initializerDefineProperty(this, "n2", _descriptor4, this);

      _initializerDefineProperty(this, "from", _descriptor5, this);

      _initializerDefineProperty(this, "to", _descriptor6, this);

      _initializerDefineProperty(this, "tvl", _descriptor7, this);
    }

    initialize() {
      super.initialize(...arguments);

      if (!this.n1) {
        this.n1 = this.App.NETWORK || 'TRON';
      }

      if (!this.from) {
        if (this.n1 == 'TRON') {
          this.from = 'TRX';
        } else if (this.n1 == 'BSC') {
          this.from = 'BNB';
        } else if (this.n1 == 'POLY') {
          this.from = 'MATIC';
        } else if (this.n1 == 'BTTC') {
          this.from = 'TRX';
        }
      }

      if (!this.to) {
        this.to = 'JM';
      }

      if (!this.n2) {
        this.n2 = 'BSC';
      }

      if (this.n2 == this.n1) {
        if (this.n1 == 'BSC') {
          this.n2 = 'TRON';
        } else {
          this.n2 = 'BSC';
        }
      }

      this.afterRender(() => {
        this.initializeSettings();
      });
      window.amplitude.getInstance().logEvent('ROUTE_CCWIDGET', {
        host: this.App.widgetOptions ? this.App.widgetOptions.host : window.location.host,
        widget: true,
        network: this.App.NETWORK
      });
    }

    initializeSettings() {
      if (this.App.widgetOptions.hasOwnProperty("fromNetwork")) {
        this.changeNetwork(this.App.widgetOptions.fromNetwork);
        this.n1 = this.App.widgetOptions.fromNetwork;
      }

      if (this.App.widgetOptions.hasOwnProperty("toNetwork")) {
        this.n2 = this.App.widgetOptions.toNetwork;
      }

      if (this.App.widgetOptions) {
        this.setSafeInterval(() => {
          let height = document.getElementById('mainWrapper').offsetHeight;

          if (this.lastHeight != height) {
            this.resizeIframe(height);
          }

          this.lastHeight = height;
        }, 500);
      }

      if (Array.isArray(this.App.widgetOptions.network)) {
        this.networks = this.App.widgetOptions.network;
      }

      if (this.App.widgetOptions.hasOwnProperty("shadow") && this.App.widgetOptions.shadow == false) {
        this.addStyle('.crossswapbox, .add-liquidity-box {box-shadow:none !important;}');
      }

      if (this.App.widgetOptions.hasOwnProperty('backgroundColor')) {
        this.addStyle('.crossswapbox, .add-liquidity-box, .widgetTab .networkTabs a .tab.active {background-color:' + this.App.widgetOptions.backgroundColor + ' !important;}');
      }

      if (this.App.widgetOptions.hasOwnProperty('backgroundImage')) {
        this.addStyle('.crossswapbox, .add-liquidity-box {background-image:' + this.App.widgetOptions.backgroundImage + '}');
      }

      if (this.App.widgetOptions.hasOwnProperty('textColor')) {
        this.addStyle('.crossswapbox, .add-liquidity-box, .crossswapbox a, .add-liquidity-box a {color:' + this.App.widgetOptions.textColor + ' !important;}');
        this.addStyle('.crossswapbox .infoContainer {border-color:' + this.App.widgetOptions.textColor + ' !important;}');
      }

      if (this.App.widgetOptions.hasOwnProperty('buttonStyle')) {
        this.addStyle('.crossswapbox .connectButton, .crossswapbox .swapButton, ' + '.add-liquidity-box .connectButton, .add-liquidity-box .addLiquidityButton, .add-liquidity-box .supplyButton {' + this.App.widgetOptions.buttonStyle + '}');
      }

      if (this.App.widgetOptions.hasOwnProperty('maxButtonStyle')) {
        this.addStyle('.crossswapbox .inputContainer .maxButton, ' + '.add-liquidity-box .inputContainer .maxButton {' + this.App.widgetOptions.maxButtonStyle + '}');
      }

      if (this.App.widgetOptions.hasOwnProperty('headingStyle')) {
        this.addStyle('.crossswapbox h3, .add-liquidity-box h3 {' + this.App.widgetOptions.headingStyle + '}');
      }

      if (this.App.widgetOptions.hasOwnProperty('lightBranding') && this.App.widgetOptions.lightBranding) {
        this.addStyle('.crossswapbox .brandingLogo, .add-liquidity-box .brandingLogo  {filter:none;opacity:1}');
      }
    }

    resizeIframe(height) {
      let finalData = {
        name: 'JMSwapFunction' + this.App.NETWORK,
        functionName: 'resizeIframe',
        functionParams: [height],
        iframeID: window.App.widgetOptions.iframeID
      };
      window.parent.postMessage(finalData, '*');
      let finalData2 = {
        name: 'JMSwapFunctionWeb3',
        functionName: 'resizeIframe',
        functionParams: [height],
        iframeID: window.App.widgetOptions.iframeID
      };
      window.parent.postMessage(finalData, '*');
      window.parent.postMessage(finalData2, '*');
    }
    /**
     * Utility function to add replaceable CSS.
     * @param {string} styleString
     */


    addStyle(styleString) {
      const style = document.createElement("style");
      document.head.append(style);
      style.textContent = styleString;
    }

    async changeNetwork(network) {
      this.provider.getProvider(network);
    }

    updateQuery(query) {
      if (query.from) {
        this.from = query.from;
      }

      if (query.to) {
        this.to = query.to;
      }

      if (query.n1 && this.n1 != query.n1) {
        this.n1 = query.n1;
      }

      if (query.n2 && this.n2 != query.n2) {
        this.n2 = query.n2;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "networks", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "n1", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "n2", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "from", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "to", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "tvl", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "changeNetwork", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeNetwork"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateQuery", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateQuery"), _class.prototype)), _class);
  _exports.default = Ccwidget;
});