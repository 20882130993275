define("justmoney-swap/components/add-token-metamask/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "43tubZMh",
    "block": "[[[11,\"button\"],[24,0,\"addTokenMetamask\"],[4,[38,0],[[30,0,[\"didInsert\"]]],null],[4,[38,1],[[30,0,[\"didUpdateAttributes\"]],[30,1]],null],[4,[38,2],[\"click\",[30,0,[\"addTokenClicked\"]]],null],[12],[1,\"\\n    \"],[10,\"img\"],[14,\"src\",\"assets/img/wallets/icon-metamask.svg\"],[12],[13],[1,\" Add token on metamask\\n\"],[13]],[\"@token\"],false,[\"did-insert\",\"did-update\",\"on\"]]",
    "moduleName": "justmoney-swap/components/add-token-metamask/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});