define("justmoney-swap/templates/ccwidget", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "wvBSbWpH",
    "block": "[[[1,[28,[35,0],[\"JustMoney Swap\"],null]],[1,\"\\n\"],[10,0],[14,0,\"widgetTab\"],[12],[1,\"\\n\"],[8,[39,1],null,[[\"@from\",\"@to\",\"@toNetwork\",\"@fromNetwork\",\"@updateQuery\"],[[30,0,[\"from\"]],[30,0,[\"to\"]],[30,0,[\"n2\"]],[30,0,[\"n1\"]],[28,[37,2],[[30,0],[30,0,[\"updateQuery\"]]],null]]],[[\"default\"],[[[[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"page-title\",\"cross-swap-box\",\"action\"]]",
    "moduleName": "justmoney-swap/templates/ccwidget.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});