define("justmoney-swap/core/base-route", ["exports", "@ember/routing/route", "@ember/service", "@glimmer/tracking", "@ember/object", "justmoney-swap/utils/tools"], function (_exports, _route, _service, _tracking, _object, _tools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BaseRoute = (_class = class BaseRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "logging", _descriptor, this);

      _initializerDefineProperty(this, "provider", _descriptor2, this);

      _initializerDefineProperty(this, "App", _descriptor3, this);
    }

    dispatch(name, data) {
      document.dispatchEvent(new CustomEvent(name, {
        detail: data
      }));
    }

    addListener(name, callback) {
      document.addEventListener(name, callback);
    }

    setupController(controller, model, transition) {
      super.setupController(...arguments);
      this.App.set('currentRouteName', transition.targetName);
    }

    currentProvider() {
      return this.provider.getProvider(this.App.NETWORK);
    }

    willTransition(transition) {
      this.controller.active = false;

      if (typeof this.controller.destroying == 'function') {
        this.controller.destroying();
      }

      this.dispatch('routeWillTransition');
      return true; // Bubble the didTransition event
    }

    didTransition() {
      this.controller.active = true;

      if (typeof this.controller.initialize == 'function') {
        this.controller.initialize();
      }

      this.dispatch('routeDidTransition'); //Ember.run.next(() => this.App.set("currentRouteName", this.get('router').get('currentRouteName')));

      return true; // Bubble the didTransition event
    }

    error(error) {
      if (error == null || error.errors && error.errors[0].status === '401') {
        this.dispatch('loggedOut');
        this.App.setLoading(false);
      } else {
        this.logging.warn(error); // Let the route above this handle the error.

        return true;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "logging", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "App", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return window.App;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "willTransition", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didTransition", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didTransition"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "error", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "error"), _class.prototype)), _class);
  _exports.default = BaseRoute;
});