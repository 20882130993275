define("justmoney-swap/providers/wallets/wconnectbttc", ["exports", "justmoney-swap/providers/wallets/wconnect"], function (_exports, _wconnect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class WconnectbttcProvider extends _wconnect.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "chainId", 199);

      _defineProperty(this, "rpcAddress", 'https://rpc.bt.io');
    }

  }

  _exports.default = WconnectbttcProvider;
});