define("justmoney-swap/controllers/help", ["exports", "justmoney-swap/core/base-controller", "@glimmer/tracking", "@ember/object"], function (_exports, _baseController, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Pools = (_class = class Pools extends _baseController.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", ['t']);

      _initializerDefineProperty(this, "t", _descriptor, this);
    }

    initialize() {
      super.initialize(...arguments);
      window.amplitude.getInstance().logEvent('ROUTE_HELP', {
        host: this.App.widgetOptions ? this.App.widgetOptions.host : window.location.host,
        widget: false,
        network: this.App.NETWORK
      });
    }

    async addToken() {
      if (window.tronLink) {
        await window.tronLink.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'trc20',
            options: {
              address: 'TT8VkSkW6igkiRsV5WiJgLrsbVwY5bLLjA'
            }
          }
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "t", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addToken", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addToken"), _class.prototype)), _class);
  _exports.default = Pools;
});