define("justmoney-swap/helpers/orderedPairSymbol", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.orderedPairSymbol = orderedPairSymbol;

  function orderedPairSymbol(params) {
    if (params[0]) {
      if (params[0].invert) {
        return params[1] == 0 ? params[0].symbolB : params[0].symbolA;
      } else {
        return params[1] == 0 ? params[0].symbolA : params[0].symbolB;
      }
    }

    return null;
  }

  var _default = (0, _helper.helper)(orderedPairSymbol);

  _exports.default = _default;
});