define("justmoney-swap/routes/help", ["exports", "justmoney-swap/core/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class HelpRoute extends _baseRoute.default {
    model(params) {}

  }

  _exports.default = HelpRoute;
});