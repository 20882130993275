define("justmoney-swap/components/header/component", ["exports", "@ember/object", "justmoney-swap/core/base-component", "@ember/service"], function (_exports, _object, _baseComponent, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let HeaderComponent = (_class = class HeaderComponent extends _baseComponent.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notification", _descriptor, this);

      _defineProperty(this, "id", 'header');

      this.addListener('routeDidTransition', () => {
        this.selectActiveMenuItem();
      });
      this.addListener('WALLET_CONNECTED', () => {
        if (this.provider.connected) {
          this.provider.getCurrentProvider().wallet.getBalanceForBase();
        }
      });
    }

    selectActiveMenuItem() {
      let selectedMenuItem = document.querySelector('.subMenuItem.selected');

      if (selectedMenuItem) {
        selectedMenuItem.classList.remove('selected');
      }

      if (window.App.currentRouteName == 'bridge') {
        let mainMenuItem = document.querySelector('.menuItem.selected');

        if (mainMenuItem) {
          mainMenuItem.classList.remove('selected');
        }

        let bridgeMenuItem = document.querySelector('.menuItem[mainroutename=bridge]');

        if (bridgeMenuItem) {
          bridgeMenuItem.classList.add('selected');
        }
      }

      let menuItem = document.querySelector('.subMenuItem[routeName=' + window.App.currentRouteName + ']');

      if (menuItem) {
        menuItem.classList.add('selected');
      }
    }

    connectWallet() {
      this.provider.connect().catch(() => {
        console.error('No wallet found');
        this.notification.error('No wallet found. ' + 'Please ensure you have Tron supported wallet installed and you have logged in. ' + "On mobile use this inside the wallet's browser", {
          duration: 10000
        });
      });
    }

    didInsert() {
      this.selectActiveMenuItem();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notification", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "connectWallet", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "connectWallet"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype)), _class);
  _exports.default = HeaderComponent;
});