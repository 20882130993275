define("justmoney-swap/helpers/formatNumber", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatNumber = formatNumber;

  function formatNumber(params) {
    if (!params[0] && !params !== 0) return '';
    return new Intl.NumberFormat('en-US', {
      maximumSignificantDigits: params[1] || 4
    }).format(params[0]);
  }

  var _default = (0, _helper.helper)(formatNumber);

  _exports.default = _default;
});