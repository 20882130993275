define("justmoney-swap/helpers/shortenedTokenAddress", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.shortenedTokenAddress = shortenedTokenAddress;

  function shortenedTokenAddress(params) {
    if (!params[0]) return '';
    let address = params[0];
    return address.substr(0, 11) + '...' + address.substr(address.length - 9, 97);
  }

  var _default = (0, _helper.helper)(shortenedTokenAddress);

  _exports.default = _default;
});