define("justmoney-swap/helpers/min", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.min = min;

  function min(_ref) {
    let [value1, value2] = _ref;
    return Math.min(value1, value2);
  }

  var _default = (0, _helper.helper)(min);

  _exports.default = _default;
});