define("justmoney-swap/routes/widget", ["exports", "@ember/service", "justmoney-swap/core/base-route", "@ember/object", "justmoney-swap/utils/tools"], function (_exports, _service, _baseRoute, _object, _tools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let WidgetRoute = (_class = class WidgetRoute extends _baseRoute.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "tokenPair", _descriptor, this);

      _defineProperty(this, "queryParams", {
        n: {
          refreshModel: true
        },
        t: {
          refreshModel: true
        },
        from: {
          refreshModel: false
        },
        to: {
          refreshModel: false
        }
      });
    }

    refreshModel() {//this.refresh();
    }

    model(params) {
      return new Promise(async resolve => {
        if (window.App.widgetOptions) {
          if (window.App.widgetOptions.hasOwnProperty("defaultNetwork") && !this.lastNetwork) {
            params.n = window.App.widgetOptions.defaultNetwork;
          }

          if (!params.n) {
            params.n = this.App.NETWORK || 'TRON';
          }

          if (params.n != this.App.NETWORK) {
            this.App.NETWORK = params.n;
          }

          if (!params.from) {
            params.from = this.App.DEFAULT_TOKENS[this.App.NETWORK][0];
          }

          if (!params.to) {
            params.to = this.App.DEFAULT_TOKENS[this.App.NETWORK][1];
          }

          this.provider.getProvider(params.n);

          if (params.t == 'addLiquidity') {
            this.dispatch('LIQUIDITY_TAB_VISIBLE');
          }

          this.tokens = await this.tokenPair.getTokens();
          this.pairs = await this.tokenPair.getPairs();
          resolve({
            from: params.from,
            to: params.to,
            tokens: this.tokens,
            pools: this.pairs,
            n: params.n,
            t: params.t
          });
        } else {
          window.addEventListener("message", async event => {
            if (event.data && event.data.name == 'JMSetOptions') {
              if (event.data.options.hasOwnProperty("defaultNetwork")) {
                params.n = event.data.options.defaultNetwork;
              }

              if (!params.n) {
                params.n = this.App.NETWORK || 'TRON';
              }

              if (params.n != this.App.NETWORK) {
                this.App.NETWORK = params.n;
              }

              if (!params.from) {
                params.from = this.App.DEFAULT_TOKENS[this.App.NETWORK][0];
              }

              if (!params.to) {
                params.to = this.App.DEFAULT_TOKENS[this.App.NETWORK][1];
              }

              this.provider.getProvider(params.n);

              if (params.t == 'addLiquidity') {
                this.dispatch('LIQUIDITY_TAB_VISIBLE');
              }

              this.tokens = await this.tokenPair.getTokens();
              this.pairs = await this.tokenPair.getPairs();
              resolve({
                from: params.from,
                to: params.to,
                tokens: this.tokens,
                pools: this.pairs,
                n: params.n,
                t: params.t
              });
            }
          }, false);
        }
      });
    }

    async setupController(controller, model, transition) {
      super.setupController(controller, model, transition); //controller.n = this.App.NETWORK;

      controller.tokens = model.tokens;

      if (model.from && !this.getTokenBySymbol(model.from)) {
        controller.from = null;
      }

      if (model.to && !this.getTokenBySymbol(model.to)) {
        controller.to = null;
      }

      if (model.from == model.to) {
        controller.from = null;
      }

      this.dispatch('TOKENS_UPDATED', model.tokens);

      if (model.n != this.lastNetwork) {
        this.dispatch('REFRESH_SWAP');
        this.lastNetwork = model.n;
      }
    }

    getTokenBySymbol(symbol) {
      return this.tokens.find(token => token.symbol === symbol);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "tokenPair", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "refreshModel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "refreshModel"), _class.prototype)), _class);
  _exports.default = WidgetRoute;
});