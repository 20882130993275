define("justmoney-swap/templates/bridge", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Z1uH45kg",
    "block": "[[[1,[28,[35,0],[\"JustMoney Bridge\"],null]],[1,\"\\n\"],[8,[39,1],null,null,null],[1,\"\\n\"],[8,[39,2],null,null,[[\"default\"],[[[],[]]]]],[1,\"\\n\"],[10,0],[14,0,\"tab-content\"],[12],[1,\"\\n\\n    \"],[8,[39,3],null,[[\"@token\",\"@tokens\",\"@toNetwork\",\"@fromNetwork\",\"@updateQuery\"],[[30,0,[\"token\"]],[30,0,[\"tokens\"]],[30,0,[\"n2\"]],[30,0,[\"n1\"]],[28,[37,4],[[30,0],[30,0,[\"updateQuery\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"listingText\"],[12],[1,\"\\n    For listing your token, please fill our \"],[6,[39,5],null,[[\"route\"],[\"listing\"]],[[\"default\"],[[[[1,\"listing form\"]],[]]]]],[1,\". For other enquiries, email support@just.money\\n\"],[13]],[],false,[\"page-title\",\"header\",\"news-ticker\",\"bridge-box\",\"action\",\"link-to\"]]",
    "moduleName": "justmoney-swap/templates/bridge.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});