define("justmoney-swap/utils/metamask", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addNetwork = addNetwork;

  async function addNetwork(network) {
    switch (network) {
      case 'POLY':
        try {
          await changeChain(137);
        } catch (error) {
          if (error.code === 4902) {
            try {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                  chainId: `0x${parseInt(137, 10).toString(16)}`,
                  // Hexadecimal version of 137, prefixed with 0x
                  chainName: 'Polygon Mainnet',
                  nativeCurrency: {
                    name: 'MATIC',
                    symbol: 'MATIC',
                    decimals: 18
                  },
                  rpcUrls: ['https://polygon-rpc.com'],
                  blockExplorerUrls: ['https://polygonscan.com/'],
                  iconUrls: ['']
                }]
              });
            } catch (addError) {
              console.log('Did not add network');
            }
          }
        }

        break;

      case 'BSC':
        try {
          await changeChain(56);
        } catch (error) {
          if (error.code === 4902) {
            try {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                  chainId: `0x${parseInt(56, 10).toString(16)}`,
                  // Hexadecimal version of 56, prefixed with 0x
                  chainName: 'Binance Smart Chain',
                  nativeCurrency: {
                    name: 'BNB',
                    symbol: 'BNB',
                    decimals: 18
                  },
                  rpcUrls: ['https://bsc-dataseed.binance.org'],
                  blockExplorerUrls: ['https://bscscan.com/'],
                  iconUrls: ['']
                }]
              });
            } catch (addError) {
              console.log('Did not add network');
            }
          }
        }

        break;

      case 'BTTC':
        try {
          await changeChain(199);
        } catch (error) {
          if (error.code === 4902) {
            try {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                  chainId: `0x${parseInt(199, 10).toString(16)}`,
                  // Hexadecimal version of 56, prefixed with 0x
                  chainName: 'BitTorrent Chain Mainnet',
                  nativeCurrency: {
                    name: 'BTT',
                    symbol: 'BTT',
                    decimals: 18
                  },
                  rpcUrls: ['https://rpc.bt.io/'],
                  blockExplorerUrls: ['https://scan.bt.io/'],
                  iconUrls: ['']
                }]
              });
            } catch (addError) {
              console.log('Did not add network');
            }
          }
        }

        break;

      case 'ZENITH':
        try {
          await changeChain(79);
        } catch (error) {
          if (error.code === 4902) {
            try {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                  chainId: `0x${parseInt(79, 10).toString(16)}`,
                  // Hexadecimal version of 79, prefixed with 0x
                  chainName: 'Zenith Mainnet',
                  nativeCurrency: {
                    name: 'ZENITH',
                    symbol: 'ZENITH',
                    decimals: 18
                  },
                  rpcUrls: ['https://dataserver-1.zenithchain.co'],
                  blockExplorerUrls: ['https://explorer.zenithchain.co/'],
                  iconUrls: ['']
                }]
              });
            } catch (addError) {
              console.log('Did not add network');
            }
          }
        }

        break;

      default:
        break;
    }
  }

  async function changeChain(chainID) {
    return window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{
        chainId: `0x${parseInt(chainID, 10).toString(16)}`
      }] // Hexadecimal version of chain id, prefixed with 0x

    });
  }
});