define("justmoney-swap/templates/application", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "jUPb5Y+F",
    "block": "[[[41,[30,0,[\"App\",\"versionChanged\"]],[[[1,\"    \"],[8,[39,1],null,null,[[\"default\"],[[[],[]]]]],[1,\"\\n\"]],[]],null],[10,0],[14,1,\"mainWrapper\"],[15,0,[29,[[28,[37,2],[\"loading\",[30,0,[\"App\",\"isLoading\"]],true],null]]]],[12],[1,\"\\n    \"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,5],null,null,null],[1,\"\\n\"]],[],false,[\"if\",\"new-version-reloader\",\"returnIfEq\",\"component\",\"-outlet\",\"loading-spinner\"]]",
    "moduleName": "justmoney-swap/templates/application.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});