define("justmoney-swap/providers/wallets/binancewallet", ["exports", "justmoney-swap/providers/wallets/metamask"], function (_exports, _metamask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class BinancewalletProvider extends _metamask.default {
    constructor(network, owner) {
      super(network, owner);

      _defineProperty(this, "walletName", 'BinanceWallet');
    }

    async resolve() {
      return new Promise(resolve => {
        if (this.web3) return resolve(this.web3);

        async function run() {
          if (window.BinanceChain) {
            this.accounts = await window.BinanceChain.request({
              method: 'eth_requestAccounts'
            });
            this.web3 = new Web3(window.BinanceChain);
            return resolve(this.web3);
          } else {
            setTimeout(run, this.POLLING_INTERVAL);
          }
        }

        run.call(this);
      });
    }

  }

  _exports.default = BinancewalletProvider;
});