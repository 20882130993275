define("justmoney-swap/instance-initializers/global", ["exports", "buffer", "justmoney-swap/utils/tools"], function (_exports, _buffer, _tools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function initialize(application) {
    window.App = application.application;
    window.App.staging = window.document.location.hostname.indexOf('vercel.app') > -1 || window.document.location.hostname.indexOf('backup.j') > -1 || window.document.location.hostname.indexOf('ngrok') > -1 || window.document.location.hostname == 'localhost' ? true : false;

    try {
      window.App.NETWORK = (0, _tools.getCookie)('SELECTED_NETWORK');

      if (!window.App.NETWORK) {
        window.App.NETWORK = 'TRON';
        (0, _tools.setCookie)('SELECTED_NETWORK', 'TRON');
      }

      window.App.NETWORKNAME = window.App.JM.NETWORKS[window.App.NETWORK].NAME;
    } catch (e) {
      window.App.NETWORK = 'TRON';
      window.App.NETWORKNAME = window.App.JM.NETWORKS[window.App.NETWORK].NAME;
    }

    if ((0, _tools.isMobile)()) {
      document.body.classList.add('mobile');
    }
    /*let hostArr = window.document.location.host.split('.');
    let protocol = window.document.location.hostname.indexOf('mundomain.com') > -1 ? 'http://' : 'https://';
    let url;
    if (hostArr.length == 2) {
        url = protocol + window.App.NETWORK.toLowerCase() + '.' + hostArr[0] + '.' + hostArr[1] + window.document.location.pathname;
        window.document.location.replace(url);
    }*/


    window.Buffer = _buffer.Buffer;
    window.App.isLoading = false;
    window.App.loadingText = null;
    window.App.currentRouteName = null;
    window.App.isVisible = true;
    window.App.versionChanged = false;
    window.globalIntervals = [];

    if (window.document.location.pathname && (window.document.location.pathname.indexOf('/widget') > -1 || window.document.location.pathname.indexOf('/ccwidget') > -1)) {
      if (window.document.body) {
        window.document.body.style.background = 'none';
      }
    }

    window.document.addEventListener('visibilitychange', function (event) {
      if (document.hidden) {
        window.App.isVisible = false;
      } else {
        window.App.isVisible = true;
      }
    });
    window.addEventListener('message', event => {
      if (event.data && event.data.name == 'JMSetOptions') {
        if (Array.isArray(event.data.options.network)) {
          window.App.NETWORK = event.data.options.network[0] || 'TRON';
        } else {
          window.App.NETWORK = event.data.options.network || 'TRON';
        }

        window.App.NETWORKNAME = window.App.JM.NETWORKS[window.App.NETWORK].NAME;
        window.App.widgetOptions = event.data.options;
        document.body.classList.add('isWidget');
      }
    }, false);
    window.parent.postMessage({
      name: 'JMSwapLoading'
    }, '*');
    addAmplitude();

    window.App.setLoading = (onOff, loadingText) => {
      if (!onOff) {
        window.App.set('loadingText', null);
      } else if (loadingText && loadingText.length > 0) {
        window.App.set('loadingText', loadingText);
      } else {
        window.App.set('loadingText', null);
      }

      window.App.set('isLoading', onOff);
    };

    fetch('/version.json').then(response => response.json()).then(data => {
      if (data && data.ui) {
        window.App.version = data.ui;
      }
    });
    setInterval(() => {
      fetch('/version.json').then(response => response.json()).then(data => {
        if (data && data.ui) {
          if (window.App.version != data.ui) {
            window.App.set('versionChanged', true);
          }
        }
      });
    }, 300000);
  }

  function addAmplitude() {
    (function (e, t) {
      var n = e.amplitude || {
        _q: [],
        _iq: {}
      };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity = "sha384-tzcaaCH5+KXD4sGaDozev6oElQhsVfbJvdi3//c2YvbY02LrNlbpGdt3Wq4rWonS";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-8.5.0-min.gz.js";

      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
          console.log("[Amplitude] Error: could not load SDK");
        }
      };

      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);

      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));

          return this;
        };
      }

      var o = function () {
        this._q = [];
        return this;
      };

      var a = ["add", "append", "clearAll", "prepend", "set", "setOnce", "unset", "preInsert", "postInsert", "remove"];

      for (var c = 0; c < a.length; c++) {
        s(o, a[c]);
      }

      n.Identify = o;

      var u = function () {
        this._q = [];
        return this;
      };

      var l = ["setProductId", "setQuantity", "setPrice", "setRevenueType", "setEventProperties"];

      for (var p = 0; p < l.length; p++) {
        s(u, l[p]);
      }

      n.Revenue = u;
      var d = ["init", "logEvent", "logRevenue", "setUserId", "setUserProperties", "setOptOut", "setVersionName", "setDomain", "setDeviceId", "enableTracking", "setGlobalUserProperties", "identify", "clearUserProperties", "setGroup", "logRevenueV2", "regenerateDeviceId", "groupIdentify", "onInit", "logEventWithTimestamp", "logEventWithGroups", "setSessionId", "resetSessionId"];

      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }

        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }

      v(n);

      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();

        if (!Object.prototype.hasOwnProperty.call(n._iq, e)) {
          n._iq[e] = {
            _q: []
          };
          v(n._iq[e]);
        }

        return n._iq[e];
      };

      e.amplitude = n;
    })(window, document);

    amplitude.getInstance().init("2503d4d3cf98ed8b6daf7aec8aa9f126");
  }

  var _default = {
    name: 'global',
    initialize: initialize
  };
  _exports.default = _default;
});