define("justmoney-swap/services/just-money-exchange", ["exports", "@ember/service", "justmoney-swap/providers/wallets/tron-link", "ethers/lib/utils", "justmoney-swap/utils/tools", "justmoney-swap/core/base-service"], function (_exports, _service, _tronLink, _utils, _tools, _baseService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let JustMoneyExchangeService = (_class = class JustMoneyExchangeService extends _baseService.default {
    // 0.3%
    // 5%
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "provider", _descriptor, this);

      _initializerDefineProperty(this, "tokenPair", _descriptor2, this);

      _initializerDefineProperty(this, "justMoneyLiquidity", _descriptor3, this);

      _initializerDefineProperty(this, "logging", _descriptor4, this);

      _defineProperty(this, "LIQUIDITY_PROVIDER_FEE", 0.003);

      _defineProperty(this, "SLIPPAGE_FEE", 0.05);

      _defineProperty(this, "CONTRACT_METHODS", {
        SWAP_EXACT_BASE_FOR_TOKENS: 'swapExactBaseForTokens(uint256,address[],bytes32[],address,uint256)',
        SWAP_EXACT_TOKENS_FOR_BASE: 'swapExactTokensForBase(uint256,uint256,address[],bytes32[],address,uint256)',
        SWAP_EXACT_TOKENS_FOR_TOKENS: 'swapExactTokensForTokens(uint256,uint256,address[],bytes32[],address,uint256)',
        SWAP_BASE_FOR_EXACT_TOKENS: 'swapBaseForExactTokens(uint256,address[],bytes32[],address,uint256)',
        SWAP_TOKENS_FOR_EXACT_BASE: 'swapTokensForExactBase(uint256,uint256,address[],bytes32[],address,uint256)',
        SWAP_TOKENS_FOR_EXACT_TOKENS: 'swapTokensForExactTokens(uint256,uint256,address[],bytes32[],address,uint256)'
      });

      if (this.App.widgetOptions && // eslint-disable-next-line no-prototype-builtins
      this.App.widgetOptions.hasOwnProperty('slippage')) {
        this.SLIPPAGE_FEE = this.App.widgetOptions.slippage;
      }
    }
    /**
     * Get estimate from token to token swap
     * @param {number} amount
     * @param {Object} fromToken
     * @param {Object} toToken
     * @returns {number} estimate}
     */


    async getEstimate(amount, fromToken, toToken, skipTax, network) {
      const pairwiseTokenPaths = await this.tokenPair.getPairwiseTokenPathsFromTo(fromToken, toToken, network || this.App.NETWORK);
      let optimalPath,
          optimalCalculation,
          optimalEstimate = 0;

      for (const [path, pairs] of pairwiseTokenPaths) {
        let calculation,
            estimate = amount,
            insufficientLiquidityPath = false;

        for (const [from, to] of pairs) {
          const {
            [from.address.toUpperCase()]: balanceFrom,
            [to.address.toUpperCase()]: balanceTo
          } = await this.justMoneyLiquidity.getCachedBalance(from, to, network);

          if (balanceFrom === 0 || balanceTo === 0) {
            insufficientLiquidityPath = true;
            break;
          }

          calculation = await this.calculateEstimate(await this.toBigNumberRounded(estimate, from), balanceFrom, balanceTo, false, skipTax ? 0 : from.tax, skipTax ? 0 : to.tax).then(estimate => Promise.resolve(estimate)).catch(() => Promise.resolve(false));

          if (!calculation) {
            insufficientLiquidityPath = true;
          } else {
            const [calculatedValue] = calculation;
            estimate = this.fromBigNumber(calculatedValue, to);
          }
        }

        if (!insufficientLiquidityPath && estimate > optimalEstimate) {
          optimalCalculation = calculation;
          optimalEstimate = estimate;
          optimalPath = path;
        }
      }

      if (!this.isIterable(optimalCalculation)) {
        this.logging.log('No swap path found', fromToken.symbol, toToken.symbol);
        throw new Error('No swap path found');
      }

      const [estimate, priceImpact] = optimalCalculation;
      return [this.fromBigNumber(estimate, toToken), priceImpact, this.fromBigNumber(estimate, toToken) * (1 - this.SLIPPAGE_FEE), amount * this.LIQUIDITY_PROVIDER_FEE * (optimalPath.length - 1), optimalPath];
    }
    /**
     * Get estimate from token to token swap between networks
     * @param {number} amount
     * @param {Object} fromNetwork
     * @param {Object} toNetwork
     * @param {Object} fromToken
     * @param {Object} toToken
     * @returns {number} estimate}
     */


    async getCrossEstimate(amount, fromNetwork, toNetwork, fromToken, toToken) {
      let fromNetworkEstimate = [amount, 0, amount, 0, [(0, _tools.getTokenBySymbol)('JM', fromNetwork.code), (0, _tools.getTokenBySymbol)('JM', fromNetwork.code)]];

      if (fromToken != (0, _tools.getTokenBySymbol)('JM', fromNetwork.code)) {
        fromNetworkEstimate = await this.getEstimate(amount, fromToken, (0, _tools.getTokenBySymbol)('JM', fromNetwork.code), false, fromNetwork.code);
      }

      let toNetworkEstimate = [fromNetworkEstimate[0], 0, fromNetworkEstimate[0], 0, [(0, _tools.getTokenBySymbol)('JM', toNetwork.code), (0, _tools.getTokenBySymbol)('JM', toNetwork.code)]];

      if (toToken != (0, _tools.getTokenBySymbol)('JM', toNetwork.code)) {
        toNetworkEstimate = await this.getEstimate(fromNetworkEstimate[0], (0, _tools.getTokenBySymbol)('JM', toNetwork.code), toToken, false, toNetwork.code);
      }

      return [fromNetworkEstimate, toNetworkEstimate];
    }

    async getPrice(fromToken, toToken) {
      const {
        [fromToken.address.toUpperCase()]: balanceFrom,
        [toToken.address.toUpperCase()]: balanceTo
      } = await this.justMoneyLiquidity.getCachedBalance(fromToken, toToken);
      return this.fromBigNumber(balanceTo, toToken) / this.fromBigNumber(balanceFrom, fromToken);
    }

    getAmountIn(amount, fromBalance, toBalance) {
      if (amount <= 0) {
        return false;
      }

      if (fromBalance <= 0 && toBalance <= 0) {
        return false;
      }

      let numerator = fromBalance * amount * 1000;
      let denominator = (toBalance - amount) * 997;
      let amountIn = numerator / denominator + 1;
      return amountIn;
    }

    getAmountOut(amount, fromBalance, toBalance) {
      if (amount <= 0) {
        return false;
      }

      if (fromBalance <= 0 && toBalance <= 0) {
        return false;
      }

      let amountInWithFee = amount * 997;
      let numerator = amountInWithFee * toBalance;
      let denominator = fromBalance * 1000 + amountInWithFee;
      let amountOut = numerator / denominator;
      return amountOut;
    }
    /**
     * Calculate estimate from LP balances with taxes included
     * @param {number} amount
     * @param {number} fromBalance
     * @param {number} toBalance
     * @param {boolean} reverse
     * @param {number} fromTax
     * @param {number} toTax
     * @returns {{amount: (boolean|number), priceImpact: number}|boolean}
     */


    async calculateEstimate(amount, fromBalance, toBalance, reverse, fromTax, toTax) {
      let amountOutput;

      if (reverse) {
        amount = toTax > 0 ? 10000 / (100 - toTax) * amount / 100 + 1 : amount;
        amountOutput = this.getAmountIn(amount, fromBalance, toBalance);
        if (amountOutput === false) return false;

        if (fromTax > 0) {
          amountOutput = 10000 / (100 - fromTax) * amountOutput / 100 + 1;
        }
      } else {
        amount = fromTax > 0 ? amount * ((100 - fromTax) / 100) : amount;
        amountOutput = this.getAmountOut(amount, fromBalance, toBalance);
        if (amountOutput === false) return false;

        if (toTax > 0) {
          amountOutput = amountOutput * ((100 - toTax) / 100);
        }
      }

      let impact = reverse ? (fromBalance - (fromBalance - amountOutput)) * 100 / fromBalance : (toBalance - (toBalance - amountOutput)) * 100 / toBalance;

      if (amountOutput < 0) {
        throw new Error('Insufficient liquidity available');
      }

      return Promise.resolve([amountOutput, impact]);
    }

    isIterable(obj) {
      // checks for null and undefined
      if (obj == null) {
        return false;
      }

      return typeof obj[Symbol.iterator] === 'function';
    }
    /**
     * Get amount from token to token swap
     * @param {number} estimate
     * @param {Object} fromToken
     * @param {Object} toToken
     * @returns {number} amount}
     */


    async getAmount(estimate, fromToken, toToken, skipTax) {
      const pairwiseTokenPaths = await this.tokenPair.getPairwiseTokenPathsFromTo(fromToken, toToken);
      let optimalPath,
          optimalCalculation,
          optimalAmount = Number.MAX_SAFE_INTEGER;

      for (const [path, pairs] of pairwiseTokenPaths) {
        let calculation,
            amount = estimate,
            insufficientLiquidityPath = false;

        for (const [from, to] of pairs.slice().reverse()) {
          const {
            [from.address.toUpperCase()]: balanceFrom,
            [to.address.toUpperCase()]: balanceTo
          } = await this.justMoneyLiquidity.getCachedBalance(from, to);

          if (balanceFrom === 0 || balanceTo === 0) {
            insufficientLiquidityPath = true;
            break;
          }

          calculation = await this.calculateEstimate(await this.toBigNumber(amount, to), balanceFrom, balanceTo, true, skipTax ? 0 : from.tax, skipTax ? 0 : to.tax).then(estimate => Promise.resolve(estimate)).catch(() => Promise.resolve(false));

          if (!calculation) {
            insufficientLiquidityPath = true;
          } else {
            const [calculatedValue] = calculation;
            amount = this.fromBigNumber(calculatedValue, from);
          }
        }

        if (!insufficientLiquidityPath && amount < optimalAmount) {
          optimalCalculation = calculation;
          optimalAmount = amount;
          optimalPath = path;
        }
      }

      if (!this.isIterable(optimalCalculation)) {
        this.logging.log('No swap path found', fromToken.symbol, toToken.symbol);
        throw new Error('No swap path found');
      }

      const [amount, priceImpact] = optimalCalculation;
      return [this.fromBigNumber(amount, fromToken), priceImpact, this.fromBigNumber(amount, fromToken) * (1 + this.SLIPPAGE_FEE), this.fromBigNumber(amount, fromToken) * this.LIQUIDITY_PROVIDER_FEE * (optimalPath.length - 1), optimalPath];
    }

    async approve(token) {
      return this.currentProvider().wallet.approve(token);
    }
    /**
     * Swap the exact amount
     * @param {number} amount
     * @param {Object} fromToken
     * @param {number} estimate
     * @param {Object} toToken
     * @param {Array} optimalPath
     * @returns {Promise<*>}
     */


    async swapExactAmount(amount, fromToken, estimate, toToken, optimalPath) {
      this.logging.log('swapExactAmount');

      if (this.isBaseToken(fromToken)) {
        return this.swapExactBaseForTokens(amount, fromToken, estimate, toToken, optimalPath);
      } else if (this.isBaseToken(toToken)) {
        return this.swapExactTokensForBase(amount, fromToken, estimate, toToken, optimalPath);
      } else {
        return this.swapExactTokensForTokens(amount, fromToken, estimate, toToken, optimalPath);
      }
    }

    async swapExactBaseForTokens(amount, fromToken, estimate, toToken, optimalPath) {
      this.logging.log('swapExactBaseForTokens');
      const deadline = (0, _tools.getDeadline5MinutesFromNow)();
      const options = {
        value: (0, _tools.toWei)(amount.toString(), fromToken.decimals).toString()
      };
      const parameters = [{
        type: _tronLink.default.PARAMETERS.UINT256,
        value: (0, _tools.toWei)((Number(estimate) * (1 - this.SLIPPAGE_FEE)).toString(), toToken.decimals).toString()
      }, {
        type: _tronLink.default.PARAMETERS.ADDRESS_ARRAY,
        value: optimalPath.map(token => token.address)
      }, {
        type: _tronLink.default.PARAMETERS.BYTES32_ARRAY,
        // eslint-disable-next-line no-undef
        value: optimalPath.map(token => (0, _utils.hexZeroPad)( // eslint-disable-next-line no-undef
        Web3.utils.utf8ToHex((token.tax * 10 ** 18).toString() || 0), 32))
      }, {
        type: _tronLink.default.PARAMETERS.ADDRESS,
        value: await this.currentProvider().wallet.getAddress()
      }, {
        type: _tronLink.default.PARAMETERS.UINT256,
        value: deadline
      }];
      this.logging.log(parameters);
      const transaction = await this.currentProvider().wallet.send(this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.ROUTER_ADDRESS, this.CONTRACT_METHODS.SWAP_EXACT_BASE_FOR_TOKENS, options, parameters);
      return transaction;
    }

    async swapExactTokensForBase(amount, fromToken, estimate, toToken, optimalPath) {
      this.logging.log('swapExactTokensForBase');
      const deadline = (0, _tools.getDeadline5MinutesFromNow)();
      const options = {};
      const parameters = [{
        type: _tronLink.default.PARAMETERS.UINT256,
        value: (0, _tools.toWei)(amount.toString(), fromToken.decimals).toString()
      }, {
        type: _tronLink.default.PARAMETERS.UINT256,
        value: (0, _tools.toWei)((Number(estimate) * (1 - this.SLIPPAGE_FEE)).toString(), toToken.decimals).toString()
      }, {
        type: _tronLink.default.PARAMETERS.ADDRESS_ARRAY,
        value: optimalPath.map(token => token.address)
      }, {
        type: _tronLink.default.PARAMETERS.BYTES32_ARRAY,
        value: optimalPath.map(token => (0, _utils.hexZeroPad)( // eslint-disable-next-line no-undef
        Web3.utils.utf8ToHex((token.tax * 10 ** 18).toString() || 0), 32))
      }, {
        type: _tronLink.default.PARAMETERS.ADDRESS,
        value: await this.currentProvider().wallet.getAddress()
      }, {
        type: _tronLink.default.PARAMETERS.UINT256,
        value: deadline
      }];
      this.logging.log(parameters);
      const transaction = await this.currentProvider().wallet.send(this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.ROUTER_ADDRESS, this.CONTRACT_METHODS.SWAP_EXACT_TOKENS_FOR_BASE, options, parameters);
      return transaction;
    }

    async swapExactTokensForTokens(amount, fromToken, estimate, toToken, optimalPath) {
      this.logging.log('swapExactTokensForTokens');
      const deadline = (0, _tools.getDeadline5MinutesFromNow)();
      const options = {};
      const parameters = [{
        type: _tronLink.default.PARAMETERS.UINT256,
        value: (0, _tools.toWei)(amount.toString(), fromToken.decimals).toString()
      }, {
        type: _tronLink.default.PARAMETERS.UINT256,
        value: (0, _tools.toWei)((Number(estimate) * (1 - this.SLIPPAGE_FEE)).toString(), toToken.decimals).toString()
      }, {
        type: _tronLink.default.PARAMETERS.ADDRESS_ARRAY,
        value: optimalPath.map(token => token.address)
      }, {
        type: _tronLink.default.PARAMETERS.BYTES32_ARRAY,
        value: optimalPath.map(token => (0, _utils.hexZeroPad)( // eslint-disable-next-line no-undef
        Web3.utils.utf8ToHex((token.tax * 10 ** 18).toString() || 0), 32))
      }, {
        type: _tronLink.default.PARAMETERS.ADDRESS,
        value: await this.currentProvider().wallet.getAddress()
      }, {
        type: _tronLink.default.PARAMETERS.UINT256,
        value: deadline
      }];
      this.logging.log(parameters);
      const transaction = await this.currentProvider().wallet.send(this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.ROUTER_ADDRESS, this.CONTRACT_METHODS.SWAP_EXACT_TOKENS_FOR_TOKENS, options, parameters);
      return transaction;
    }

    async swapExactEstimate(amount, fromToken, estimate, toToken, optimalPath) {
      this.logging.log('swapExactEstimate');

      if (this.isBaseToken(fromToken)) {
        return this.swapBaseForExactTokens(amount, fromToken, estimate, toToken, optimalPath);
      } else if (this.isBaseToken(toToken)) {
        return this.swapTokensForExactBase(amount, fromToken, estimate, toToken, optimalPath);
      } else {
        return this.swapTokensForExactTokens(amount, fromToken, estimate, toToken, optimalPath);
      }
    }

    async swapBaseForExactTokens(amount, fromToken, estimate, toToken, optimalPath) {
      this.logging.log('swapBaseForExactTokens');
      const deadline = (0, _tools.getDeadline5MinutesFromNow)();
      const options = {
        value: (0, _tools.toWei)((amount * (1 + this.SLIPPAGE_FEE)).toString(), fromToken.decimals).toString()
      };
      const parameters = [{
        type: _tronLink.default.PARAMETERS.UINT256,
        value: (0, _tools.toWei)(estimate.toString(), toToken.decimals).toString()
      }, {
        type: _tronLink.default.PARAMETERS.ADDRESS_ARRAY,
        value: optimalPath.map(token => token.address)
      }, {
        type: _tronLink.default.PARAMETERS.BYTES32_ARRAY,
        value: optimalPath.map(token => (0, _utils.hexZeroPad)( // eslint-disable-next-line no-undef
        Web3.utils.utf8ToHex((token.tax * 10 ** 18).toString() || 0), 32))
      }, {
        type: _tronLink.default.PARAMETERS.ADDRESS,
        value: await this.currentProvider().wallet.getAddress()
      }, {
        type: _tronLink.default.PARAMETERS.UINT256,
        value: deadline
      }];
      this.logging.log(parameters);
      const transaction = await this.currentProvider().wallet.send(this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.ROUTER_ADDRESS, this.CONTRACT_METHODS.SWAP_BASE_FOR_EXACT_TOKENS, options, parameters);
      return transaction;
    }

    async swapTokensForExactBase(amount, fromToken, estimate, toToken, optimalPath) {
      this.logging.log('swapTokensForExactBase');
      const deadline = (0, _tools.getDeadline5MinutesFromNow)();
      const options = {};
      const parameters = [{
        type: _tronLink.default.PARAMETERS.UINT256,
        value: (0, _tools.toWei)(estimate.toString(), toToken.decimals).toString()
      }, {
        type: _tronLink.default.PARAMETERS.UINT256,
        value: (0, _tools.toWei)((Number(amount) * (1 + this.SLIPPAGE_FEE)).toString(), fromToken.decimals).toString()
      }, {
        type: _tronLink.default.PARAMETERS.ADDRESS_ARRAY,
        value: optimalPath.map(token => token.address)
      }, {
        type: _tronLink.default.PARAMETERS.BYTES32_ARRAY,
        value: optimalPath.map(token => (0, _utils.hexZeroPad)( // eslint-disable-next-line no-undef
        Web3.utils.utf8ToHex((token.tax * 10 ** 18).toString() || 0), 32))
      }, {
        type: _tronLink.default.PARAMETERS.ADDRESS,
        value: await this.currentProvider().wallet.getAddress()
      }, {
        type: _tronLink.default.PARAMETERS.UINT256,
        value: deadline
      }];
      this.logging.log(parameters);
      const transaction = await this.currentProvider().wallet.send(this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.ROUTER_ADDRESS, this.CONTRACT_METHODS.SWAP_TOKENS_FOR_EXACT_BASE, options, parameters);
      return transaction;
    }

    async swapTokensForExactTokens(amount, fromToken, estimate, toToken, optimalPath) {
      this.logging.log('swapTokensForExactTokens');
      const deadline = (0, _tools.getDeadline5MinutesFromNow)();
      const options = {};
      const parameters = [{
        type: _tronLink.default.PARAMETERS.UINT256,
        value: (0, _tools.toWei)(estimate.toString(), toToken.decimals).toString()
      }, {
        type: _tronLink.default.PARAMETERS.UINT256,
        value: (0, _tools.toWei)(Number(amount) * (1 + this.SLIPPAGE_FEE).toString(), fromToken.decimals).toString()
      }, {
        type: _tronLink.default.PARAMETERS.ADDRESS_ARRAY,
        value: optimalPath.map(token => token.address)
      }, {
        type: _tronLink.default.PARAMETERS.BYTES32_ARRAY,
        value: optimalPath.map(token => (0, _utils.hexZeroPad)( // eslint-disable-next-line no-undef
        Web3.utils.utf8ToHex((token.tax * 10 ** 18).toString() || 0), 32))
      }, {
        type: _tronLink.default.PARAMETERS.ADDRESS,
        value: await this.currentProvider().wallet.getAddress()
      }, {
        type: _tronLink.default.PARAMETERS.UINT256,
        value: deadline
      }];
      this.logging.log(parameters);
      const transaction = await this.currentProvider().wallet.send(this.App.JM.NETWORKS[this.App.NETWORK].EXCHANGE.ROUTER_ADDRESS, this.CONTRACT_METHODS.SWAP_TOKENS_FOR_EXACT_TOKENS, options, parameters);
      return transaction;
    }

    async getAllowance(fromToken) {
      return this.currentProvider().wallet.getAllowance(fromToken);
    }

    fromBigNumber(amount, token) {
      return amount / Math.pow(10, token.decimals);
    }

    async toBigNumber(amount, token) {
      return (0, _tools.toBigNumber)(Math.floor(amount * Math.pow(10, token.decimals)));
    }

    async toBigNumberRounded(amount, token) {
      return (0, _tools.toBigNumber)(Math.round(amount * Math.pow(10, token.decimals)));
    }

    isBaseToken(token) {
      let conf = this.App.JM.NETWORKS[this.App.NETWORK];
      return token.address == conf.TOKENS[conf.BASE_TOKEN].address;
    }

    currentProvider() {
      return this.provider.getProvider(this.App.NETWORK);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "tokenPair", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "justMoneyLiquidity", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "logging", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = JustMoneyExchangeService;
});