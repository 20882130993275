define("justmoney-swap/components/user-lp-pool-item/component", ["exports", "@ember/object", "@ember/service", "justmoney-swap/core/base-component", "@glimmer/tracking"], function (_exports, _object, _service, _baseComponent, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LpPoolItemComponent = (_class = class LpPoolItemComponent extends _baseComponent.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "justMoneyLiquidity", _descriptor, this);

      _initializerDefineProperty(this, "tokenA", _descriptor2, this);

      _initializerDefineProperty(this, "tokenB", _descriptor3, this);

      _initializerDefineProperty(this, "balanceA", _descriptor4, this);

      _initializerDefineProperty(this, "balanceB", _descriptor5, this);

      _initializerDefineProperty(this, "pairData", _descriptor6, this);
    }

    async didInsert() {
      let tokenA, tokenB;
      this.pairData = this.args.pairData;
      let address = this.pairData.address;

      if (this.App.NETWORK == 'TRON') {
        address = (await this.provider.getProvider(this.App.NETWORK).api.resolve()).address.fromHex(address);
      }

      let pool = this.App.JM.NETWORKS[this.App.NETWORK].POOLS_BY_ADDRESS[address.toUpperCase()];

      if (pool) {
        tokenA = this.getTokenObject(pool.aA);
        tokenB = this.getTokenObject(pool.bA);
      } else {
        try {
          tokenA = this.App.JM.NETWORKS[this.App.NETWORK].TOKENS[this.pairData.symbolA];
          tokenB = this.App.JM.NETWORKS[this.App.NETWORK].TOKENS[this.pairData.symbolB];
        } catch (e) {}
      }

      if (!tokenA) {
        tokenA = {
          symbol: this.pairData.symbolA == 'JM' ? 'OLDJM' : this.pairData.symbolA
        };

        if (this.pairData.symbolA == 'JM') {
          this.pairData.symbolA = 'OLDJM';
        }
      }

      if (!tokenB) {
        tokenB = {
          symbol: this.pairData.symbolB == 'JM' ? 'OLDJM' : this.pairData.symbolB
        };

        if (this.pairData.symbolB == 'JM') {
          this.pairData.symbolB = 'OLDJM';
        }
      }

      this.tokenA = tokenA;
      this.tokenB = tokenB;
    }

    getTokenObject(address) {
      return this.App.JM.NETWORKS[this.App.NETWORK].TOKENS_BY_ADDRESS[address.toUpperCase()];
    }

    addLiquidityClicked() {}

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "justMoneyLiquidity", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "tokenA", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tokenB", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "balanceA", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "balanceB", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "pairData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addLiquidityClicked", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addLiquidityClicked"), _class.prototype)), _class);
  _exports.default = LpPoolItemComponent;
});