define("justmoney-swap/utils/tools", ["exports", "bignumber.js", "big-integer", "tron-format-address"], function (_exports, _bignumber, _bigInteger, _tronFormatAddress) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findPairBySymbols = findPairBySymbols;
  _exports.fromWei = fromWei;
  _exports.getCookie = getCookie;
  _exports.getDeadline5MinutesFromNow = getDeadline5MinutesFromNow;
  _exports.getTokenBySymbol = getTokenBySymbol;
  _exports.isInverted = isInverted;
  _exports.isMobile = isMobile;
  _exports.isPoolPairExists = isPoolPairExists;
  _exports.mapPairData = mapPairData;
  _exports.mapPairDataProperty = mapPairDataProperty;
  _exports.mapTokenData = mapTokenData;
  _exports.mapTokenDataProperty = mapTokenDataProperty;
  _exports.mapTokenDataPropertyInverse = mapTokenDataPropertyInverse;
  _exports.setCookie = setCookie;
  _exports.toBigNumber = toBigNumber;
  _exports.toWei = toWei;

  function setCookie(name, network, currentDomain) {
    var myDate = new Date();
    myDate.setMonth(myDate.getMonth() + 12);
    var hostNameArr = window.location.hostname.split('.');

    if (currentDomain) {
      document.cookie = name + "=" + network + ";expires=" + myDate + ";path=/";
    } else {
      document.cookie = name + "=" + network + ";expires=" + myDate + ";domain=." + hostNameArr[hostNameArr.length - 2] + '.' + hostNameArr[hostNameArr.length - 1] + ";path=/";
    }
  }

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];

      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }

      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }

    return "";
  }

  function isPoolPairExists(pairA, pairB) {
    if (!window.App.JM.NETWORKS[window.App.NETWORK].POOLMAP) {
      let pools = window.App.JM.NETWORKS[window.App.NETWORK].POOLS;
      let poolsMap = {};

      for (let i = 0; i < pools.length; i++) {
        const pair = pools[i];
        if (!poolsMap[pair.aA.toUpperCase()]) poolsMap[pair.aA.toUpperCase()] = {};
        if (!poolsMap[pair.bA.toUpperCase()]) poolsMap[pair.bA.toUpperCase()] = {};
        poolsMap[pair.bA.toUpperCase()][pair.aA.toUpperCase()] = true;
        poolsMap[pair.aA.toUpperCase()][pair.bA.toUpperCase()] = true;
      }

      window.App.JM.NETWORKS[window.App.NETWORK].POOLMAP = poolsMap;
    }

    let liquidityPoolMap = window.App.JM.NETWORKS[window.App.NETWORK].POOLMAP;
    if (!pairA || liquidityPoolMap[pairA.address.toUpperCase()] === undefined) return false;
    return liquidityPoolMap[pairA.address.toUpperCase()][pairB.address.toUpperCase()] === true;
  }

  function findPairBySymbols(symbolA, symbolB) {
    for (let i = 0; i < window.App.JM.NETWORKS[window.App.NETWORK].POOLS.length; i++) {
      let pool = window.App.JM.NETWORKS[window.App.NETWORK].POOLS[i];

      if (pool.symbolA.toUpperCase() == symbolA.toUpperCase() && pool.symbolB.toUpperCase() == symbolB.toUpperCase() || pool.symbolB.toUpperCase() == symbolA.toUpperCase() && pool.symbolA.toUpperCase() == symbolB.toUpperCase()) {
        return pool;
      }
    }

    return false;
  }

  function getTokenBySymbol(symbol, network) {
    return window.App.JM.NETWORKS[network || window.App.NETWORK].TOKENS[symbol];
  }

  function isInverted(symbolA, symbolB) {
    for (let i = 0; i < window.App.JM.NETWORKS[window.App.NETWORK].POOLS.length; i++) {
      let pool = window.App.JM.NETWORKS[window.App.NETWORK].POOLS[i];

      if (pool.symbolA == symbolA && pool.symbolB == symbolB) {
        return false;
      } else if (pool.symbolB == symbolA && pool.symbolA == symbolB) {
        return true;
      }
    }

    return false;
  }

  function getDeadline5MinutesFromNow() {
    return Math.floor(new Date().getTime() / 1000) + 300;
  }

  function toBigNumber(number) {
    return Web3.utils.toBN((0, _bigInteger.default)(number).toString(10));
  }

  function fromWei(number, decimals) {
    const zero = new Web3.utils.BN(0);
    const negative1 = new Web3.utils.BN(-1);
    var wei = new Web3.utils.BN(number.toString()); // eslint-disable-line

    var negative = wei.lt(zero); // eslint-disable-line

    const base = new Web3.utils.BN(Math.pow(10, decimals).toString(), 10);
    const baseLength = decimals || 1;

    if (negative) {
      wei = wei.mul(negative1);
    }

    var fraction = wei.mod(base).toString(10); // eslint-disable-line

    while (fraction.length < baseLength) {
      fraction = `0${fraction}`;
    }

    fraction = fraction.match(/^([0-9]*[1-9]|0)(0*)/)[1];
    var whole = wei.div(base).toString(10); // eslint-disable-line

    var value = `${whole}${fraction == '0' ? '' : `.${fraction}`}`; // eslint-disable-line

    if (negative) {
      value = `-${value}`;
    }

    return value;
  }

  function toWei(etherInput, decimals) {
    const negative1 = new Web3.utils.BN(-1);
    var ether = (0, _bignumber.BigNumber)(etherInput).toFixed(); // eslint-disable-line

    const base = new Web3.utils.BN(Math.pow(10, decimals).toString(), 10);
    const baseLength = decimals || 1; // Is it negative?

    var negative = ether.substring(0, 1) === '-'; // eslint-disable-line

    if (negative) {
      ether = ether.substring(1);
    }

    if (ether === '.') {
      throw new Error(`[ethjs-unit] while converting number ${etherInput} to wei, invalid value`);
    } // Split it into a whole and fractional part


    var comps = ether.split('.'); // eslint-disable-line

    if (comps.length > 2) {
      throw new Error(`[ethjs-unit] while converting number ${etherInput} to wei,  too many decimal points`);
    }

    var whole = comps[0],
        fraction = comps[1]; // eslint-disable-line

    if (!whole) {
      whole = '0';
    }

    if (!fraction) {
      fraction = '0';
    }

    if (fraction.length > baseLength) {
      fraction = fraction.substring(0, fraction.length - (fraction.length - baseLength));
    }

    while (fraction.length < baseLength) {
      fraction += '0';
    }

    whole = new Web3.utils.BN(whole);
    fraction = new Web3.utils.BN(fraction);
    var wei = whole.mul(base).add(fraction); // eslint-disable-line

    if (negative) {
      wei = wei.mul(negative1);
    }

    return new Web3.utils.BN(wei.toString(10), 10);
  }

  function mapTokenDataPropertyInverse(property) {
    switch (property) {
      case 'symbol':
        return 's';

      case 'address':
        return 'a';

      case 'decimals':
        return 'd';

      case 'name':
        return 'n';

      case 'logoURI':
        return 'u';

      case 'tax':
        return 't';

      case 'useNormalRouterForLiquidity':
        return 'nr';

      case 'canAddToken':
        return 'c';

      case 'description':
        return 'de';

      case 'www':
        return 'w';

      case 'swap':
        return 'sw';

      case 'gift':
        return 'gi';

      case 'staging':
        return 'st';

      case 'wp':
        return 'wp';

      case 'issued':
        return 'i';

      default:
        return property;
    }
  }

  function mapTokenDataInverse(item, network) {
    let newItem = {};

    for (let property in item) {
      if (item.hasOwnProperty(property)) {
        newItem[mapTokenDataPropertyInverse(property)] = item[property];

        if (property == 'a' || property == 'address') {
          if (network == 'TRON') {
            newItem.hex = (0, _tronFormatAddress.toHex)(item[property]);
          } else {
            newItem.hex = window.Web3.utils.toHex(item[property]);
          }
        }
      }
    }

    return newItem;
  }

  function mapTokenDataProperty(property) {
    switch (property) {
      case 's':
        return 'symbol';

      case 'a':
        return 'address';

      case 'd':
        return 'decimals';

      case 'n':
        return 'name';

      case 'u':
        return 'logoURI';

      case 't':
        return 'tax';

      case 'nr':
        return 'useNormalRouterForLiquidity';

      case 'c':
        return 'canAddToken';

      case 'de':
        return 'description';

      case 'i':
        return 'issued';

      case 'w':
        return 'www';

      case 'sw':
        return 'swap';

      case 'gi':
        return 'gift';

      case 'st':
        return 'staging';

      case 'rid':
        return 'bridgeResourceID';

      default:
        return property;
    }
  }

  function mapPairDataProperty(property) {
    switch (property) {
      case 'a':
        return 'symbolA';

      case 'b':
        return 'symbolB';

      case 'ad':
        return 'address';

      case 'hc':
        return 'hideFromCharts';

      case 'i':
        return 'invert';

      case 'h':
        return 'hidden';

      case 'st':
        return 'staging';

      default:
        return property;
    }
  }

  function mapTokenData(item, network) {
    let newItem = {}; //item.hex = null;

    let hex;

    for (let property in item) {
      if (item.hasOwnProperty(property)) {
        newItem[mapTokenDataProperty(property)] = item[property];

        if (property == 'a' || property == 'address') {
          if (network == 'TRON') {
            hex = (0, _tronFormatAddress.toHex)(item[property]);
          } else {
            hex = window.Web3.utils.toHex(item[property]);
          }
        }
      }
    }

    newItem.hex = hex;
    return newItem;
  }

  function mapPairData(item) {
    let newItem = {};

    for (let property in item) {
      if (item.hasOwnProperty(property)) {
        newItem[mapPairDataProperty(property)] = item[property];
      }
    }

    return newItem;
  }

  function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
});