define("justmoney-swap/components/news-ticker/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "S9Kv6LzL",
    "block": "[[[10,0],[14,1,\"newsTicker\"],[14,0,\"ticker-outer-wrap\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"ticker-wrap\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"ticker\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"ticker__item\"],[12],[1,\"We have migrated JM token to a new version. Please swap your OLDJM to V2  \"],[10,3],[14,6,\"/jmtoken\"],[12],[1,\"here\"],[13],[13],[1,\"\\n            \"],[10,0],[14,0,\"ticker__item\"],[12],[1,\"JustMoney Exchange is now JustMoney Swap. The old domain (justmoney.exchange) is now just.money\"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"closeButton\"],[15,\"onclick\",[28,[37,0],[[30,0],[30,0,[\"closeContainer\"]]],null]],[12],[10,\"i\"],[14,0,\"fa-solid fa-xmark\"],[12],[13],[13],[1,\"\\n\"],[13]],[],false,[\"action\"]]",
    "moduleName": "justmoney-swap/components/news-ticker/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});