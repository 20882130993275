define("justmoney-swap/helpers/toPercentage", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toPercentage = toPercentage;

  function toPercentage(params) {
    if (!params[0] && !params !== 0) return '';
    return (parseFloat(params[0]) * 100).toFixed(params[1]);
  }

  var _default = (0, _helper.helper)(toPercentage);

  _exports.default = _default;
});