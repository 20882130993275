define("justmoney-swap/routes/ccwidget", ["exports", "@ember/service", "justmoney-swap/core/base-route", "@ember/object"], function (_exports, _service, _baseRoute, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CcwidgetRoute = (_class = class CcwidgetRoute extends _baseRoute.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "tokenPair", _descriptor, this);

      _defineProperty(this, "queryParams", {
        n1: {
          refreshModel: true
        },
        n2: {
          refreshModel: true
        },
        from: {
          refreshModel: true
        },
        to: {
          refreshModel: true
        }
      });
    }

    refreshModel() {
      this.refresh();
    }

    model(params) {
      return new Promise(async resolve => {
        if (window.App.widgetOptions) {
          if (this.App.widgetOptions.hasOwnProperty("fromNetwork")) {
            this.provider.getProvider(this.App.widgetOptions.fromNetwork);
            this.n1 = this.App.widgetOptions.fromNetwork;
          }

          if (this.App.widgetOptions.hasOwnProperty("toNetwork")) {
            this.n2 = this.App.widgetOptions.toNetwork;
          }

          if (this.App.widgetOptions.hasOwnProperty("fromToken")) {
            params.from = this.App.widgetOptions.fromToken;
          }

          if (this.App.widgetOptions.hasOwnProperty("toToken")) {
            params.to = this.App.widgetOptions.toToken;
          }

          if (!params.n1) {
            params.n1 = this.App.NETWORK || 'TRON';
          }

          if (params.n1 != this.App.NETWORK) {
            this.App.NETWORK = params.n1;
          }

          this.provider.getProvider(params.n1);

          if (!params.n2) {
            params.n2 = 'BSC';
          }

          if (params.n2 == params.n1) {
            if (params.n1 == 'BSC') {
              params.n2 = 'TRON';
            } else {
              params.n2 = 'BSC';
            }
          }

          this.tokens = await this.tokenPair.getTokens();
          this.pairs = await this.tokenPair.getPairs();
          this.tokens2 = await this.tokenPair.getTokens(params.n2);
          this.pairs2 = await this.tokenPair.getPairs(params.n2);
          resolve({
            from: params.from,
            to: params.to,
            tokens: this.tokens,
            tokens2: this.tokens2,
            pools: this.pairs,
            pools2: this.pairs2,
            fromNetwork: params.n1,
            toNetwork: params.n2
          });
        } else {
          window.addEventListener("message", async event => {
            if (event.data && event.data.name == 'JMSetOptions') {
              if (event.data.options.hasOwnProperty("fromNetwork")) {
                params.n1 = this.n1 = event.data.options.fromNetwork;
              }

              if (event.data.options.hasOwnProperty("toNetwork")) {
                params.n2 = this.n2 = event.data.options.toNetwork;
              }

              if (event.data.options.hasOwnProperty("fromToken")) {
                params.from = event.data.options.fromToken;
              }

              if (event.data.options.hasOwnProperty("toToken")) {
                params.to = event.data.options.toToken;
              }

              if (!params.n1) {
                params.n1 = this.App.NETWORK || 'TRON';
              }

              if (params.n1 != this.App.NETWORK) {
                this.App.NETWORK = params.n1;
              }

              this.provider.getProvider(params.n1);

              if (!params.n2) {
                params.n2 = 'BSC';
              }

              if (params.n2 == params.n1) {
                if (params.n1 == 'BSC') {
                  params.n2 = 'TRON';
                } else {
                  params.n2 = 'BSC';
                }
              }

              this.tokens = await this.tokenPair.getTokens();
              this.pairs = await this.tokenPair.getPairs();
              this.tokens2 = await this.tokenPair.getTokens(params.n2);
              this.pairs2 = await this.tokenPair.getPairs(params.n2);
              resolve({
                from: params.from,
                to: params.to,
                tokens: this.tokens,
                tokens2: this.tokens2,
                pools: this.pairs,
                pools2: this.pairs2,
                fromNetwork: params.n1,
                toNetwork: params.n2
              });
            }
          }, false);
        }
      });
    }

    async setupController(controller, model, transition) {
      super.setupController(controller, model, transition);
      controller.n1 = model.fromNetwork;
      controller.n2 = model.toNetwork;

      if (model.from) {
        controller.from = model.from;
      }

      if (model.to) {
        controller.to = model.to;
      }

      controller.tokens = model.tokens;
      controller.tokens2 = model.tokens2;

      if (!model.from || !this.getTokenBySymbol(model.from)) {
        if (model.fromNetwork == 'TRON') {
          model.from = 'TRX';
          this.from = 'TRX';
          controller.from = 'TRX';
        } else if (model.fromNetwork == 'BSC') {
          model.from = 'BNB';
          this.from = 'BNB';
          controller.from = 'BNB';
        } else if (model.fromNetwork == 'POLY') {
          model.from = 'MATIC';
          this.from = 'MATIC';
          controller.from = 'MATIC';
        } else if (model.fromNetwork == 'BTTC') {
          model.from = 'TRX';
          this.from = 'TRX';
          controller.from = 'TRX';
        }
      }

      if (!model.to || !this.getTokenBySymbol2(model.to)) {
        model.to = 'JM';
        this.to = 'JM';
        controller.to = 'JM';
      }

      console.log('TOKENS_UPDATED', {
        tokens: model.tokens,
        tokens2: model.tokens2
      });
      this.dispatch('TOKENS_UPDATED', {
        tokens: model.tokens,
        tokens2: model.tokens2
      });
    }

    getTokenBySymbol(symbol) {
      return this.tokens.find(token => token.symbol === symbol);
    }

    getTokenBySymbol2(symbol) {
      return this.tokens2.find(token => token.symbol === symbol);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "tokenPair", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "refreshModel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "refreshModel"), _class.prototype)), _class);
  _exports.default = CcwidgetRoute;
});