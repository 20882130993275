define("justmoney-swap/helpers/userPoolPercentage", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.userPoolPercentage = userPoolPercentage;

  function userPoolPercentage(params) {
    if (!params[0] && !params !== 0) return '';
    return (parseFloat(Number(params[0]) / Number(params[1])) * 100).toFixed(2);
  }

  var _default = (0, _helper.helper)(userPoolPercentage);

  _exports.default = _default;
});