define("justmoney-swap/routes/listing", ["exports", "justmoney-swap/core/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ListingRoute extends _baseRoute.default {}

  _exports.default = ListingRoute;
});