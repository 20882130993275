define("justmoney-swap/services/just-money-bridge", ["exports", "@ember/service", "justmoney-swap/providers/wallets/tron-link", "ethers/lib/utils", "ethers", "justmoney-swap/core/base-service", "justmoney-swap/utils/tools"], function (_exports, _service, _tronLink, _utils, _ethers, _baseService, _tools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const AbiCoder = _ethers.ethers.utils.AbiCoder;
  const ADDRESS_PREFIX_REGEX = /^(41)/;
  const ADDRESS_PREFIX = "41";
  let JustMoneyBridgeService = (_class = class JustMoneyBridgeService extends _baseService.default {
    // 35%
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "provider", _descriptor, this);

      _initializerDefineProperty(this, "logging", _descriptor2, this);

      _defineProperty(this, "SLIPPAGE_FEE", 0.35);

      _defineProperty(this, "CONTRACT_METHODS", {
        DEPOSIT: 'deposit(uint8,bytes32,bytes)',
        DEPOSIT_CROSS: 'depositCross(uint8,bytes,bytes,bytes)'
      });
    }

    encode(amount, recipient, decimals) {
      const expandDecimals = function (amount) {
        let decimals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 18;
        return (0, _utils.parseUnits)(String(amount), decimals);
      };

      const data = '0x' + (0, _utils.hexZeroPad)(_ethers.BigNumber.from(expandDecimals(amount, decimals)).toHexString(), 32).substr(2) + // Deposit Amount        (32 bytes)
      (0, _utils.hexZeroPad)((0, _utils.hexlify)((recipient.length - 2) / 2), 32).substr(2) + // len(recipientAddress) (32 bytes)
      recipient.substr(2);
      return data;
    }

    async getAllowance(fromToken) {
      return this.currentProvider().wallet.getAllowanceForAddress(fromToken, this.App.JM.NETWORKS[this.App.NETWORK].BRIDGE.BRIDGE_HANDLER);
    }

    async getAllowanceBridge(fromToken) {
      return this.currentProvider().wallet.getAllowanceForAddress(fromToken, this.App.JM.NETWORKS[this.App.NETWORK].BRIDGE.BRIDGE_ADDRESS);
    }

    async approve(token) {
      return this.currentProvider().wallet.approve(token, this.App.JM.NETWORKS[this.App.NETWORK].BRIDGE.BRIDGE_HANDLER);
    }

    async approveBridge(token) {
      return this.currentProvider().wallet.approve(token, this.App.JM.NETWORKS[this.App.NETWORK].BRIDGE.BRIDGE_ADDRESS);
    }

    async bridgeDeposit(token, destinationChain, fee, amount, recipient) {
      let address = this.App.JM.NETWORKS[this.App.NETWORK].BRIDGE.BRIDGE_ADDRESS;
      let destinationDomainID = this.App.JM.NETWORKS[destinationChain].BRIDGE.DESTINATION_ID;
      const data = this.encode(amount, recipient, token.decimals);
      const parameters = [{
        type: _tronLink.default.PARAMETERS.UINT8,
        value: destinationDomainID
      }, {
        type: _tronLink.default.PARAMETERS.BYTES32,
        value: token.bridgeResourceID
      }, {
        type: _tronLink.default.PARAMETERS.BYTES,
        value: data
      }];
      let transaction = await this.currentProvider().wallet.send(address, this.CONTRACT_METHODS.DEPOSIT, {
        value: fee
      }, parameters);
      this.logging.log(transaction);
      return transaction;
    }

    async bridgeCrossDeposit(token, isBase, fromChain, destinationChain, fee, recipient, fromAmount, estimate, estimate2, toToken, optimalPath, optimalPath2) {
      let address = this.App.JM.NETWORKS[this.App.NETWORK].BRIDGE.BRIDGE_ADDRESS;
      let destBridgeAddress = this.App.JM.NETWORKS[destinationChain].BRIDGE.BRIDGE_ADDRESS;
      let destinationDomainID = this.App.JM.NETWORKS[destinationChain].BRIDGE.DESTINATION_ID;
      let amountOutMin = (0, _tools.toWei)((Number(estimate) * (1 - this.SLIPPAGE_FEE)).toString(), this.App.JM.NETWORKS[fromChain].TOKENS.JM.decimals).toString();
      let amountOutMin2 = (0, _tools.toWei)((Number(estimate2) * (1 - this.SLIPPAGE_FEE)).toString(), toToken.decimals).toString();
      let fromAmountWei = (0, _tools.toWei)(Number(fromAmount).toString(), token.decimals).toString();
      let baseCoin = this.App.JM.NETWORKS[this.App.NETWORK].BASE_TOKEN;
      let feeWei = (0, _tools.toWei)(fee.toString(), this.App.JM.NETWORKS[this.App.NETWORK].TOKENS[baseCoin].decimals).toString();
      let path1 = optimalPath.map(token => {
        return token.hex;
      });
      let path2 = optimalPath2.map(token => {
        return token.hex;
      });
      let taxes = optimalPath.map(token => (0, _utils.hexZeroPad)( // eslint-disable-next-line no-undef
      Web3.utils.utf8ToHex((token.tax * 10 ** 18).toString() || 0), 32));
      let taxes2 = optimalPath2.map(token => (0, _utils.hexZeroPad)( // eslint-disable-next-line no-undef
      Web3.utils.utf8ToHex((token.tax * 10 ** 18).toString() || 0), 32));
      const data = this.encodeCrossSwapData(destBridgeAddress, destinationChain == 'TRON'); // destination chain bridge contract address

      const dataSrc = this.encodeCrossSwapDataSrc(fromAmountWei, amountOutMin, this.App.JM.NETWORKS[fromChain].TOKENS.JM.bridgeResourceID, path1, taxes);
      const dataDest = this.encodeCrossSwapDataDest(path2, taxes2, recipient, amountOutMin2);
      const parameters = [{
        type: _tronLink.default.PARAMETERS.UINT8,
        value: destinationDomainID
      }, {
        type: _tronLink.default.PARAMETERS.BYTES,
        value: data
      }, {
        type: _tronLink.default.PARAMETERS.BYTES,
        value: dataSrc
      }, {
        type: _tronLink.default.PARAMETERS.BYTES,
        value: dataDest
      }];
      let transaction = await this.currentProvider().wallet.send(address, this.CONTRACT_METHODS.DEPOSIT_CROSS, {
        value: isBase ? (0, _tools.toWei)((Number(fromAmount) + Number(fee)).toString(), this.App.JM.NETWORKS[this.App.NETWORK].TOKENS[baseCoin].decimals).toString() : feeWei
      }, parameters);
      return transaction;
    }

    encodeParams(inputs) {
      let typesValues = inputs;
      let parameters = '';

      if (typesValues.length == 0) {
        return parameters;
      }

      const abiCoder = new AbiCoder();
      let types = [];
      const values = [];

      for (let i = 0; i < typesValues.length; i++) {
        let {
          type,
          value
        } = typesValues[i];

        if (type == 'uint256') {
          value = value;
        } else if (type == 'bytes32') {
          value = value;
        } else if (type == 'address') {
          value = value.replace(ADDRESS_PREFIX_REGEX, '0x');
        } else if (type == 'address[]') {
          value = value;
        } else if (type == 'bytes32[]') {
          value = value.map(v => (0, _utils.hexlify)(v));
        }

        types.push(type);
        values.push(value);
      }

      try {
        parameters = abiCoder.encode(types, values).replace(/^(0x)/, '');
      } catch (ex) {
        console.log(ex);
      }

      return parameters;
    }

    encodeCrossSwapData(recipient, isTron) {
      if (isTron) {
        recipient = (0, _utils.hexlify)(_utils.base58.decode(recipient));
        recipient = recipient.substring(0, recipient.length - 8);

        if (recipient.startsWith('0x41')) {
          recipient = recipient.replace('0x41', '0x');
        }
      }

      const data = '0x' + (0, _utils.hexZeroPad)((0, _utils.hexlify)((recipient.length - 2) / 2), 32).substr(2) + // len(recipientAddress) (32 bytes)
      recipient.substr(2);
      return data;
    }

    encodeCrossSwapDataSrc(amountIn, amountOutMin, resourceID, path, taxes) {
      let inputs = [{
        type: 'uint256',
        value: amountIn
      }, {
        type: 'uint256',
        value: amountOutMin
      }, {
        type: 'bytes32',
        value: resourceID
      }, {
        type: 'address[]',
        value: path
      }, {
        type: 'bytes32[]',
        value: taxes
      }];
      let parameters = this.encodeParams(inputs);
      parameters = '0x' + parameters;
      return parameters;
    }

    encodeCrossSwapDataDest(path, taxes, recipient, amountOutMin) {
      let inputs = [{
        type: 'address[]',
        value: path
      }, {
        type: 'bytes32[]',
        value: taxes
      }, {
        type: 'address',
        value: recipient
      }, {
        type: 'uint256',
        value: amountOutMin
      }];
      let parameters = this.encodeParams(inputs);
      parameters = '0x' + parameters;
      return parameters;
    }

    currentProvider() {
      return this.provider.getProvider(this.App.NETWORK);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "logging", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = JustMoneyBridgeService;
});