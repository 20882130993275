define("justmoney-swap/components/in-component-loading-spinner/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "7jtVcGCL",
    "block": "[[[10,0],[14,0,\"inComponentLoadingSpinner\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"imageContainer\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"imageSubContainer\"],[12],[1,\"\\n            \"],[10,\"img\"],[14,\"src\",\"/assets/img/coin.svg\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"loadingText\"],[12],[41,[30,1],[[[1,[30,1]],[1,\" \"]],[]],[[[1,\"Loading...\"]],[]]],[13],[1,\"\\n\"],[13]],[\"@loadingText\"],false,[\"if\"]]",
    "moduleName": "justmoney-swap/components/in-component-loading-spinner/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});