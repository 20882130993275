define("justmoney-swap/helpers/formatDate", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatDate = formatDate;

  function formatDate(params) {
    if (params[0]) {
      if (params[1]) {
        let date = new Date(params[0]);
        return date.toLocaleDateString("en-US");
      } else {
        let date = new Date(params[0]);
        return date.toLocaleDateString("en-US") + " " + date.toLocaleTimeString("en-US");
      }
    }
  }

  var _default = (0, _helper.helper)(formatDate);

  _exports.default = _default;
});